import gql from 'graphql-tag';

export const ON_INTERACTION_CREATED = gql`
	subscription ActivityInteractionCreated($activity_id: ID!) {
		activityInteractionCreated(activity_id: $activity_id) {
			id
			visibility
			content
			meta
			content_history
			attachments {
				id
				path
				meta
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
			}
			created_at
			creator {
				id
				first_name
				middle_name
				last_name
			}
			updated_at
			updater {
				id
				first_name
				middle_name
				last_name
			}
		}
	}
`;

export const ON_ACTIVITY_UPDATED = gql`
	subscription ActivityUpdated($activity_id: ID!) {
		activityUpdated(activity_id: $activity_id) {
			id
			description
			data
			forms
			status
			sub_status
			variables
			visibility
			workflow_process {
				id
				variables
				history
				status_id
			}
			form_requests {
				id
				form_key
				status
				visibility
				form {
					name
					reference_name
					description
				}
				activated_at
				submitted_at
				closed_at
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
				updated_at
				updater {
					id
					first_name
					middle_name
					last_name
				}
			}
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
		}
	}
`;

export const ON_ACTIVITY_CREATED = gql`
	subscription ActivityCreated {
		activityCreated {
			id
			template {
				name
			}
			description
			status
			visibility
			sub_status
			data
			variables
			forms
			created_at
			creator {
				id
				first_name
				middle_name
				last_name
			}
			updated_at
			updater {
				id
				first_name
				middle_name
				last_name
			}
		}
	}
`;

export const ON_ACTIVITY_EVENT_CREATED = gql`
	subscription ActivityEventCreated($activity_id: ID!) {
		activityEventCreated(activity_id: $activity_id) {
			causer {
				first_name
				middle_name
				last_name
				id
			}
			caused_at
			event
			message
			meta
			properties
			id
			subject {
				... on ActivityInteraction {
					content
				}
			}
			visibility
		}
	}
`;
