// The following Tailwind theme aspires to be a reproduction of the
// default optional Genesis CSS theme that ships with FormKit

import { merge } from 'lodash';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import genesis from '@formkit/themes/tailwindcss/genesis';

export default merge(genesis, {
	// Global styles apply to _all_ inputs with matching section keys
	global: {
		fieldset: 'w-full border border-gray-300 rounded px-2 py-1 mb-1',
		input: 'appearance-none bg-transparent focus:outline-none focus:shadow-none',
		label: 'block mb-1 text-sm formkit-invalid:text-red-500 font-medium text-gray-600',
		legend: 'font-medium text-gray-700 text-sm px-1',
		outer: 'formkit-disabled:opacity-50',
		prefixIcon: 'w-10 flex self-stretch grow-0 shrink-0 rounded-tl-md rounded-bl-md border-r border-gray-300 bg-white bg-gradient-to-b from-transparent to-gray-200 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
		suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
	},
	'family:box': {
		fieldset: 'focus-within:border-primary-500',
		decorator: 'block relative h-5 w-5 mr-2 rounded bg-white bg-gradient-to-b from-transparent to-gray-200 border border-gray-300 peer-checked:border-primary-500 text-transparent peer-checked:text-primary-500',
		help: 'mb-2',
		input: 'fixed left-[999em] w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
		wrapper: 'flex items-center',
	},
	'family:button': {
		input: '$reset inline-flex items-center bg-primary-600 text-white text-sm font-normal py-3 px-6 rounded',
	},
	'family:dropdown': {
		dropdownWrapper: 'my-2 w-full drop-shadow-lg rounded-md [&::-webkit-scrollbar]:hidden',
		emptyMessageInner: 'flex items-center justify-center text-base p-2 text-center w-full text-gray-700 [&>span]:mr-3 [&>span]:ml-0',
		inner:
			'w-full relative flex border border-gray-300 focus-within:border-primary-500 focus-within:ring-0 rounded-md mb-1 formkit-disabled:focus-within:border-gray-200 formkit-disabled:focus-within:border [&>span:first-child]:focus-within:text-primary-500',
		input: 'w-full pl-3 pr-2 py-1.5',
		listbox: 'bg-white drop-shadow-lg rounded-md overflow-hidden border-opacity-5 border',
		listitem: 'flex flex-row items-center pl-5 relative hover:bg-gray-300 data-[is-active="true"]:bg-gray-300 data-[is-active="true"]:aria-selected:bg-primary-600 aria-selected:bg-primary-600 aria-selected:text-white',
		loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-primary-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
		option: 'pl-3 pr-2 py-1.5',
		optionLoading: 'text-gray-500',
		placeholder: 'pl-3 pr-2 py-1.5 text-gray-400',
		selector: 'flex w-full justify-between items-center min-h-9 [&u] cursor-default',
		selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0',
	},
	'family:text': {
		inner: 'flex items-center border border-gray-300 focus-within:border-primary-500 [&>label:first-child]:focus-within:text-primary-500 rounded-md mb-1',
		input: 'w-full pl-3 pr-2 py-1.5 border-none focus:ring-0 text-base text-gray-700 placeholder-gray-400 rounded-md',
	},
	'family:date': {
		inner: 'flex items-center border border-gray-300 focus-within:border-primary-500 [&>label:first-child]:focus-within:text-primary-500 rounded-md mb-1',
		input: 'w-full pl-3 pr-2 py-1.5 border-none focus:ring-0 text-base text-gray-700 placeholder-gray-400',
	},
	stepper: {
		inner: 'flex items-center border border-gray-300 focus-within:border-primary-500 [&>label:first-child]:focus-within:text-primary-500 rounded-md mb-1',
		input: 'bg-transparent focus:outline-none focus:shadow-none appearance-textfield [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ',
		//stepInput: 'relative border-0 m-0.5 flex-shrink rounded-full select-none text-center text-gray-400 hover:text-gray-700 [&>span]:top-1/2 [&>span]:left-1/2 [&>span]:absolute [&>span]:translate-x-1/2  [&>span]:translate-y-1/2',
		minusStepper:
			'w-10 flex self-stretch grow-0 shrink-0 rounded-tl-md rounded-bl-md border-r border-gray-300 bg-white bg-gradient-to-b from-transparent to-gray-200 justify-center items-center [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto hover:bg-gray-400 hover:to-gray-400 hover:border-gray-400',
		plusStepper:
			'w-10 flex self-stretch grow-0 shrink-0 rounded-tr-md rounded-br-md border-l border-gray-300 bg-white bg-gradient-to-b from-transparent to-gray-200 justify-center items-center [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto hover:bg-gray-400 hover:to-gray-400 hover:border-gray-400',
	},
	color: {
		input: '$reset appearance-none w-full cursor-pointer border-none rounded-md p-0 h-9 m-0 bg-transparent [&::-webkit-color-swatch-wrapper]:p-0 [&::-webkit-color-swatch]:border-none [&::-webkit-color-swatch]:rounded-md',
	},
	file: {
		fileRemove:
			'relative z-[2] ml-auto text-[0px] hover:text-red-500 pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-primary-500 peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
		inner: 'relative w-full cursor-pointer formkit-multiple:[&>button]:absolute',
		noFiles: 'flex w-full items-center pl-3 pr-2 py-1.5 text-gray-400',
	},
	radio: {
		decorator:
			'$reset block relative h-5 w-5 mr-2 rounded-full bg-white bg-gradient-to-b from-transparent to-gray-200 border border-gray-300 peer-checked:border-primary-500 text-transparent peer-checked:text-primary-500 flex justify-center items-center',
		decoratorIcon: '$reset w-5 p-[5px]',
		label: '$reset text-sm text-gray-700 select-none',
		option: 'mb-1.5',
	},
	range: {
		inner: '$reset flex items-center w-full',
		input: '$reset w-full mb-1 h-2 p-0 rounded-full border-gray-300 accent-primary-500',
	},
	select: {
		inner: 'flex relative items-center rounded-md mb-1 border border-gray-300 focus-within:border-primary-500 focus-within:ring-0 [&>span:first-child]:focus-within:text-primary-500',
		input: 'w-full rounded-md pl-3 pr-8 -mr-2 py-1.5 border-none text-base text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-700 formkit-multiple:data-[placeholder="true"]:text-inherit',
		selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
		option: 'formkit-multiple:py-2 formkit-multiple:px-3 formkit-multiple:text-sm text-gray-700',
	},
	textarea: {
		inner: 'flex rounded-md mb-1 border border-gray-300 focus-within:border-primary-500 [&>label:first-child]:focus-within:text-primary-500',
		input: 'block w-full min-h-9 pl-3 pr-2 py-1.5 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
	},

	// PRO input styles
	autocomplete: {
		selection: 'static flex left-0 top-0 right-0 bottom-0 mt-0 mb-2 rounded-md bg-gray-100',
		selectionWrapper: `
      absolute left-0 top-0 right-0 bottom-0 flex rounded-md bg-gray-100
      formkit-multiple:static formkit-multiple:mt-0 formkit-multiple:mb-2 formkit-multiple:w-full
    `,
	},
	repeater: {
		content: 'grow p-3 flex flex-col align-center space-y-2',
		moveDownIcon: 'block grow-0 shrink w-6 my-1 [&>svg]:w-full [&>svg]:m-auto [&>svg]:h-6',
		moveUpIcon: 'block grow-0 shrink w-6 my-1 [&>svg]:w-full [&>svg]:m-auto [&>svg]:h-6',
		removeIcon: 'block grow-0 shrink w-6 my-1 [&>svg]:w-full [&>svg]:m-auto [&>svg]:h-6',
		downControl: 'text-primary-400 hover:text-primary-500 disabled:hover:text-inherit disabled:opacity-25',
		removeControl: 'text-primary-400 hover:text-primary-500 disabled:hover:text-inherit disabled:opacity-25',
		upControl: 'text-primary-400 hover:text-primary-500 disabled:hover:text-inherit disabled:opacity-25',
		item: 'flex w-full mb-5 rounded border border-gray-300 border-b',
		controls: 'flex flex-col items-center justify-center bg-gray-200 p-3',
	},
	slider: {
		outer: 'max-w-full',
		fill: 'h-full rounded-full absolute top-0 mx-[-4px] bg-primary-500',
		handle:
			'group w-4 h-4 rounded-full bg-white absolute top-1/2 left-0 z-[2] -translate-x-1/2 -translate-y-1/2 shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.8)] focus-visible:outline-0 focus-visible:border border-primary-500 data-[is-target="true"]:z-[3]',
		tooltip:
			'absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-[4px] bg-primary-500 text-white py-1 px-2 text-xs leading-none whitespace-nowrap rounded-sm opacity-0 pointer-events-none transition-opacity after:content-[""] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:-translate-y-[1px] after:border-4 after:border-transparent after:border-t-primary-500 group-hover:opacity-100 group-focus-visible:opacity-100 group-data-[show-tooltip="true"]:opacity-100',
	},
	toggle: {
		altLabel: 'block w-full mb-1 font-medium text-gray-600 text-sm',
		track:
			'p-0.5 min-w-[3em] relative rounded-full transition-all bg-gray-400 peer-checked:bg-primary-500 peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0 peer-checked:[&>div:first-child:not(:last-child)]:text-white peer-checked:[&>div:has(svg)]:text-primary select-none',
	},
	dropdown: {
		selector: 'flex w-full justify-between items-center min-h-9 [&u] cursor-default',
		removeSelection: 'block box-content w-3 mx-2 my-auto self-stretch grow-0 shrink-0 cursor-pointer',
		emptyMessage: 'text-black',
	},
	datepicker: {
		input: 'selection:bg-primary-500 selection:text-white',
		month: `
      flex items-center justify-center
      w-[calc(33%_-_1em)] m-2 p-2 rounded-md
      bg-gray-200
      aria-selected:bg-primary-500 aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-primary-500 focus:outline-offset-2 focus:bg-white focus:text-black
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none select-none cursor-pointer
    `,
		year: `
      flex items-center justify-center
      w-[calc(20%_-_1em)] m-2 p-2 rounded-md
      bg-gray-200
      aria-selected:bg-primary-500 aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-primary-500 focus:outline-offset-2 focus:bg-white focus:text-black
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none select-none cursor-pointer
    `,

		dayCell: `
      flex items-center justify-center
      w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
      bg-gray-200
      aria-selected:bg-primary-500 aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-primary-500 focus:outline-offset-2 focus:bg-white focus:text-black
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none select-none cursor-pointer
    `,
		timeInput: 'w-full border-2 border-gray-300 rounded-md p-2 my-[2em] focus-visible:outline-primary-500',
		dayButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-500',
		monthButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-500',
		yearButton: 'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-primary-500',
		openButton: `
      appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
      focus-visible:outline-none focus-visible:text-white focus-visible:bg-primary-500
    `,
		removeSelection: 'block box-content w-3 mx-2 my-auto self-stretch grow-0 shrink-0 cursor-pointer',
	},
	taglist: {
		tags: 'flex items-center flex-wrap w-full px-2',
		input: '$reset appearance-none bg-transparent focus:outline-none focus:shadow-none pl-2 pr-2 py-1.5 w-[0%] grow',
	},
	transferlist: {
		outer: `
      [&_.dnd-placeholder]:bg-primary-500 [&_.dnd-placeholder]:text-white
      [&_.dnd-placeholder_svg]:text-white
      [&_.dnd-children-hidden]:w-full [&_.dnd-children-hidden]:p-0 [&_.dnd-children-hidden]:flex [&_.dnd-children-hidden]:flex-col [&_.dnd-children-hidden]:border-none
      [&_.dnd-children-hidden_span]:hidden
      [&_.dnd-children-hidden_.formkit-transferlist-option]:hidden
      [&_.dnd-multiple-selections_span]:inline-block
      [&_.dnd-multiple-selections_.formkit-transferlist-option]:inline-block
    `,
		fieldset: '$reset w-full',
		transferlist: 'sm:w-3/5 shadow-md flex flex-col min-h-[350px] max-h-[350px] border rounded-md overflow-hidden select-none bg-gray-50 focus-visible:outline-none',
		transferControls: 'flex sm:flex-col justify-center mx-auto my-2 sm:mx-2 sm:my-auto border rounded-md',
		transferlistListItem:
			'pl-8 py-2 relative aria-selected:bg-primary-600 aria-selected:data-[is-active=true]:bg-primary-600 aria-selected:text-white aria-selected:data-[is-active=true]:text-white first:-mt-px first:border-t py-2 px-3 flex relative border-b bg-white data-[is-active=true]:text-primary-500 data-[is-active=true]:bg-gray-100 cursor-pointer group-data-[is-max=true]:cursor-not-allowed items-center',
		transferlistButton:
			'text-sm disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50 first:rounded-l last:rounded-r-md sm:first:rounded-t-md sm:last:rounded-b-md sm:first:rounded-b-none sm:last:rounded-t-none appearance-none p-2 m-0 cursor-pointer h-10 border-none rounded-none bg-gray-50 hover:bg-gray-200 disabled:hover:outline-none hover:outline-1 hover:text-primary-500 disabled:hover:text-current hover:z-10',
		transferlistListItems: 'list-none bg-gray-50 h-full overflow-x-hidden overflow-y-auto focus-visible:outline-none',
		transferlistSearch: 'flex border rounded-md items-center focus-within:border-primary-500 border border-gray-300 pl-3 pr-2 py-0.5',
	},
	togglebuttons: {
		input: `
      !text-black !bg-white
      relative flex ring-1 ring-gray-400 text-center align-center justify-center transition-colors
      group-data-[vertical="true"]/options:w-full
      group-data-[vertical="true"]/options:rounded-none
      group-data-[vertical="false"]/options:rounded-none
      focus-visible:z-10
      disabled:filter-grayscale
      disabled:!bg-gray-200
      disabled:!text-gray-700
      disabled:opacity-50
      disabled:cursor-not-allowed
      formkit-disabled:opacity-100
      aria-pressed:!bg-primary-600
      aria-pressed:!text-white
    `,
	},
});
