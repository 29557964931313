import gql from 'graphql-tag';

export const GET_WORKFLOWS = gql`
	query Data($first: Int!, $page: Int) {
		getWorkflows(first: $first, page: $page) {
			data {
				id
				status_id
				settings
				name
				reference_name
				description
				current_revision_id
				revision_history
				updated_at
				updater {
					first_name
					middle_name
					last_name
					id
				}
				created_at
				creator {
					first_name
					middle_name
					last_name
					id
				}
				archived_at
				archiver {
					last_name
					middle_name
					first_name
					id
				}
				published_at
				publisher {
					last_name
					middle_name
					first_name
					id
				}
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_ACTIVE_WORKFLOWS_DROPDOWN = gql`
	query Data($first: Int!, $page: Int) {
		getWorkflows(first: $first, page: $page) {
			data {
				id
				name
				description
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_WORKFLOW_BY_ID = gql`
	query Data($workflow_id: ID) {
		getWorkflowById(id: $workflow_id) {
			id
			status_id
			settings
			name
			reference_name
			description
			current_revision_id
			current_revision_replaces
			current_revision_at
			current_revision_by
			current_revision {
				id
				workflow_id
				revision
				schema
				forms
				triggers
				variables
				exits
				created_at
				created_by
				updated_at
				updated_by
			}
			revision_history
			published_at
			published_by
			archived_at
			archived_by
			created_at
			created_by
			updated_at
			updated_by
		}
	}
`;

export const GET_WORKBLOCK_TEMPLATES = gql`
	query Query {
		getWorkblockTemplates {
			id
			action_id
			integration_id
			name
			title
			description
			type_id
			category_id
			status_id
			settings
		}
	}
`;

export const GET_WORKBLOCK_ACTIONS = gql`
	query Query {
		getWorkblockActions {
			id
			integration_id
			name
			reference_name
			description
			status_id
			settings
			schema
		}
	}
`;

export const GET_WORKBLOCK_EXPRESSION_FUNCTIONS = gql`
	query Query {
		getWorkblockExpressionFunctions {
			id
			name
			reference_name
			description
			status_id
			options
			settings
		}
	}
`;
