import { RouteRecordRaw } from 'vue-router';

export const guestRoutes: RouteRecordRaw[] = [];

export const authRoutes: RouteRecordRaw[] = [
	{
		path: '/',
		name: 'dashboard',
		component: () => import('./pages/index.vue'),
	},
];

export const adminRoutes: RouteRecordRaw[] = [];

export default [...guestRoutes, ...authRoutes, ...adminRoutes];
