import { generateClasses } from '@formkit/themes';
import { check, circle, close, date, down, fileDoc, left, right, spinner, star } from '@formkit/icons';
import theme from './formkit.theme';
import { createInput, DefaultConfigOptions } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
// Custom inputs
import OneTimePasscode from '@modules/form/inputs/OneTimePasscodeInput.vue';
import TelephoneInput from '@modules/form/inputs/TelephoneInput.vue';
import Validation from '@modules/form/inputs/ValidationInput.vue';
import ClassesInput from '@modules/form/inputs/ClassesInput.vue';
import CardGroup from '@modules/form/inputs/CardGroup.vue';
import StackedCardGroup from '@modules/form/inputs/StackedCardGroup.vue';
import SelectOptions from '@modules/form/inputs/SelectOptionsInput.vue';
import ColourInput from '@modules/form/inputs/ColourInput.vue';
import ConditionalInput from '@modules/form/inputs/ConditionalInput.vue';
import stepper from '@/modules/form/inputs/stepper';
import ExpressionInput from '@modules/form/inputs/ExpressionInput.vue';
// Validation Rules
import { safeAlphaNumeric, safeAlphaNumericMessage, safeVariableString, safeVariableStringMessage } from '@modules/form/utils/rules';
// Input Icons
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faAt, faCheck, faDollarSign, faDown, faFastBackward, faFastForward, faLock, faMinus, faPhone, faPlus, faTrashAlt, faUp } from '@fortawesome/pro-light-svg-icons';
// Formkit Plugins
import { addAsteriskToRequiredFieldsPlugin, castNumber, getDropdownOptionLoaders, setGlobalElementDefaults } from '@modules/form/utils/plugins';
// Config
import { ServiceConfig } from '@/config/services';
import FormMapperInput from '@modules/form/inputs/FormMapperInput.vue';
import VariableMappingInput from '@modules/form/inputs/VariableMappingInput.vue';

const pro = createProPlugin(ServiceConfig.formkit_pro_key, inputs);

const config: DefaultConfigOptions = {
	config: {
		classes: generateClasses(theme),
	},
	rules: {
		safeAlphaNumeric: safeAlphaNumeric,
		safeVariableString: safeVariableString,
	},
	plugins: [pro, getDropdownOptionLoaders, setGlobalElementDefaults, addAsteriskToRequiredFieldsPlugin, castNumber],
	messages: {
		en: {
			validation: {
				safeAlphaNumeric: safeAlphaNumericMessage,
				safeVariableString: safeVariableStringMessage,
			},
		},
	},
	icons: {
		mail: icon(faAt).html.toString(),
		phone: icon(faPhone).html.toString(),
		lock: icon(faLock).html.toString(),
		dollar: icon(faDollarSign).html.toString(),
		check: icon(faCheck).html.toString(),
		arrowDown: icon(faDown).html.toString(),
		arrowUp: icon(faUp).html.toString(),
		fastForward: icon(faFastForward).html.toString(),
		rewind: icon(faFastBackward).html.toString(),
		close,
		checkboxDecorator: check,
		fileItem: fileDoc,
		fileRemove: close,
		noFiles: fileDoc,
		radioDecorator: circle,
		select: down,
		spinner,
		star,
		trash: icon(faTrashAlt).html.toString(),
		date,
		left,
		right,
		plus: icon(faPlus).html.toString(),
		minus: icon(faMinus).html.toString(),
	},
	inputs: {
		otp: createInput(OneTimePasscode, {
			props: ['digits'],
		}),
		stepper,
		telephone: createInput(TelephoneInput),
		cardGroup: createInput(CardGroup, { props: ['cardOptions', 'simple'] }),
		stackedCardGroup: createInput(StackedCardGroup, { props: ['cardOptions'] }),
		validation: {
			type: 'input',
			schema: [],
			component: Validation,
		},
		classes: {
			type: 'input',
			schema: [],
			component: ClassesInput,
			props: ['classOptions', 'staticElement', 'directReturn'],
		},
		colour: {
			type: 'input',
			schema: [],
			component: ColourInput,
			props: ['styleAttribute', 'directReturn'],
		},
		selectOptions: {
			type: 'input',
			schema: [],
			component: SelectOptions,
			props: ['inputType'],
		},
		conditional: {
			type: 'input',
			schema: [],
			component: ConditionalInput,
		},
		expression: {
			type: 'input',
			schema: [],
			component: ExpressionInput,
		},
		formMapper: {
			type: 'input',
			schema: [],
			component: FormMapperInput,
			props: ['schemaObjectType', 'schemaObjectId', 'schemaObjectName'],
		},
		variableMapping: {
			type: 'input',
			schema: [],
			family: 'text',
			component: VariableMappingInput,
			props: ['originalType'],
		},
		// custom: {
		// 	type: 'input',
		// 	schema: [],
		// 	family: 'text',
		// 	component: CustomComponentInput,
		// },
	},
};

export default config;
