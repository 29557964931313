import { apolloClient } from '@/services/useApollo';
import { DocumentNode } from 'graphql/index';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { OperationVariables } from '@apollo/client/core/types';
import { MutationOptions, QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { ApolloError, ApolloQueryResult, FetchResult, isApolloError } from '@apollo/client/core';
import { Mutation, Query } from '@/types/graphql';
import useNotify from '@utils/useNotify';

export const useGraphQL = {
	onError(queryError: unknown) {
		const apolloError = toApolloError(queryError);
		console.error(apolloError.message);
		useNotify.error('An error occurred', apolloError.message);

		throw ApolloError;
	},

	async query(query: DocumentNode | TypedDocumentNode, variables: OperationVariables | undefined = undefined, config: QueryOptions | unknown = undefined) {
		let queryOptions: QueryOptions = {
			query: query,
			fetchPolicy: 'no-cache',
		};

		if (variables && Object.keys(variables).length) {
			queryOptions = Object.assign({}, queryOptions, { variables: variables });
		}
		if (config) {
			queryOptions = Object.assign({}, queryOptions, config);
		}

		const queryResult: ApolloQueryResult<Query> = await apolloClient.watchQuery(queryOptions).result();

		if (queryResult.error) {
			this.onError(queryResult.error);
		}

		return queryResult.data;
	},

	async mutate(mutation: DocumentNode | TypedDocumentNode, variables: OperationVariables | undefined = undefined, config: MutationOptions | unknown = undefined) {
		let mutationOptions: MutationOptions = {
			mutation: mutation,
			fetchPolicy: 'no-cache',
		};

		if (variables && Object.keys(variables).length) {
			mutationOptions = Object.assign({}, mutationOptions, { variables: variables });
		}
		if (config) {
			mutationOptions = Object.assign({}, mutationOptions, config);
		}

		try {
			const mutationResult: FetchResult<Mutation> = await apolloClient.mutate(mutationOptions);

			return mutationResult.data;
		} catch (e) {
			const apolloError = toApolloError(e);
			this.onError(apolloError);
		}
	},
};

function toApolloError(error: unknown): ApolloError {
	if (!(error instanceof Error)) {
		return new ApolloError({
			networkError: Object.assign(new Error(), { originalError: error }),
			errorMessage: String(error),
		});
	}

	if (isApolloError(error)) {
		return error;
	}

	return new ApolloError({ networkError: error, errorMessage: error.message });
}

export default useGraphQL;
