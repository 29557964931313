import { WorkflowObjectType, WorkflowSchema } from '@/types/workflow';
import { nanoid } from 'nanoid';

export const initElement: WorkflowSchema = {
	id: nanoid(),
	type: WorkflowObjectType.START,
	position: {
		x: 0,
		y: 0,
	},
	children: [
		{
			id: nanoid(),
			type: WorkflowObjectType.PLUS,
			position: {
				x: 0,
				y: 1,
			},
		},
		{
			id: nanoid(),
			type: WorkflowObjectType.END,
			position: {
				x: 0,
				y: 2,
			},
		},
	],
	length: 1,
};

export const ActionTypes = {
	ADD_NEW_INTERACTION: 1,
	INITIATE_FORM: 2,
	WAIT_FOR_TRIGGER: 3,
	CREATE_TRIGGER: 4,
	WAIT_FOR_FORM_TRIGGER: 5,
	EXPRESSION: 6,
};

export const WorkblockSections = {
	pre_actions: 'Pre-actions',
	actions: 'Actions',
	post_actions: 'Post-actions',
};
