import { FormKitNode, FormKitTypeDefinition } from '@formkit/core';
import { outer, inner, wrapper, label, help, messages, message, icon, textInput, buttonInput, $attrs, defaultIcon } from '@formkit/inputs';

/**
 * Here we add our custom handlers to support
 * the unique needs of our input.
 */
function addHandlers(node: FormKitNode): void {
	node.on('created', () => {
		if (node.context) {
			node.context.handlers.updateValue = (n) => () => {
				if (node.context && !node.context.disabled) {
					// step the value
					const value = parseInt(<string>node.value) || 0;
					node.input(value + n);
					// trigger the blur handler because we've touched the input
					node.on('settled', () => {
						setTimeout(() => {
							if (node.context) {
								// ensure we run after validation
								node.context.handlers.blur();
							}
						}, 0);
					});
				}
			};
		}
	});
}

/**
 * Input definition for a text.
 * @public
 */
const stepper: FormKitTypeDefinition = {
	/**
	 * The actual schema of the input, or a function that returns the schema.
	 */
	schema: outer(
		wrapper(
			label('$label'),
			inner(
				$attrs(
					{
						class: '$classes.minusStepper',
						onClick: '$handlers.updateValue(-1)',
						type: 'button',
					},
					buttonInput(icon('minus')),
				),
				$attrs(
					{
						type: 'number',
					},
					textInput(),
				),
				$attrs(
					{
						class: '$classes.plusStepper',
						onClick: '$handlers.updateValue(1)',
						type: 'button',
					},
					buttonInput(icon('plus')),
				),
			),
		),
		help('$help'),
		messages(message('$message.value')),
	),
	/**
	 * The type of node, can be a list, group, or input.
	 */
	type: 'input',
	/**
	 * The family of inputs this one belongs too. For example "text" and "email"
	 * are both part of the "text" family. This is primary used for styling.
	 */
	family: 'text',
	/**
	 * An array of extra props to accept for this input.
	 */
	props: [],
	/**
	 * Forces node.props.type to be this explicit value.
	 */
	forceTypeProp: 'stepper',
	/**
	 * Additional features that should be added to your input
	 */
	features: [addHandlers, defaultIcon('minus', 'minus'), defaultIcon('plus', 'plus')],
};

export default stepper;
