<template>
	<div class="col-span-12 flex flex-row w-full">
		<TransitionRoot as="template" :show="conditionalBuilderOpen">
			<Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="conditionalBuilderOpen = false">
				<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6 z-50">
							<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
								<button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="conditionalBuilderOpen = false">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</button>
							</div>
							<div class="sm:flex sm:items-start">
								<div class="flex flex-row flex-grow items-center">
									<div class="flex flex-row flex-grow justify-center">
										<div class="text-center sm:ml-4 sm:text-left justify-center">
											<DialogTitle as="h1" class="text-xl font-medium text-gray-900">Conditional Builder</DialogTitle>
										</div>
									</div>
									<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"></div>
								</div>
							</div>
							<hr />
							<div v-if="conditionals.length === 0">
								<div class="rounded-lg h-72 flex flex-col justify-center items-center w-full border-black">
									<FontAwesomeIcon :icon="faClipboardListCheck" aria-hidden="true" class="h-16 w-16" />
									<h1 class="select-none mt-6 font-medium text-xl">No conditionals yet! Click to add one!</h1>
									<button
										type="button"
										class="relative inline-flex items-center px-4 py-1 my-4 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent"
										@click="addConditional"
									>
										Add condition
									</button>
								</div>
							</div>
							<div v-else class="flex flex-row w-full">
								<div class="flex flex-col w-full">
									<div v-for="(conditional, conditionalIndex) in conditionals" :key="conditional.id" class="flex flex-row w-full">
										<div class="flex flex-col px-9">
											<div
												class="flex flex-row justify-between h-full relative"
												:class="{
													'pt-4': conditionalIndex == 0,
												}"
											>
												<div class="h-full w-1/2 border-r-2"></div>
												<div v-if="conditionalIndex != 0" class="absolute flex justify-center bg-white font-semibold text-sm -mx-3 mt-6">AND</div>
											</div>
										</div>
										<div class="flex flex-row w-full py-2">
											<div
												class="flex flex-col w-full border border-dashed px-2"
												:class="{
													'border-white': hoverConditionals != conditionalIndex,
													'border-red-500': hoverConditionals == conditionalIndex,
												}"
											>
												<div v-for="(condition, conditionIndex) in conditional.conditions" :key="conditionIndex" class="flex flex-row w-full justify-between">
													<div
														class="flex flex-row w-full border border-dashed pl-1"
														:class="{
															'border-white': hoverCondition != conditionalIndex + '_' + conditionIndex,
															'border-red-500': hoverCondition == conditionalIndex + '_' + conditionIndex,
														}"
													>
														<div v-if="conditionIndex !== 0" class="flex flex-col h-full w-10">
															<div class="flex flex-row justify-between items-center h-full relative">
																<div
																	class="h-full w-1/2 border-r-2"
																	:class="{
																		'mt-2': conditionIndex == 0,
																		'mb-4': conditionIndex == conditional.conditions.length - 1,
																	}"
																></div>
																<div class="absolute mx-2 flex flex-col justify-center bg-white font-semibold text-sm">OR</div>
															</div>
														</div>
														<div class="grid grid-cols-12 gap-x-2 w-full my-2">
															<FormKit
																v-model="condition.field"
																type="dropdown"
																:ignore="true"
																:classes="{
																	outer: 'col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4',
																}"
																:options="getOptionsAvailableFields"
																placeholder="Field"
															/>
															<FormKit
																v-model="condition.condition"
																type="dropdown"
																:ignore="true"
																:classes="{
																	outer: 'col-span-12 sm:col-span-12 lg:col-span-3 xl:col-span-3',
																}"
																:options="getOptionsForSelectedField(condition.field)"
																placeholder="Condition"
															/>
															<template
																v-if="
																	condition.condition &&
																	condition.condition != FieldConditions.TRUE &&
																	condition.condition != FieldConditions.FALSE &&
																	condition.condition != FieldConditions.EMPTY &&
																	condition.condition != FieldConditions.NOT_EMPTY
																"
															>
																<template v-if="condition.condition == FieldConditions.BETWEEN">
																	<FormKit
																		v-model="condition.lower_value"
																		type="text"
																		:ignore="true"
																		:classes="{
																			outer: 'col-span-12 sm:col-span-12 lg:col-span-2 xl:col-span-2',
																		}"
																		placeholder="Lower value"
																	/>
																	<FormKit
																		v-model="condition.upper_value"
																		type="text"
																		:ignore="true"
																		:classes="{
																			outer: 'col-span-12 sm:col-span-12 lg:col-span-2 xl:col-span-2',
																		}"
																		placeholder="Upper value"
																	/>
																</template>
																<template v-else-if="condition.condition == FieldConditions.MATCHES_FIELD">
																	<FormKit
																		type="dropdown"
																		:value="condition.value"
																		:ignore="true"
																		:classes="{
																			outer: 'col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4',
																		}"
																		:options="getOptionsAvailableFields"
																		placeholder="Value"
																		@input="(value) => (condition.value = value.id)"
																	/>
																</template>
																<template v-else>
																	<FormKit
																		v-model="condition.value"
																		type="text"
																		:ignore="true"
																		:classes="{
																			outer: 'col-span-12 sm:col-span-12 lg:col-span-4 xl:col-span-4',
																		}"
																		placeholder="Value"
																	/>
																</template>
															</template>
														</div>
													</div>
													<div class="flex flex-row justify-center items-center text-danger-500 pl-2 cursor-pointer">
														<button
															type="button"
															class="relative inline-flex items-center px-1 py-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
															@mouseenter="hoverCondition = conditionalIndex + '_' + conditionIndex"
															@mouseleave="hoverCondition = undefined"
															@click="deleteCondition(conditionalIndex, conditionIndex)"
														>
															<FontAwesomeIcon :icon="faTrashAlt" aria-hidden="true" class="h-4 w-4" />
														</button>
													</div>
												</div>
												<div class="h-8 py-1 flex flex-row justify-between">
													<button
														type="button"
														class="relative inline-flex items-center px-2 py-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap transform -translate-x-1"
														@click="addCondition(conditionalIndex)"
													>
														+ OR
													</button>
													<button
														type="button"
														class="peer relative inline-flex items-center px-2 py-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
														@mouseenter="hoverConditionals = conditionalIndex"
														@mouseleave="hoverConditionals = undefined"
														@click="deleteConditional(conditionalIndex)"
													>
														<FontAwesomeIcon :icon="faTrashAlt" aria-hidden="true" class="h-4 w-4 mr-1" />
														AND
													</button>
												</div>
											</div>
										</div>
									</div>
									<div class="h-8 pt-2">
										<button
											type="button"
											class="relative inline-flex items-center px-2 py-1 mx-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
											@click="addConditional"
										>
											+ AND
										</button>
									</div>
								</div>
							</div>
							{{ stringConditional }}
							<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
									@click="saveConditionToElement"
								>
									Save
								</button>
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm bg-white border-2 border-red-500 text-red-500"
									@click="conditionalBuilderOpen = false"
								>
									Close
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>

		<div
			class="flex flex-row w-full"
			:class="{
				'justify-between': conditionals.length > 0,
				'justify-start': conditionals.length == 0,
			}"
		>
			<div v-if="conditionals">
				<div v-for="conditional in conditionals" :key="conditional.id">
					<div v-for="condition in conditional.conditions" :key="condition.id">
						<div class="whitespace-nowrap flex flex-row space-x-1">
							<span class="font-semibold">'{{ condition.field?.id }}'</span>
							<span>{{ FieldConditionsLabel[condition.condition] }}</span>
							<span v-if="condition.value" class="font-semibold">"{{ condition.value }}"</span>
						</div>
					</div>
				</div>
			</div>
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
				@click="conditionalBuilderOpen = true"
			>
				Open conditional builder
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { FormKitFrameworkContext } from '@formkit/core';
	import { useFormStore } from '@modules/form/store';
	import { faClipboardListCheck, faTrashAlt, faXmark } from '@fortawesome/pro-light-svg-icons';
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { computed, defineProps, ref } from 'vue';
	import { nanoid } from 'nanoid';
	import { Conditional, ConditionalField, ConditionalType, FieldConditions, FieldConditionsLabel, FieldConditionsOperator } from '@/types/form';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const conditionalBuilderOpen = ref<boolean>(false);
	const formStore = useFormStore();
	const conditionals = ref<Conditional[]>(formStore.editingElement?.meta?.conditionals ?? []);

	const hoverConditionals = ref<number>();
	const hoverCondition = ref<string>();

	defineProps<Props>();

	formStore.getActiveSchemaFields();

	function addConditional() {
		conditionals.value.push({
			id: nanoid(),
			type: ConditionalType.AND,
			conditions: [
				{
					id: nanoid(),
					type: ConditionalType.OR,
					field: undefined,
					condition: undefined,
					value: undefined,
				},
			],
		});
	}

	function addCondition(conditionalIndex: number) {
		conditionals.value[conditionalIndex].conditions.push({
			id: nanoid(),
			type: ConditionalType.OR,
			field: undefined,
			condition: undefined,
			value: undefined,
		});
	}

	function saveConditionToElement() {
		const rootElement = formStore.getEditingElement();

		rootElement['if'] = stringConditional.value;
		if (!('meta' in rootElement)) {
			rootElement['meta'] = {};
		}
		rootElement['meta']['conditionals'] = conditionals;

		conditionalBuilderOpen.value = false;
	}

	function deleteConditional(conditionalIndex: number) {
		conditionals.value.splice(conditionalIndex, 1);
	}

	function deleteCondition(conditionalIndex: number, conditionIndex: number) {
		conditionals.value[conditionalIndex].conditions.splice(conditionIndex, 1);
	}

	const stringConditional = computed<string>(() => {
		let output = '';
		for (const conditional of conditionals.value) {
			if (output !== '') {
				output = output + ' && ';
			}
			if (conditionals.value.length > 1) {
				output = output + '(';
			}
			let conditionOutput = '';
			for (const condition of conditional.conditions) {
				if (condition.field && condition.condition) {
					if (conditionOutput !== '') {
						conditionOutput = conditionOutput + ' || ';
					}
					if (conditional.conditions.length > 1) {
						conditionOutput = conditionOutput + '(';
					}
					conditionOutput = conditionOutput + '$get(' + condition.field.id + ').value ';
					if ([FieldConditions.EMPTY, FieldConditions.NOT_EMPTY, FieldConditions.TRUE, FieldConditions.FALSE].includes(condition.condition as FieldConditions)) {
						conditionOutput = conditionOutput + FieldConditionsOperator[condition.condition as FieldConditions];
					} else {
						conditionOutput = conditionOutput + FieldConditionsOperator[condition.condition as FieldConditions] + ' "' + (condition.value ?? '') + '"';
					}
					if (conditional.conditions.length > 1) {
						conditionOutput = conditionOutput + ')';
					}
				}
			}
			output = output + conditionOutput;
			if (conditionals.value.length > 1) {
				output = output + ')';
			}
		}
		return output;
	});

	function getOptionsAvailableFields() {
		const fieldList = [];
		for (const field of formStore.activeSchemaFields) {
			fieldList.push({
				label: field.label,
				value: { id: field.id, type: field.type },
			});
		}
		return fieldList;
	}

	const getOptionsForSelectedField = (field: ConditionalField | undefined) => {
		if (field && field.type) {
			const availableConditions = [];
			if (['text', 'toggle', 'stackedCardGroup', 'cardGroup', 'select', 'dropdown', 'number', 'password', 'textarea', 'date', 'datetime', 'time', 'telephone', 'range', 'email', 'repeater', 'rating', 'taglist'].includes(field.type)) {
				availableConditions.push(
					{
						value: FieldConditions.EMPTY,
						label: FieldConditionsLabel[FieldConditions.EMPTY],
					},
					{
						value: FieldConditions.NOT_EMPTY,
						label: FieldConditionsLabel[FieldConditions.NOT_EMPTY],
					},
				);
			}

			if (['toggle'].includes(field.type)) {
				availableConditions.push(
					{
						value: FieldConditions.TRUE,
						label: FieldConditionsLabel[FieldConditions.TRUE],
					},
					{
						value: FieldConditions.FALSE,
						label: FieldConditionsLabel[FieldConditions.FALSE],
					},
				);
			}

			if (['text', 'toggle', 'stackedCardGroup', 'cardGroup', 'select', 'dropdown', 'number', 'password', 'textarea', 'date', 'datetime', 'time', 'telephone', 'range', 'email', 'rating'].includes(field.type)) {
				availableConditions.push(
					{
						value: FieldConditions.EQUAL_TO,
						label: FieldConditionsLabel[FieldConditions.EQUAL_TO],
					},
					{
						value: FieldConditions.STRICT_EQUAL_TO,
						label: FieldConditionsLabel[FieldConditions.STRICT_EQUAL_TO],
					},
					{
						value: FieldConditions.NOT_EQUAL_TO,
						label: FieldConditionsLabel[FieldConditions.NOT_EQUAL_TO],
					},
					{
						value: FieldConditions.STRICT_NOT_EQUAL_TO,
						label: FieldConditionsLabel[FieldConditions.STRICT_NOT_EQUAL_TO],
					},
				);
			}

			if (['number', 'date', 'datetime', 'time', 'range'].includes(field.type)) {
				availableConditions.push(
					{
						value: FieldConditions.GREATER_THAN,
						label: FieldConditionsLabel[FieldConditions.GREATER_THAN],
					},
					{
						value: FieldConditions.GREATER_THAN_OR_EQUAL,
						label: FieldConditionsLabel[FieldConditions.GREATER_THAN_OR_EQUAL],
					},
					{
						value: FieldConditions.LESS_THAN,
						label: FieldConditionsLabel[FieldConditions.LESS_THAN],
					},
					{
						value: FieldConditions.LESS_THAN_OR_EQUAL,
						label: FieldConditionsLabel[FieldConditions.LESS_THAN_OR_EQUAL],
					},
					{
						value: FieldConditions.BETWEEN,
						label: FieldConditionsLabel[FieldConditions.BETWEEN],
					},
				);
			}

			if (['text', 'toggle', 'stackedCardGroup', 'cardGroup', 'select', 'dropdown', 'number', 'password', 'textarea', 'date', 'datetime', 'time', 'telephone', 'range', 'email', 'rating'].includes(field.type)) {
				availableConditions.push({
					value: FieldConditions.MATCHES_FIELD,
					label: FieldConditionsLabel[FieldConditions.MATCHES_FIELD],
				});
			}

			return availableConditions;
		}
		return [];
	};
</script>
