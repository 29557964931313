import gql from 'graphql-tag';

export const GET_FORMS = gql`
	query Data($first: Int!, $page: Int) {
		getForms(first: $first, page: $page) {
			data {
				id
				type_id
				status_id
				settings
				name
				reference_name
				description
				current_version_id
				version_history
				updated_at
				updater {
					first_name
					middle_name
					last_name
					id
				}
				created_at
				creator {
					first_name
					middle_name
					last_name
					id
				}
				archived_at
				archiver {
					last_name
					middle_name
					first_name
					id
				}
				published_at
				publisher {
					last_name
					middle_name
					first_name
					id
				}
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_ACTIVE_FORMS_DROPDOWN = gql`
	query Data($first: Int!, $page: Int) {
		getForms(first: $first, page: $page) {
			data {
				id
				name
				description
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_FORM_BY_ID = gql`
	query Data($form_id: ID) {
		getFormById(id: $form_id) {
			id
			type_id
			status_id
			settings
			name
			reference_name
			description
			current_version_id
			current_version_replaces
			current_version {
				id
				form_id
				version
				schema
				created_at
				created_by
				updated_at
				updated_by
			}
			version_history
			published_at
			published_by
			archived_at
			archived_by
			created_at
			created_by
			updated_at
			updated_by
			current_version_at
			current_version_by
		}
	}
`;

export const GET_FORM_REQUEST_BY_ID = gql`
	query Data($form_request_id: ID!) {
		getFormRequestById(id: $form_request_id) {
			id
			status
			visibility
			form_key
			settings
			data
			form {
				id
				name
				reference_name
				description
			}
			form_version {
				id
				version
				schema
			}
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
			created_at
			creator {
				first_name
				middle_name
				last_name
				id
			}
		}
	}
`;
