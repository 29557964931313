<template>
	<div class="col-span-12 flex flex-row w-full">
		<TransitionRoot as="template" :show="expressionBuilderOpen">
			<Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="expressionBuilderOpen = false">
				<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4/5 sm:w-full sm:p-6 z-50">
							<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
								<button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="expressionBuilderOpen = false">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</button>
							</div>
							<div class="sm:flex sm:items-start">
								<div class="flex flex-row flex-grow items-center">
									<div class="flex flex-row flex-grow justify-center">
										<div class="text-center sm:ml-4 sm:text-left justify-center">
											<DialogTitle as="h1" class="text-xl font-medium text-gray-900">Expression Builder</DialogTitle>
										</div>
									</div>
									<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"></div>
								</div>
							</div>
							<div class="flex flex-row w-full">
								<div class="flex flex-col w-full">
									<div v-for="(expression, expressionIndex) in expressions" :key="expressionIndex" class="flex flex-row w-full p-1">
										<div class="flex flex-col w-full">
											<div
												class="flex flex-row justify-between border border-dashed w-full p-1"
												:class="{
													'border-white': hoverExpression != expressionIndex,
													'border-red-500': hoverExpression == expressionIndex,
												}"
												@mouseenter="hoverExpression = expressionIndex"
												@mouseleave="hoverExpression = undefined"
											>
												<div class="flex flex-row">
													<hr v-if="expressionIndex > 0" />
													<div v-if="!expression.variable.type" class="flex flex-row">
														<FormKit
															v-model="expression.variable.type"
															type="dropdown"
															:ignore="true"
															label="Variable type"
															:options="[
																{
																	label: 'Local variable',
																	value: ExpressionVariableType.LOCAL,
																},
																{
																	label: 'Global variable',
																	value: ExpressionVariableType.GLOBAL,
																},
															]"
														/>
													</div>
													<div v-else-if="expression.variable.type == ExpressionVariableType.GLOBAL" class="flex flex-col justify-center items-center">
														<FormKit v-model="expression.variable.label" type="text" placeholder="Enter Label" validation="required|safeVariableString" :ignore="true" validation-visibility="live" label="Global variable" />
													</div>
													<div v-else-if="expression.variable.type == ExpressionVariableType.LOCAL" class="flex flex-col justify-center items-center">
														<div class="flex flex-row justify-center items-center">
															<FormKit v-model="expression.variable.label" type="text" placeholder="Enter Label" validation="required|safeAlphaNumeric" :ignore="true" validation-visibility="live" label="Local variable" />
														</div>
													</div>
													<template v-if="expression.variable.type">
														<div class="flex flex-col justify-center items-center px-2">=</div>
														<div v-if="expression.type === ExpressionType.EQUATION" class="border-l border-t border-b border-black px-1 flex flex-col justify-center items-center"></div>
														<div class="flex flex-row -pb-1 w-full">
															<div v-if="expression.type === ExpressionType.EQUATION" class="flex flex-row space-x-2">
																<div class="flex flex-col justify-center items-start border-l border-r border-black rounded px-1 my-1 pt-1 w-96">
																	<div class="flex flex-row items-center justify-center space-x-1">
																		<FormKit
																			v-model="expression.equation.left_type"
																			type="dropdown"
																			:ignore="true"
																			:disabled="[FieldConditions.EQUALS, FieldConditions.ABSOLUTE_VALUE].includes(expression?.equation?.operator)"
																			placeholder="Select value type"
																			popover
																			:options="[
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.LOCAL],
																					value: ExpressionVariableType.LOCAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.GLOBAL],
																					value: ExpressionVariableType.GLOBAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.STATIC],
																					value: ExpressionVariableType.STATIC,
																				},
																			]"
																		/>
																		<FormKit v-if="expression.equation.left_type === ExpressionVariableType.STATIC" v-model="expression.equation.left" :ignore="true" type="text" placeholder="Value" />
																		<FormKit
																			v-else-if="expression.equation.left_type === ExpressionVariableType.LOCAL"
																			v-model="expression.equation.left"
																			:ignore="true"
																			:disabled="[FieldConditions.EQUALS, FieldConditions.ABSOLUTE_VALUE].includes(expression?.equation?.operator)"
																			type="dropdown"
																			placeholder="Select variable"
																			popover
																			:options="localVariables"
																		/>
																		<FormKit v-if="expression.equation.left_type === ExpressionVariableType.GLOBAL" v-model="expression.equation.left" :ignore="true" type="text" placeholder="Global variable" />
																	</div>
																</div>
																<div class="flex flex-col justify-center items-center px-1 my-1 pt-1 max-w-[175px]">
																	<FormKit
																		v-model="expression.equation.operator"
																		type="dropdown"
																		:ignore="true"
																		placeholder="Select operator"
																		:help="expression.equation.operator == FieldConditions.POWER_OF ? 'If the output of the function is an incalculable number the absolute value of the base will be used. ' : 'test'"
																		:options="[
																			{
																				value: FieldConditions.GREATER_THAN,
																				label: FieldConditionsOperator[FieldConditions.GREATER_THAN],
																			},
																			{
																				value: FieldConditions.GREATER_THAN_OR_EQUAL,
																				label: FieldConditionsOperator[FieldConditions.GREATER_THAN_OR_EQUAL],
																			},
																			{
																				value: FieldConditions.LESS_THAN,
																				label: FieldConditionsOperator[FieldConditions.LESS_THAN],
																			},
																			{
																				value: FieldConditions.LESS_THAN_OR_EQUAL,
																				label: FieldConditionsOperator[FieldConditions.LESS_THAN_OR_EQUAL],
																			},
																			{
																				value: FieldConditions.EQUAL_TO,
																				label: FieldConditionsOperator[FieldConditions.EQUAL_TO],
																			},
																			{
																				value: FieldConditions.STRICT_EQUAL_TO,
																				label: FieldConditionsOperator[FieldConditions.STRICT_EQUAL_TO],
																			},
																			{
																				value: FieldConditions.NOT_EQUAL_TO,
																				label: FieldConditionsOperator[FieldConditions.NOT_EQUAL_TO],
																			},
																			{
																				value: FieldConditions.STRICT_NOT_EQUAL_TO,
																				label: FieldConditionsOperator[FieldConditions.STRICT_NOT_EQUAL_TO],
																			},
																			{
																				value: FieldConditions.ADDITION,
																				label: FieldConditionsOperator[FieldConditions.ADDITION],
																			},
																			{
																				value: FieldConditions.SUBTRACTION,
																				label: FieldConditionsOperator[FieldConditions.SUBTRACTION],
																			},
																			{
																				value: FieldConditions.DIVISION,
																				label: FieldConditionsOperator[FieldConditions.DIVISION],
																			},
																			{
																				value: FieldConditions.MULTIPLICATION,
																				label: FieldConditionsOperator[FieldConditions.MULTIPLICATION],
																			},
																			{
																				value: FieldConditions.EQUALS,
																				label: FieldConditionsOperator[FieldConditions.EQUALS],
																			},
																			{
																				value: FieldConditions.ABSOLUTE_VALUE,
																				label: FieldConditionsOperator[FieldConditions.ABSOLUTE_VALUE],
																			},
																			{
																				value: FieldConditions.POWER_OF,
																				label: FieldConditionsOperator[FieldConditions.POWER_OF],
																			},
																			{
																				value: FieldConditions.MODULUS,
																				label: FieldConditionsOperator[FieldConditions.MODULUS],
																			},
																		]"
																		@input="
																			(event) => {
																				if (event === FieldConditions.EQUALS) {
																					expression.equation.left_type = ExpressionVariableType.LOCAL;
																					expression.equation.left = expression.id;
																				}
																			}
																		"
																	/>
																</div>
																<div class="flex flex-col justify-center items-center border-l border-r border-black rounded px-1 my-1 pt-1">
																	<div class="flex flex-row items-center justify-center space-x-1">
																		<FormKit
																			v-model="expression.equation.right_type"
																			type="dropdown"
																			placeholder="Select value type"
																			:ignore="true"
																			:options="[
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.LOCAL],
																					value: ExpressionVariableType.LOCAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.GLOBAL],
																					value: ExpressionVariableType.GLOBAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.STATIC],
																					value: ExpressionVariableType.STATIC,
																				},
																			]"
																		/>
																		<FormKit v-if="expression.equation.right_type === ExpressionVariableType.STATIC" v-model="expression.equation.right" :ignore="true" type="text" placeholder="Value" />
																		<FormKit
																			v-else-if="expression.equation.right_type === ExpressionVariableType.LOCAL"
																			v-model="expression.equation.right"
																			:ignore="true"
																			type="dropdown"
																			placeholder="Select variable"
																			:options="localVariables"
																		/>
																		<FormKit v-if="expression.equation.right_type === ExpressionVariableType.GLOBAL" v-model="expression.equation.right" :ignore="true" type="text" placeholder="Global variable" />
																	</div>
																</div>
															</div>
															<div v-else-if="expression.type === ExpressionType.CONDITIONAL" class="flex flex-row space-x-2"></div>
															<div v-else-if="expression.type === ExpressionType.FUNCTION" class="flex flex-row space-x-2">
																<div class="flex flex-col justify-center items-center px-1 my-1 pt-1">
																	<FormKit v-model="expression.function.function" type="dropdown" :ignore="true" placeholder="Select function" :options="getFunctionList" />
																</div>
																<div class="flex flex-col justify-center items-center px-1 my-1 pt-1">
																	<div v-for="functionOptions in getFunctionOptions(expression.function.function)" :key="functionOptions.id" class="flex flex-row">
																		<FormKit
																			:ignore="true"
																			:placeholder="functionOptions.label"
																			:required="functionOptions.required ?? false"
																			:type="functionOptions.type ?? 'text'"
																			:value="expression.function.options[functionOptions.name]"
																			:help="functionOptions.help ?? false"
																			@input="(value) => (expression.function.options[functionOptions.name] = value)"
																		/>
																	</div>
																</div>
																<div v-if="getFunctionSettings(expression.function.function)?.arguments ?? true" :key="expression.function.function" class="flex flex-col justify-center items-center px-1 my-1 pt-1">
																	<h2>Function Arguments</h2>
																	<FormKit v-if="getFunctionSettings(expression.function.function)?.multiple" v-slot="{ index }" v-model="expression.function.arguments" type="repeater" :ignore="true">
																		<FormKit
																			name="type"
																			type="dropdown"
																			placeholder="Select value type"
																			:options="[
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.LOCAL],
																					value: ExpressionVariableType.LOCAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.GLOBAL],
																					value: ExpressionVariableType.GLOBAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.STATIC],
																					value: ExpressionVariableType.STATIC,
																				},
																			]"
																		/>
																		<FormKit v-if="expression.function.arguments[index].type === ExpressionVariableType.STATIC" name="value" type="text" placeholder="Value" />
																		<FormKit v-else-if="expression.function.arguments[index].type === ExpressionVariableType.LOCAL" name="value" type="dropdown" placeholder="Select variable" :options="localVariables" />
																		<FormKit v-if="expression.function.arguments[index].type === ExpressionVariableType.GLOBAL" name="value" type="text" placeholder="Global variable" />
																	</FormKit>
																	<FormKit v-else v-slot="{ index }" v-model="expression.function.arguments" max="1" type="repeater" :ignore="true">
																		<FormKit
																			name="type"
																			type="dropdown"
																			placeholder="Select value type"
																			:options="[
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.LOCAL],
																					value: ExpressionVariableType.LOCAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.GLOBAL],
																					value: ExpressionVariableType.GLOBAL,
																				},
																				{
																					label: ExpressionVariableTypeLabel[ExpressionVariableType.STATIC],
																					value: ExpressionVariableType.STATIC,
																				},
																			]"
																		/>
																		<FormKit v-if="expression.function.arguments[index].type === ExpressionVariableType.STATIC" name="value" type="text" placeholder="Value" />
																		<FormKit v-else-if="expression.function.arguments[index].type === ExpressionVariableType.LOCAL" name="value" type="dropdown" placeholder="Select variable" :options="localVariables" />
																		<FormKit v-if="expression.function.arguments[index].type === ExpressionVariableType.GLOBAL" name="value" type="text" placeholder="Global variable" />
																	</FormKit>
																</div>
															</div>
														</div>
														<div v-if="expression.type === ExpressionType.EQUATION" class="border-r border-t border-b border-black px-1 flex flex-col justify-center items-center"></div>
													</template>
												</div>

												<div class="flex flex-col space-y-0.5 bg-gray-100 justify-center">
													<div class="flex flex-row items-center">
														<button
															type="button"
															class="relative inline-flex h-7 w-7 justify-center items-center px-1 py-1 rounded-md border bg-gray-200 hover:bg-gray-300 text-sm font-medium focus:z-10 focus:outline-none text-black border-transparent whitespace-nowrap"
															@click="shiftElement(expressionIndex, 'up')"
														>
															<FontAwesomeIcon :icon="faArrowUp" aria-hidden="true" class="h-4 w-4" />
														</button>
													</div>
													<div class="flex flex-row items-center">
														<button
															type="button"
															class="relative inline-flex h-7 w-7 justify-center items-center px-1 py-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
															@click="deleteExpression(expressionIndex)"
														>
															<FontAwesomeIcon :icon="faTrashAlt" aria-hidden="true" class="h-4 w-4" />
														</button>
													</div>
													<div class="flex flex-row items-center">
														<button
															type="button"
															class="relative inline-flex h-7 w-7 justify-center items-center px-1 py-1 rounded-md border bg-gray-200 hover:bg-gray-300 text-sm font-medium focus:z-10 focus:outline-none text-black border-transparent whitespace-nowrap"
															@click="shiftElement(expressionIndex, 'down')"
														>
															<FontAwesomeIcon :icon="faArrowDown" aria-hidden="true" class="h-4 w-4" />
														</button>
													</div>
												</div>
											</div>
											<div v-if="expressionIndex !== expressions.length - 1" class="flex flex-row w-full border-b my-0.5"></div>
										</div>
									</div>
									<div class="h-8 mt-2">
										<button
											type="button"
											class="relative inline-flex items-center px-2 py-1 mx-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
											@click="addEquation"
										>
											+ Equation
										</button>
										<button
											type="button"
											class="relative inline-flex items-center px-2 py-1 mx-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
											@click="addConditional"
										>
											+ Conditional
										</button>
										<button
											type="button"
											class="relative inline-flex items-center px-2 py-1 mx-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
											@click="addFunction"
										>
											+ Function
										</button>
									</div>
								</div>
							</div>

							<!--							<div class="border-2">
								<div class="builder-header">
									<div class="flex flex-row items-center justify-left py-1.5 px-2 space-x-1.5 border-b-2">
										<TextEditorMenuItem title="Add" :icon="faPlus" width="8" @click="" />
										<TextEditorMenuItem title="Subtract" :icon="faSubtract" width="8" @click="" />
										<TextEditorMenuItem title="Multiply" :icon="faMultiply" width="8" @click="" />
										<TextEditorMenuItem title="Divide" :icon="faDivide" width="8" @click="" />
										<TextEditorMenuItem title="Modulus" text="%" width="8" @click="" />
										<div class="inline-flex items-center justify-center h-8 w-2.5 text-sm font-medium rounded-md focus:outline-none">
											<span class="h-6 border-r-2 border-gray-300"> </span>
										</div>
										<TextEditorMenuItem title="Strict equals" text="===" width="8" @click="" />
										<TextEditorMenuItem title="Strict not equals" text="!==" width="8" @click="" />
										<TextEditorMenuItem title="Loose equals" text="==" width="8" @click="" />
										<TextEditorMenuItem title="Loose not equals" text="!=" width="8" @click="" />
										<TextEditorMenuItem title="Greater than or equal" :icon="faGreaterThanEqual" width="8" @click="" />
										<TextEditorMenuItem title="Less than or equal" :icon="faLessThanEqual" width="8" @click="" />
										<TextEditorMenuItem title="Greater than" :icon="faGreaterThan" width="8" @click="" />
										<TextEditorMenuItem title="Less than" :icon="faLessThan" width="8" @click="" />
										<div class="inline-flex items-center justify-center h-8 w-2.5 text-sm font-medium rounded-md focus:outline-none">
											<span class="h-6 border-r-2 border-gray-300"> </span>
										</div>
										<TextEditorMenuItem title="Variable" :icon="faSigma" width="8" @click="" />
									</div>
								</div>
								<div class="builder-content">
									<div class="flex flex-row items-center justify-left py-1.5 px-2 space-x-1.5">content</div>
								</div>
							</div>-->
							<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
									@click="saveExpression"
								>
									Save
								</button>
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm bg-white border-2 border-red-500 text-red-500"
									@click="expressionBuilderOpen = false"
								>
									Close
								</button>
							</div>
							<DebugSection>{{ expressions }}</DebugSection>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>

		<div
			class="flex flex-row w-full"
			:class="{
				'justify-between': expressions.length > 0,
				'justify-start': expressions.length == 0,
			}"
		>
			<button
				type="button"
				class="relative inline-flex items-center px-4 py-2 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
				@click="expressionBuilderOpen = true"
			>
				Open expression builder
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { FormKitFrameworkContext } from '@formkit/core';
	import { useFormStore } from '@modules/form/store';
	import { faArrowDown, faArrowUp, faTrashAlt, faXmark } from '@fortawesome/pro-light-svg-icons';
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { computed, defineProps, onMounted, ref } from 'vue';
	import { nanoid } from 'nanoid';
	import { ConditionalType, Expression, ExpressionType, ExpressionVariableType, ExpressionVariableTypeLabel, FieldConditions, FieldConditionsOperator } from '@/types/form';
	import clone from '@utils/useClone';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { safeUnpack } from '@utils/helpers';
	import DebugSection from '@components/DebugSection.vue';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const expressionBuilderOpen = ref<boolean>(false);
	const formStore = useFormStore();
	const workflowStore = useWorkflowStore();
	const props = defineProps<Props>();
	const expressions = ref<Expression[]>(clone(props.context.value ?? ([] as Expression[])));

	const hoverExpression = ref<number>();

	formStore.getActiveSchemaFields();

	function shiftElement(index: number, direction: 'up' | 'down'): void {
		if (index < 0 || index >= expressions.value.length) {
			return;
		}

		if (direction === 'up' && index > 0) {
			const temp = expressions.value[index];
			expressions.value[index] = expressions.value[index - 1];
			expressions.value[index - 1] = temp;
		} else if (direction === 'down' && index < expressions.value.length - 1) {
			const temp = expressions.value[index];
			expressions.value[index] = expressions.value[index + 1];
			expressions.value[index + 1] = temp;
		}
	}

	function addEquation() {
		expressions.value.push({
			id: nanoid(),
			type: ExpressionType.EQUATION,
			variable: {
				label: undefined,
				type: undefined,
				variable: undefined,
			},
			equation: {
				id: nanoid(),
				left: undefined,
				left_type: undefined,
				operator: undefined,
				right: undefined,
				right_type: undefined,
			},
		});
	}

	function addFunction() {
		expressions.value.push({
			id: nanoid(),
			type: ExpressionType.FUNCTION,
			variable: {
				label: undefined,
				type: undefined,
				variable: undefined,
			},
			function: {
				id: nanoid(),
				function: undefined,
				options: {},
				arguments: undefined,
			},
		});
	}

	function addConditional() {
		expressions.value.push({
			id: nanoid(),
			type: ExpressionType.CONDITIONAL,
			variable: {
				label: undefined,
				type: undefined,
				variable: undefined,
			},
			conditional: {
				id: nanoid(),
				type: ConditionalType.AND,
				conditions: [
					{
						id: nanoid(),
						type: ConditionalType.OR,
						field: undefined,
						condition: undefined,
						value: undefined,
					},
				],
			},
		});
	}

	function saveExpression() {
		updateInputContext();
		expressionBuilderOpen.value = false;
	}

	function deleteExpression(expressionIndex: number) {
		expressions.value.splice(expressionIndex, 1);
	}

	onMounted(() => {
		workflowStore.getWorkblockExpressionFunctions();
	});

	const getFunctionList = computed(() => {
		const functionList = [];
		for (const expressionFunction of workflowStore.workblockExpressionFunctions) {
			functionList.push({
				value: expressionFunction.id,
				label: expressionFunction.name,
			});
		}
		return functionList;
	});

	function getFunctionOptions(functionId: number) {
		for (const expressionFunction of workflowStore.workblockExpressionFunctions) {
			if (expressionFunction.id == functionId) {
				return safeUnpack(expressionFunction.options, []);
			}
		}
	}
	function getFunctionSettings(functionId: number) {
		for (const expressionFunction of workflowStore.workblockExpressionFunctions) {
			if (expressionFunction.id == functionId) {
				return safeUnpack(expressionFunction.settings, []);
			}
		}
	}

	const localVariables = computed(() => {
		const localVariablesList = [];
		for (const local of expressions.value) {
			if (local.variable.type == ExpressionVariableType.LOCAL) {
				localVariablesList.push({
					value: local.id,
					label: local.variable.label ?? 'Unlabeled',
				});
			}
		}
		return localVariablesList;
	});

	/*
	watch(
		expressions,
		(newValue) => {
			console.dir(newValue);
			updateInputContext();
		},
		{ deep: true },
	);
	watch(returnValue, (newValue) => {
		console.dir(newValue);
		updateInputContext();
	});
	watch(returnType, (newValue) => {
		console.dir(newValue);
		updateInputContext();
	});
*/

	function updateInputContext() {
		console.dir('updating context object');
		props.context.node.input(expressions.value);
	}
</script>
