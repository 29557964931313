import { ApiConfig as ApiConfigType } from '@/types/api';

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL?.toString().endsWith('/') ? import.meta.env.VITE_API_BASE_URL?.toString() : import.meta.env.VITE_API_BASE_URL?.toString() + '/';

export const ApiConfig: ApiConfigType = {
	base_url: apiBaseUrl,
	graphql_url: import.meta.env.VITE_GRAPHQL_URL?.toString() ?? '',
	broadcast_auth_url: (import.meta.env.VITE_AUTH_BASE_URL?.toString() ?? '') + '/broadcasting/auth',
	subscription_auth_url: (import.meta.env.VITE_GRAPHQL_URL?.toString() ?? '') + '/subscriptions/auth',
};
