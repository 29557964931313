import { RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@modules/auth/store';

export const guestRoutes: RouteRecordRaw[] = [
	{
		path: '/login',
		name: 'login',
		component: () => import('./pages/login.vue'),
	},
	{
		path: '/challenge',
		name: 'challenge',
		component: () => import('./pages/challenge.vue'),
	},
	{
		path: '/reset',
		name: 'reset',
		component: () => import('./pages/reset.vue'),
	},
	{
		path: '/forgot',
		name: 'forgot',
		component: () => import('./pages/forgot.vue'),
	},
	{
		path: '/logout',
		name: 'logout',
		component: () => import('./pages/login.vue'),
		beforeEnter: () => {
			const authStore = useAuthStore();
			return authStore.logout();
		},
	},
];

export const authRoutes: RouteRecordRaw[] = [];

export const adminRoutes: RouteRecordRaw[] = [];

export default [...guestRoutes, ...authRoutes, ...adminRoutes];
