import { SentryConfig as SentryConfigType } from '@/types/sentry';
import { AppConfig } from '@/config/app';

export const SentryConfig: SentryConfigType = {
	enable: !!JSON.parse(import.meta.env.VITE_SENTRY_ENABLED?.toString() ?? ''),
	dsn: import.meta.env.VITE_SENTRY_DSN?.toString() ?? 'https://examplePublicKey@o0.ingest.sentry.io/0',
	release: AppConfig.version,
	environment: AppConfig.environment,
	performanceSampleRate: 1.0,
	sessionReplaySampleRate: 0.1,
	sessionReplayOnErrorSampleRate: 1.0,
	captureCrashReports: false,
	allowUserFeedback: true,
};
