import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { UnstructuredFormOutput } from '@/types/form';
import { WorkblockTemplate } from '@/types/graphql';

export type WorkflowConfig = {
	gapHeight: number;
	exGapHeight: number;
	gapWidth: number;
	exBoxHeight: number;
	boxHeight: number;
	boxWidth: number;
};

export type ElementPosition = {
	x: number;
	y: number;
};

export enum ZoomType {
	fit = 'fit',
	in = 'in',
	out = 'out',
	center = 'center',
}

export type BoundingRect = {
	left: number;
	width: number;
	right: number;
	top: number;
	bottom: number;
	height: number;
};

export type ZoomControl = {
	type: ZoomType;
	width: number;
	height: number;
	offset: number;
	boundingRect: BoundingRect;
};

export type WorkflowObjectPosition = {
	x: number;
	y: number;
};

export type WorkflowReplacementObject = {
	type: WorkflowObjectType;
	name: string;
	color?: string;
	description?: string;
	numberOfBranches?: number;
};

export enum WorkflowObjectType {
	START = 1,
	STEP = 2,
	PLUS = 3,
	BRANCH = 4,
	ROOT = 5,
	END = 6,
	HALT = 7,
	ENTRY = 8,
	EXIT = 9,
}

export enum WorkflowElementMode {
	Drag = 'drag',
	Button = 'button',
}

export enum events {
	drag = 'drag',
	dragend = 'dragend',
	dragenter = 'dragenter',
	dragleave = 'dragleave',
	dragstart = 'dragstart',
	dragover = 'dragover',
	drop = 'drop',
}

export enum dropEffects {
	copy = 'copy',
	move = 'move',
	link = 'link',
	none = 'none',
}

export enum effectsAllowed {
	none = 'none',
	copy = 'copy',
	copyLink = 'copyLink',
	copyMove = 'copyMove',
	link = 'link',
	linkMove = 'linkMove',
	move = 'move',
	all = 'all',
	uninitialized = 'uninitialized',
}

export const WorkflowElementCategory = {
	//SIMPLE: 1,
	COMPLEX: 2,
	FORM: 3,
	WORKFLOW: 4,
	ACTIVITY: 5,
	FUNCTION: 6,
	INTEGRATION: 7,
	CONTROL: 8,
};
export const WorkflowElementCategoryNames = {
	[WorkflowElementCategory.CONTROL]: 'Control elements',
	[WorkflowElementCategory.INTEGRATION]: 'Integrations',
	[WorkflowElementCategory.ACTIVITY]: 'Activity elements',
	[WorkflowElementCategory.FUNCTION]: 'Function elements',
	[WorkflowElementCategory.WORKFLOW]: 'Workflow elements',
	[WorkflowElementCategory.FORM]: 'Forms elements',
	[WorkflowElementCategory.COMPLEX]: 'Complex elements',
	//[WorkflowElementCategory.SIMPLE]: 'Simple elements',
};

export enum WorkflowDisplayMode {
	Builder = 'builder',
	Display = 'display',
}

export type WorkflowSchema = {
	id: string;
	type: WorkflowObjectType;
	position: WorkflowObjectPosition;
	children?: WorkflowSchema[];
	template?: WorkblockTemplate;
	branch?: WorkflowElementBranch;
	workblock?: WorkflowElementActions;
	length?: number;
};

export type WorkflowElementActions = {
	pre_actions: WorkflowElementAction[];
	actions: WorkflowElementAction[];
	post_actions: WorkflowElementAction[];
};

export type WorkflowElementBranch = {
	index: number;
	catch_all?: boolean;
	selector?: string;
	value_variable?: string;
	value_static?: string;
	value_expression?: string;
	notes?: string;
};

export type WorkflowElementAction = {
	id: string;
	actionId: string | undefined;
	parameters: UnstructuredFormOutput;
};

export type WorkflowForms = {
	[key: string]: {
		id: string;
		actionId: string;
		parameters: {
			[key: string]: Record<string, any>;
		};
	};
};

export type WorkflowTriggers = {
	[key: string]: {
		id: string;
		actionId: string;
		parameters: {
			[key: string]: Record<string, any>;
		};
	};
};

export type WorkflowExit = {
	[key: string]: {
		label: string;
		color: string;
	};
};

export type WorkflowElementTrigger = {
	trigger_id?: string;
	parameters?: UnstructuredFormOutput;
};

export const WorkflowElementStatus = {
	INACTIVE: 1,
	TESTING: 2,
	BETA: 3,
	ACTIVE: 4,
	DEPRECATED: 5,
	DELETED: 99,
};

export type WorkflowElementSettings = {
	icon?: WorkflowElementIcon;
	color?: string;
	detail?: string;
};

export type WorkflowElementIcon = {
	icon?: string | IconDefinition;
	svg?: string;
	iconName?: string;
	iconClass?: string;
	iconStyle?: string;
	backgroundClass?: string;
	backgroundStyle?: string;
	separator?: boolean;
};

export type CreateWorkflowType = {
	name?: string;
	reference_name?: string;
	description?: string;
};

export type WorkflowSettings = {};

export type WorkblockTemplateSettings = {
	color: string;
	icon: WorkflowElementIcon;
};

export const WorkflowStatus = {
	DRAFT: 1,
	PUBLISHED: 2,
	ARCHIVED: 3,
	DELETED: 99,
};

export const WorkflowStatusLabel = {
	[WorkflowStatus.DRAFT]: 'Draft',
	[WorkflowStatus.PUBLISHED]: 'Published',
	[WorkflowStatus.ARCHIVED]: 'Archived',
	[WorkflowStatus.DELETED]: 'Deleted',
};

export type WorkflowVariable = {
	id: string;
	name: string;
	description?: string | undefined;
	type: 'string' | 'number' | 'date' | 'datetime' | 'time' | 'json' | 'boolean' | 'list' | 'custom' | undefined;
	children: WorkflowVariables;
	attributes: WorkflowVariableAttributes;
	initial_value?: unknown | undefined;
};

export type WorkflowVariables = {
	[key: string]: WorkflowVariable;
};

export type WorkflowVariableAttributes = {
	description: string | undefined;
	group: boolean;
	list: boolean;
	hidden: boolean;
	read_only: boolean;
	tracked: boolean;
};
