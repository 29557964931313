export const ucword = (str: string): string => {
	if (str && str.length > 0) {
		return str[0].toUpperCase() + str.substring(1).toLowerCase();
	}
	return str;
};

export const getInitials = (firstName: string, lastName: string): string => {
	return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
};

export function safeUnpack<T>(stringToDecode: string | undefined, defaultResult: T) {
	if (stringToDecode) {
		if (typeof JSON.parse(stringToDecode) === 'string') {
			return JSON.parse(JSON.parse(stringToDecode)) satisfies T;
		}
		return JSON.parse(stringToDecode) satisfies T;
	}

	return defaultResult satisfies T;
}
