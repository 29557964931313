<template>
	<fieldset>
		<div class="space-y-4">
			<label
				v-for="option in availableOptions"
				:key="option.value"
				class="relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between focus-within:border-primary-600 focus-within:ring-2 focus-within:ring-primary-600"
				:class="{
					'border-gray-300': currentValue !== option.value,
					'border-transparent': currentValue == option.value,
					'opacity-25': option.disabled,
					'cursor-not-allowed': option.disabled,
					'cursor-pointer': !option.disabled,
				}"
				@click="currentValue = option.value"
			>
				<input :id="randomKey + '_' + context.node.name + '_' + option.value" :name="randomKey + '_' + context.node.name" type="radio" :value="option.value" class="sr-only peer" :checked="currentValue == option.value" />
				<span class="flex items-center select-none">
					<span class="flex flex-col text-sm">
						<span class="font-medium text-gray-900">{{ option.label }}</span>
						<span class="text-gray-500">
							<span class="block sm:inline">{{ option.description }}</span>
						</span>
					</span>
				</span>
				<span class="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right select-none">
					<span class="font-medium text-gray-900">{{ option.extra }}</span>
				</span>
				<span class="pointer-events-none absolute -inset-px rounded-lg border-2 peer-focus:border border-transparent peer-checked:border-primary-600 select-none" aria-hidden="true"></span>
			</label>
		</div>
	</fieldset>
</template>

<script setup lang="ts">
	import { reactive, ref, watch } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';
	import { CardOption } from '@/types/form';
	import { nanoid } from 'nanoid';

	interface Props {
		context: FormKitFrameworkContext;
	}
	const randomKey = nanoid(8);
	const props = defineProps<Props>();
	const currentValue = ref(props.context._value);
	const availableOptions = reactive<CardOption[]>((props.context.cardOptions as CardOption[]) ?? []);

	watch(currentValue, (newValue) => {
		props.context.node.input(newValue);
	});
</script>
