import { klona } from 'klona';

export default function clone<F>(objectToClone: F): F {
	/*if (typeof window.structuredClone != 'undefined') {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return structuredClone(objectToClone);
	} else {

	}*/
	// Disabled as seems to have wierd side effects :S
	//return JSON.parse(JSON.stringify(objectToClone)); // testing klona

	return klona(objectToClone);
}
