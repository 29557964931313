import { RouteRecordRaw } from 'vue-router';

export const guestRoutes: RouteRecordRaw[] = [];

export const authRoutes: RouteRecordRaw[] = [
	{
		path: '/:activity_type(ticket|task|project|jobs|activity|work_order)',
		name: 'activity.index',
		component: () => import('./layouts/index.vue'),
	},
	{
		path: '/:activity_type(ticket|task|project|jobs|activity|work_order)/create',
		name: 'activity.create',
		component: () => import('./layouts/index.vue'),
	},
	{
		path: '/:activity_type(ticket|task|project|job|activity|work_order)/:activity_id',
		component: () => import('./layouts/show.vue'),
		children: [
			{
				path: '',
				name: 'activity.details',
				component: () => import('./pages/ActivityDetails.vue'),
				meta: {
					title: 'Details',
				},
			},
			{
				path: 'interactions',
				name: 'activity.interactions',
				component: () => import('./pages/ActivityInteractions.vue'),
				meta: {
					title: 'Interactions',
				},
			},
			{
				path: 'workflows',
				name: 'activity.workflow',
				component: () => import('./pages/ActivityWorkflows.vue'),
				meta: {
					title: 'Workflows',
				},
			},
			{
				path: 'forms',
				name: 'activity.form',
				component: () => import('./pages/ActivityForms.vue'),
				meta: {
					title: 'Forms',
				},
				children: [
					{
						path: ':form_request_id',
						name: 'activity.form.show',
						component: () => import('./partials/FormRequestWrapper.vue'),
					},
				],
			},
			{
				path: 'log',
				name: 'activity.log',
				component: () => import('./pages/ActivityLog.vue'),
				meta: {
					title: 'Log',
				},
			},
			{
				path: 'variables',
				name: 'activity.variables',
				component: () => import('./pages/ActivityVariables.vue'),
				meta: {
					title: 'Variables',
				},
			},
			{
				path: 'related',
				name: 'activity.related',
				component: () => import('./pages/ActivityRelated.vue'),
				meta: {
					title: 'Related',
				},
			},
		],
	},
];

export const adminRoutes: RouteRecordRaw[] = [];

export default [...guestRoutes, ...authRoutes, ...adminRoutes];
