<template>
	<vue-tel-input
		v-model="currentValue"
		:input-options="{
			name: context.node.name,
			id: context.id,
			styleClasses: 'w-full py-1.5 px-2 text-base text-gray-700 placeholder-gray-400 focus-within:border-primary-500 focus-visible:outline-none',
		}"
		:dropdown-options="{ showDialCodeInSelection: true, showFlags: true }"
		style-classes="border border-gray-300 formkit-invalid:border-red-500 shadow-sm focus-within:border-primary-500 bg-white bg-gradient-to-b from-transparent to-gray-200 mb-1"
		:disabled="context.disabled"
		default-country="AU"
		:preferred-countries="['AU']"
		mode="international"
		:valid-characters-only="true"
		invalid-msg="This phone number is not valid!"
		@blur="context.handlers.blur"
		@input="context.handlers.DOMInput"
	/>
</template>

<script setup lang="ts">
	import { defineProps, ref } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';
	import { VueTelInput } from 'vue-tel-input';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const props = defineProps<Props>();
	const currentValue = ref(props.context._value);
</script>
