import { FormKitNode } from '@formkit/core';

export function safeAlphaNumeric({ value }: FormKitNode) {
	return !!String(value).match('^([a-zA-Z-_0-9]+)$');
}

export const safeAlphaNumericMessage = ({ name }: FormKitNode) => {
	return name + ' can only include alphanumeric, hyphens, and underscores.';
};

export function safeVariableString({ value }: FormKitNode) {
	return !!String(value).match('^([a-zA-Z0-9_-]+|\\#\\[([a-zA-Z0-9_-]+(?:\\.[a-zA-Z0-9_-]+)*)\\])(?:\\.(?:[a-zA-Z0-9_-]+|\\#\\[([a-zA-Z0-9_-]+(?:\\.[a-zA-Z0-9_-]+)*)\\]))*$');
}

export const safeVariableStringMessage = ({ name }: FormKitNode) => {
	return name + ' must be a valid variable address. (i.e. forms.#[variable].index)';
};
