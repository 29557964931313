import { acceptHMRUpdate, defineStore } from 'pinia';
import { AuthRequires2FAResponse, AuthRequiresAuthorizationResponse, AuthSuccessResponse, AuthTokenRefreshResponse } from '@/types/auth';
import { AuthConfig } from '@/config/auth';
import { useUserStore } from '@modules/user/store';
import { clearApolloStore } from '@/services/useApollo';
import { APIResponse } from '@/types/api';
import { getAuthHeaders } from '@utils/useAuth';

type TwoFactorRequest = {
	token: string;
	organisation_id: string;
	email: string;
	password: string;
};

export const useAuthStore = defineStore({
	id: 'Auth',
	state: () => ({
		/** @type { string | boolean } */
		authEmail: localStorage.getItem('email') || false,
		/** @type { number | boolean } */
		authUserId: parseInt(localStorage.getItem('user_id') ?? '') || false,
		/** @type { string | boolean } */
		authOrganisationId: localStorage.getItem('organisation_id') || false,
		/** @type { string | boolean } */
		token: localStorage.getItem('token') || false,
		/** @type { number | boolean } */
		tokenExpires: parseInt(localStorage.getItem('token_expires') ?? '') || false,
		/** @type { string | boolean } */
		refreshToken: localStorage.getItem('refresh_token') || false,
		/** @type { number | boolean } */
		refreshTokenExpires: parseInt(localStorage.getItem('refresh_token_expires') ?? '') || false,
		twoFactorRequest: <TwoFactorRequest>{},
	}),
	getters: {
		/**
		 * Returns the current authentication status, return true if the token is
		 * not empty and the expiry is less than the current time
		 *
		 * @returns {boolean}
		 */
		isAuthenticated: (state): boolean => {
			return state.token !== false && typeof state.tokenExpires === 'number' && state.tokenExpires > Math.round(Date.now() / 1000);
		},
		/**
		 * Return the user id of the currently authenticated user
		 * @returns {number|boolean}
		 */
		getAuthUserID: (state): number | boolean => {
			return state.authUserId;
		},
		/**
		 * Returns authenticated users email/email (depends on what they used to log in with)
		 * @returns {string|boolean}
		 */
		getAuthEmail: (state): string | boolean => {
			return state.authEmail;
		},
		/**
		 * Return the current authentication token
		 * @returns {string|boolean}
		 */
		getAuthToken: (state): string | boolean => {
			return state.token;
		},
		/**
		 * Returns a timestamp for when the current user's authentication token expires
		 * @returns {number|boolean}
		 */
		getAuthTokenExpiry: (state): number | boolean => {
			return state.tokenExpires;
		},
		/**
		 * Returns the current authenticated users refresh token
		 * @returns {string|boolean}
		 */
		getRefreshToken: (state): string | boolean => {
			return state.refreshToken;
		},
		/**
		 * Returns a timestamp for when the current user's refresh token expires
		 * @returns {number|boolean}
		 */
		getRefreshTokenExpiry: (state): number | boolean => {
			return state.refreshTokenExpires;
		},
		/**
		 * Returns a timestamp for when the current user's refresh token expires
		 * @returns {string|boolean}
		 */
		getAuthOrganisationId: (state): string | boolean => {
			return state.authOrganisationId;
		},
	},
	actions: {
		async login(email: string, password: string) {
			if (email == '' || password == '') {
				throw 'You need to enter your email and password first!';
			}
			return await fetch(AuthConfig.login_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email,
					password,
				}),
			})
				.then(async (response: Response) => {
					const responseStatus = response.status;
					const decodedResponse = await response.json();
					if (responseStatus === 401) {
						throw Error(decodedResponse.message);
					} else {
						if (decodedResponse.data) {
							if ((decodedResponse.data as AuthRequires2FAResponse).requires_two_factor && (decodedResponse.data as AuthRequires2FAResponse).request_token && (decodedResponse.data as AuthRequires2FAResponse).organisation_id) {
								// handle 2 factor!
								decodedResponse.data = decodedResponse.data as AuthRequires2FAResponse;

								this.twoFactorRequest = {
									token: decodedResponse.data.request_token,
									organisation_id: decodedResponse.data.organisation_id,
									email: email,
									password: password,
								};

								return decodedResponse.data;
							} else if ((decodedResponse.data as AuthRequiresAuthorizationResponse).requires_device_authorization) {
								//handle device authorization needed
								return decodedResponse.data as AuthRequiresAuthorizationResponse;
							} else {
								// noinspection DuplicatedCode
								if (
									(decodedResponse.data as AuthSuccessResponse).user_id &&
									(decodedResponse.data as AuthSuccessResponse).organisation_id &&
									(decodedResponse.data as AuthSuccessResponse).access_token &&
									(decodedResponse.data as AuthSuccessResponse).access_token_expires &&
									(decodedResponse.data as AuthSuccessResponse).refresh_token &&
									(decodedResponse.data as AuthSuccessResponse).refresh_token_expires
								) {
									//handle successful login
									decodedResponse.data = decodedResponse.data as AuthSuccessResponse;

									this.persistIdentity(
										this.twoFactorRequest.email,
										decodedResponse.data.user_id,
										decodedResponse.data.organisation_id,
										decodedResponse.data.access_token,
										decodedResponse.data.access_token_expires,
										decodedResponse.data.refresh_token,
										decodedResponse.data.refresh_token_expires,
									);

									return decodedResponse;
								}
							}
						}
						return false;
					}
				})
				.catch((error) => {
					throw error;
				});
		},

		async challenge(otp: string) {
			if (otp == '') {
				throw 'You need to enter your one time passcode first!';
			}
			return await fetch(AuthConfig.challenge_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-Organisation': this.twoFactorRequest.organisation_id,
				},
				body: JSON.stringify({
					passcode: otp,
					request_token: this.twoFactorRequest.token,
					email: this.twoFactorRequest.email,
					password: this.twoFactorRequest.password,
				}),
			})
				.then(async (response: Response) => {
					const responseStatus = response.status;
					const decodedResponse = await response.json();
					if (responseStatus === 401) {
						throw Error(decodedResponse.message);
					} else {
						if (decodedResponse.data) {
							// noinspection DuplicatedCode
							if (
								(decodedResponse.data as AuthSuccessResponse).user_id &&
								(decodedResponse.data as AuthSuccessResponse).organisation_id &&
								(decodedResponse.data as AuthSuccessResponse).access_token &&
								(decodedResponse.data as AuthSuccessResponse).access_token_expires &&
								(decodedResponse.data as AuthSuccessResponse).refresh_token &&
								(decodedResponse.data as AuthSuccessResponse).refresh_token_expires
							) {
								//handle successful login
								decodedResponse.data = decodedResponse.data as AuthSuccessResponse;

								this.persistIdentity(
									this.twoFactorRequest.email,
									decodedResponse.data.user_id,
									decodedResponse.data.organisation_id,
									decodedResponse.data.access_token,
									decodedResponse.data.access_token_expires,
									decodedResponse.data.refresh_token,
									decodedResponse.data.refresh_token_expires,
								);

								return decodedResponse;
							}
						}
						return false;
					}
				})
				.catch((error) => {
					throw error;
				});
		},
		persistIdentity(email: string, userId: number, organisationId: string, accessToken: string, accessTokenExpiry: number, refreshToken: string, refreshTokenExpiry: number) {
			this.$patch({
				authEmail: email,
				authUserId: userId,
				authOrganisationId: organisationId,
			});

			localStorage.setItem('email', email);
			localStorage.setItem('user_id', userId.toString());
			localStorage.setItem('organisation_id', organisationId.toString());

			this.saveAccessToken(accessToken, accessTokenExpiry);
			this.saveRefreshToken(refreshToken, refreshTokenExpiry);
		},
		async logout() {
			await fetch(AuthConfig.logout_url, {
				method: 'GET',
				headers: Object.assign({}, getAuthHeaders(), {
					'Content-Type': 'application/json',
				}) as HeadersInit,
			}).then(() => {
				this.clearAuthentication();
				this.router.push({
					name: 'login',
				});
			});
		},

		async status() {
			return await fetch(AuthConfig.status_url, {
				method: 'GET',
				headers: Object.assign({}, getAuthHeaders(), {
					'Content-Type': 'application/json',
				}) as HeadersInit,
			})
				.then(async (response) => {
					const jsonResponse = await response.json();
					if (jsonResponse.message === 'We are unable to verify organisation access.') {
						return false;
					}
					return !(jsonResponse.errors && jsonResponse.errors[0] && jsonResponse.errors[0].message === 'Not Authenticated');
				})
				.catch(() => {
					return false;
				});
		},

		async refresh() {
			await fetch(AuthConfig.refresh_url, {
				method: 'POST',
				headers: Object.assign({}, getAuthHeaders(), {
					'Content-Type': 'application/json',
				}) as HeadersInit,
				body: JSON.stringify({
					refresh_token: this.refreshToken,
				}),
			})
				.then((response) => {
					if (!response.ok) {
						throw Error('Sorry! There was an error authenticating you!');
					}
					return response.json();
				})
				.then((response: AuthTokenRefreshResponse) => {
					this.saveAccessToken(response.data.access_token, response.data.access_token_expires);
					this.saveRefreshToken(response.data.refresh_token, response.data.refresh_token_expires);
				})
				.catch(() => {
					throw Error('Sorry! There was an error authenticating you!');
				});
		},

		async forgotPassword(email: string) {
			return await fetch(AuthConfig.forgot_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: email,
				}),
			})
				.then(async (response) => {
					if (!response.ok) {
						const errorResponse = await response.json();
						throw Error(errorResponse?.errors?.error && errorResponse?.errors?.error?.length > 0 ? errorResponse?.errors?.error[0] : 'An unknown error occurred!');
					}
					return response.json();
				})
				.then((response: APIResponse) => {
					return response.message;
				});
		},
		async resetPassword(token: string, email: string, password: string, password_confirm: string) {
			return await fetch(AuthConfig.reset_url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token: token,
					email: email,
					password: password,
					password_confirm: password_confirm,
				}),
			})
				.then(async (response) => {
					if (!response.ok) {
						const errorResponse = await response.json();
						throw Error(errorResponse?.errors?.error && errorResponse?.errors?.error?.length > 0 ? errorResponse?.errors?.error[0] : 'An unknown error occurred!');
					}
					return response.json();
				})
				.then((response: APIResponse) => {
					return response.message;
				});
		},

		saveAccessToken(accessToken: string, accessTokenExpires: number) {
			this.$patch({
				token: accessToken,
				tokenExpires: Math.round(Date.now() / 1000) + accessTokenExpires,
			});
			localStorage.setItem('token', accessToken);
			localStorage.setItem('token_expires', (Math.round(Date.now() / 1000) + accessTokenExpires).toString());
		},

		saveRefreshToken(refreshToken: string, refreshTokenExpires: number) {
			this.$patch({
				refreshToken: refreshToken,
				refreshTokenExpires: Math.round(Date.now() / 1000) + refreshTokenExpires,
			});
			localStorage.setItem('refresh_token', refreshToken);
			localStorage.setItem('refresh_token_expires', (Math.round(Date.now() / 1000) + refreshTokenExpires).toString());
		},

		async clearAuthentication() {
			this.$patch({
				authEmail: false,
				authUserId: false,
				authOrganisationId: false,
				token: false,
				tokenExpires: false,
				refreshToken: false,
				refreshTokenExpires: false,
			});

			localStorage.removeItem('email');
			localStorage.removeItem('user_id');
			localStorage.removeItem('organisation_id');
			localStorage.removeItem('token');
			localStorage.removeItem('token_expires');
			localStorage.removeItem('refresh_token');
			localStorage.removeItem('refresh_token_expires');

			const userStore = useUserStore();
			await userStore.clearUser();

			await clearApolloStore();
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
