import { SweetAlertResult } from 'sweetalert2';

export type DialogIcon = {
	icon: AlertIcons;
	colour?: string;
};
export const enum AlertIcons {
	Success = 'success',
	Error = 'error',
	Warning = 'warning',
	Info = 'info',
	Question = 'question',
}

export const enum AlertPosition {
	Top = 'top',
	TopStart = 'top-start',
	TopEnd = 'top-end',
	Center = 'center',
	CenterStart = 'center-start',
	CenterEnd = 'center-end',
	Bottom = 'bottom',
	BottomStart = 'bottom-start',
	BottomEnd = 'bottom-end',
}

export type DialogResult = SweetAlertResult;
