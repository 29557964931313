<template>
	<div :class="props.context.classes.outer + ' w-full grid grid-cols-12 items-end'">
		<div class="col-span-12">
			<FormKit v-model="currentValue" :ignore="true" type="color" :delay="200" :label="props.context.label" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, ref, watch } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';
	import { useFormStore } from '@modules/form/store';
	import { getValueWithKeyFromStyleObject } from '@modules/form/utils/helpers';

	interface Props {
		context: FormKitFrameworkContext;
	}

	type StyleObject = { [key: string]: string };

	const formStore = useFormStore();
	const props = defineProps<Props>();

	const currentValue = ref();
	const currentStyles = ref(props.context.value);
	currentValue.value = getValueWithKeyFromStyleObject(props.context.value, props.context.styleAttribute) ?? 'auto';

	watch(currentValue, (newTextColor) => {
		updateTextColour(newTextColor);
	});

	const directReturn = () => {
		return props.context.directReturn ?? false;
	};

	// Have to update the central schema because reactivity is being a complete B!^*%(^
	const updateTextColour = (selectedTextColour: string) => {
		const classes: StyleObject = {};
		let found = false;
		if (directReturn()) {
			if (currentStyles.value) {
				for (const styleOption of Object.keys(currentStyles.value)) {
					if (styleOption == props.context.styleAttribute) {
						classes[props.context.styleAttribute] = selectedTextColour;
						found = true;
					} else {
						classes[styleOption] = currentStyles.value[styleOption];
					}
				}
			}
			if (!found) {
				classes[props.context.styleAttribute] = selectedTextColour;
			}
			props.context.node.input(classes);
			currentStyles.value = classes;
		} else {
			const editingElementValue = formStore.getEditingElement().attrs;

			if (editingElementValue[props.context.node.name]) {
				for (const styleOption of Object.keys(editingElementValue[props.context.node.name])) {
					if (styleOption == props.context.styleAttribute) {
						classes[props.context.styleAttribute] = selectedTextColour;
						found = true;
					} else {
						classes[styleOption] = editingElementValue[props.context.node.name][styleOption];
					}
				}
			}
			if (!found) {
				classes[props.context.styleAttribute] = selectedTextColour;
			}
			editingElementValue[props.context.node.name] = classes;
		}
	};
</script>
