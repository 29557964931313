import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { SentryConfig } from '@/config/sentry';
import { useAuthStore } from '@modules/auth/store';
import { useUserStore } from '@modules/user/store';

export async function initializeSentry(app: App, router: Router) {
	if (SentryConfig.enable) {
		Sentry.init({
			app,
			dsn: SentryConfig.dsn,
			beforeSend(event, hint) {
				if (SentryConfig.captureCrashReports) {
					// Check if it is an exception, and if so, show the report dialog
					if (event.exception && event.event_id) {
						const authStore = useAuthStore();
						const userStore = useUserStore();

						Sentry.showReportDialog({
							dsn: SentryConfig.dsn,
							eventId: event.event_id,
							user: { email: (!authStore.getAuthEmail ? '' : authStore.getAuthEmail).toString(), name: userStore.getUserFullName },
						});
					}
				}
				return event;
			},
			logErrors: true,
			release: SentryConfig.release,
			environment: SentryConfig.environment,
			integrations: [
				Sentry.browserTracingIntegration({
					// pass router in, if applicable
					router,
				}),
				Sentry.replayIntegration({
					// Additional SDK configuration goes in here, for example:
					maskAllText: false,
					maskAllInputs: false,
					blockAllMedia: true,
					mask: ["input[type='password']"],
				}),
			],
			// Performance Monitoring
			tracesSampleRate: SentryConfig.performanceSampleRate, // Capture 100% of the transactions, reduce in production!
			// Session Replay
			replaysSessionSampleRate: SentryConfig.sessionReplaySampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: SentryConfig.sessionReplayOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/api\.conduction\.app/, /^https:\/\/api\.conduction\.test/, /^\//],
		});
	}
}

export async function initializeSentryUserContext({ user }: { user: { id: string; email: string; name: string } }) {
	if (SentryConfig.enable) {
		Sentry.setUser(user);
	}
}
