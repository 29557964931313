<template>
	<div class="col-span-12 w-full grid grid-cols-12 flex items-end">
		<section v-if="inputType === 'dropdown'" class="col-span-12 grid grid-cols-12">
			<h2 class="col-span-12 mb-3">Select option list type</h2>
			<div class="col-span-6">
				<FormKit
					v-model="selectOptionListType"
					:ignore="true"
					type="dropdown"
					label="Option list type"
					placeholder="---Please Select---"
					validation="required"
					validation-visibility="live"
					:options="[
						{
							label: 'Custom list',
							value: 'custom',
						},
						{
							label: 'Shared list',
							value: 'shared',
						},
						{
							label: 'Dynamic list',
							value: 'dynamic',
						},
					]"
				/>
			</div>

			<div class="col-span-6 ml-2">
				<FormKit
					v-if="selectOptionListType === 'dynamic'"
					v-model="dynamicListType"
					:ignore="true"
					type="dropdown"
					label="Dynamic list type"
					placeholder="---Please Select---"
					validation="required"
					validation-visibility="live"
					:options="[
						{
							label: 'List of active forms',
							value: 'active_forms',
						},
						{
							label: 'List of active workflows',
							value: 'active_workflows',
						},
						{
							label: 'List of active templates',
							value: 'active_templates',
						},
						{
							label: 'List of active activities',
							value: 'active_activities',
						},
						{
							label: 'List of active workblocks',
							value: 'active_workblocks',
						},
						{
							label: 'List of current workflow forms',
							value: 'active_workflow_forms',
						},
						{
							label: 'List of current workflow triggers',
							value: 'active_workflow_triggers',
						},
						{
							label: 'Empty list (for testing)',
							value: 'empty_list',
						},
					]"
				/>
				<!--				<div v-else-if="selectOptionListType === 'custom'">
					<FormKit
						id="customListType"
						v-model="customListType"
						label="Custom List type"
						alt-label-position
						on-value-label="Simple"
						off-value-label="Complex"
						type="toggle"
						:classes="{ outer: 'w-full' }"
						:ignore="true"
						name="newOptionLabel"
						required
						help="Wether the list will be a complex or simple list"
					/>
				</div>-->
			</div>
		</section>
		<section v-if="selectOptionListType === 'custom' || inputType !== 'dropdown'" class="col-span-12">
			<h2 class="col-span-12 mb-3">Add new option</h2>
			<div class="col-span-12">
				<div class="flex flex-row justify-between">
					<div class="flex flex-col w-full justify-between mr-2">
						<FormKit
							v-if="customListType == false"
							id="newOptionLabel"
							type="text"
							:value="newOptionLabel"
							:classes="{ outer: 'w-full' }"
							:ignore="true"
							label="Label"
							name="newOptionLabel"
							required
							placeholder="The label to be used for this option"
							@input="(value) => (newOptionLabel = value)"
						/>
						<FormKit
							v-if="inputValueType !== 'toggle'"
							id="newOptionValue"
							:type="inputValueType"
							:value="newOptionValue"
							:classes="{ outer: 'w-full' }"
							:ignore="true"
							label="Value"
							name="newOptionValue"
							required
							placeholder="The value to be used for this option"
							@input="(value) => (newOptionValue = value)"
						/>
						<FormKit
							v-else
							id="newOptionValue"
							type="toggle"
							:value="newOptionValue"
							label="Value"
							alt-label-position
							on-value-label="True"
							off-value-label="False"
							:classes="{ outer: 'w-full' }"
							:ignore="true"
							name="newOptionValue"
							required
							help="The value to be used for this option"
							@input="(value) => (newOptionValue = value)"
						/>
						<div v-if="error">
							<span class="text-danger-500 text-sm ml-1">{{ error }}</span>
						</div>
					</div>
					<div class="flex items-center justify-center mb-0.5 mt-5 min-w-[40px]">
						<button type="button" class="flex px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 justify-center items-center" @click="addOptionToElement">
							<FontAwesomeIcon :icon="faPlus" aria-hidden="true" class="w-4 h-4" />
						</button>
					</div>
				</div>
			</div>
			<div class="col-span-12 my-3 -mx-2">
				<hr />
			</div>
			<h2 class="col-span-12 mb-3">Current options</h2>
			<div v-if="props.context.value?.length > 0" class="col-span-12 flex flex-col overflow-x-auto">
				<div class="min-w-full py-2 align-middle px-px">
					<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
						<table class="min-w-full divide-y divide-gray-300">
							<thead class="bg-gray-50">
								<tr>
									<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Option</th>
									<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
										<span class="sr-only">Remove</span>
									</th>
								</tr>
							</thead>
							<Draggable tag="tbody" class="divide-y divide-gray-200 bg-white" :list="props.context.value" item-key="value" @start="dragging = true" @end="dragging = false">
								<template #item="{ element, index }">
									<tr class="w-full">
										<td class="pl-3 pr-2 w-full whitespace-nowrap py-2 text-sm font-medium text-gray-900 flex flex-col justify-center">
											<template v-if="customListType == false">
												<div class="flex flex-row items-center">
													<span class="font-semibold">Label : </span>
													<span class="ml-2 font-normal whitespace-pre-wrap"
														><span v-if="element?.label === null" class="font-light">
															<em>No label</em>
														</span>
														<span v-else>
															{{ element.label }}
														</span></span
													>
												</div>
												<div class="flex flex-row items-center">
													<span class="font-semibold">Value : </span>
													<span class="ml-2 font-normal whitespace-pre-wrap">
														<span v-if="element.__original === null" class="font-light">
															<em>No value</em>
														</span>
														<span v-else>
															{{ element.value }}
														</span>
													</span>
												</div>
											</template>
											<template v-else>
												<div class="flex flex-row items-center py-0.5">
													<span class="font-semibold">Value : </span>
													<span class="ml-2 font-normal whitespace-pre-wrap">
														<span v-if="element?.__original === null" class="font-light">
															<em>No value</em>
														</span>
														<span v-else>
															{{ element }}
														</span>
													</span>
												</div>
											</template>
										</td>
										<td class="pr-3 py-1.5 w-px whitespace-nowrap text-right text-sm font-medium min-w-[40px]">
											<button
												type="button"
												class="flex justify-center items-center px-1.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none"
												@click="removeElementOption(index)"
											>
												<FontAwesomeIcon :icon="faTrash" aria-hidden="true" class="w-4 h-4" />
											</button>
										</td>
									</tr>
								</template>
							</Draggable>
						</table>
					</div>
				</div>
			</div>
			<div v-else class="col-span-12 flex justify-center text-sm">This element current has no options.</div>
		</section>
		<DebugSection>
			{{ elementOptions }}
		</DebugSection>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, reactive, ref, watch } from 'vue';
	import { FormKitFrameworkContext, getNode } from '@formkit/core';
	import { useFormStore } from '@modules/form/store';
	import Draggable from 'vuedraggable';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPlus } from '@fortawesome/pro-solid-svg-icons';
	import { faTrash } from '@fortawesome/pro-regular-svg-icons';
	import DebugSection from '@components/DebugSection.vue';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const dragging = ref(false);

	const formStore = useFormStore();

	const props = defineProps<Props>();
	const elementOptions = reactive(props.context._value ?? []);
	const inputValueType = ref((props?.context?.inputType || formStore.editingElement?.inputType) ?? 'text');
	const selectOptionListType = ref((formStore.editingElement?.meta && formStore.editingElement?.meta?.listType) ?? 'custom');
	const dynamicListType = ref((formStore.editingElement?.meta && formStore.editingElement?.meta?.dynamicList) ?? null);
	const sharedListType = ref((formStore.editingElement?.meta && formStore.editingElement?.meta?.sharedList) ?? null);
	const customListType = ref((props?.context?.attrs?.meta?.customListType || formStore.editingElement?.meta?.customListType) ?? false);
	const inputType = ref(formStore.editingElement?.$formkit);
	const error = ref();

	watch(selectOptionListType, () => {
		saveListSettingsToElement();
	});
	watch(dynamicListType, () => {
		saveListSettingsToElement();
	});
	watch(sharedListType, () => {
		saveListSettingsToElement();
	});
	watch(customListType, () => {
		saveListSettingsToElement();
	});

	watch(
		() => formStore.editingElement?.inputType,
		(newValue) => {
			inputValueType.value = String(newValue);
		},
		{ deep: true },
	);

	watch(
		() => props?.context?.inputType,
		(newValue) => {
			inputValueType.value = String(newValue);
		},
		{ deep: true },
	);

	watch(elementOptions, (newElementOptions) => {
		if (newElementOptions) {
			props.context.node.input(newElementOptions);
		}
	});

	const newOptionValue = ref();
	const newOptionLabel = ref();
	const addOptionToElement = () => {
		error.value = '';

		if (customListType.value == true && (newOptionValue.value === null || newOptionValue.value === undefined)) {
			if (inputValueType.value == 'toggle') {
				newOptionValue.value = false;
			} else {
				newOptionValue.value = '';
			}
		}

		if (elementOptions.find((o) => o.value === newOptionValue.value) || elementOptions.find((o) => o.__original === newOptionValue.value) || (customListType.value == true && elementOptions.includes(newOptionValue.value))) {
			error.value = 'Values must be unique';
			return false;
		} else if (customListType.value == false && elementOptions.find((o) => o.label === newOptionLabel.value)) {
			error.value = 'Labels must be unique';
			return false;
		}

		if (customListType.value == false) {
			const newOption = {
				label: newOptionLabel.value ?? null,
				value: newOptionValue.value ?? null,
			};
			elementOptions.push(newOption);
		} else {
			elementOptions.push(newOptionValue.value);
		}
		getNode('newOptionLabel')?.input(null, true);
		getNode('newOptionValue')?.input(null, true);
	};

	const removeElementOption = (optionToRemoveIndex: number) => {
		elementOptions.splice(optionToRemoveIndex, 1);
	};

	function saveListSettingsToElement() {
		const rootElement = formStore.editingElement;

		if (selectOptionListType.value == 'dynamic') {
			rootElement['options'] = [];
		} else if (selectOptionListType.value == 'shared') {
			rootElement['options'] = [];
		} else if (selectOptionListType.value == 'custom') {
			rootElement['options'] = elementOptions.value ?? [];
		}

		if (!('meta' in rootElement) || Array.isArray(rootElement['meta'])) {
			rootElement['meta'] = {};
		}

		if (selectOptionListType.value === 'custom') {
			delete rootElement['meta']['listType'];
			delete rootElement['meta']['sharedList'];
			delete rootElement['meta']['dynamicList'];
			delete rootElement['meta'];
			return;
		}

		rootElement['meta']['listType'] = selectOptionListType.value;
		if (sharedListType.value && !(typeof sharedListType.value === 'undefined')) {
			rootElement['meta']['sharedList'] = sharedListType.value;
		}
		if (dynamicListType.value && !(typeof dynamicListType.value === 'undefined')) {
			rootElement['meta']['dynamicList'] = dynamicListType.value;
		}
		if (selectOptionListType.value === 'shared') {
			delete rootElement['meta']['dynamicList'];
		}
		if (selectOptionListType.value === 'dynamic') {
			delete rootElement['meta']['sharedList'];
		}
	}
</script>
