import { defineStore } from 'pinia';
import { AppConfig } from '@/config/app';
import { useFormStore } from '@modules/form/store';
import { useWorkflowStore } from '@modules/workflow/store';
import { WindowBreakpoint } from '@/types/app';

export const useBaseStore = defineStore({
	id: 'Base',
	state: () => ({
		version: AppConfig.version,
		secondaryPanelOpen: false,
		secondaryPanelClosed: false,
		slideOverPanelOpen: false,
		slideOverPanelClosed: false,
		globalSearchOpen: false,
		globalAnnouncementsOpen: false,
		accountMenuOpen: false,
		windowHeight: -1,
		windowWidth: -1,
		loading: false,
		debugMode: true,
	}),
	getters: {
		currentBreakpoint: (state): WindowBreakpoint => {
			if (state.windowWidth < 640) {
				return WindowBreakpoint.Mobile;
			} else if (state.windowWidth < 1024) {
				return WindowBreakpoint.Tablet;
			} else if (state.windowWidth < 1280) {
				return WindowBreakpoint.Laptop;
			}
			return WindowBreakpoint.Desktop;
		},
		isDesktop: (state): boolean => {
			return state.windowWidth >= 1280;
		},
		isLaptop: (state): boolean => {
			return state.windowWidth >= 1024 && state.windowWidth < 1280;
		},
		isTablet: (state): boolean => {
			return state.windowWidth >= 640 && state.windowWidth < 1024;
		},
		isMobile: (state): boolean => {
			return state.windowWidth < 640;
		},
		supportsDesktop: (state): boolean => {
			return state.windowWidth >= 1280;
		},
		supportsLaptop: (state): boolean => {
			return state.windowWidth >= 1024;
		},
		supportsTablet: (state): boolean => {
			return state.windowWidth >= 640;
		},
		supportsMobile: (state): boolean => {
			return state.windowWidth > 0;
		},
	},
	actions: {
		async setLoadingStart() {
			this.$patch({
				loading: true,
			});
		},

		async setLoadingFinished() {
			this.$patch({
				loading: false,
			});
		},

		async secondaryPanelHasClosed() {
			this.$patch({
				secondaryPanelClosed: true,
			});

			const formStore = useFormStore();
			await formStore.triggerSidePanelHasClosed();
			const workflowStore = useWorkflowStore();
			await workflowStore.triggerSidePanelHasClosed();
		},

		async secondaryPanelHasOpened() {
			this.$patch({
				secondaryPanelClosed: false,
			});
		},
	},
});
