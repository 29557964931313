import gql from 'graphql-tag';

export const GET_TEMPLATES = gql`
	query GetActivityTemplates($first: Int!, $page: Int) {
		getActivityTemplates(first: $first, page: $page) {
			data {
				id
				name
				description
				status
				type
				archiver {
					first_name
					middle_name
					last_name
					id
				}
				publisher {
					first_name
					middle_name
					last_name
					id
				}
				creator {
					first_name
					middle_name
					last_name
					id
				}
				updater {
					first_name
					middle_name
					last_name
					id
				}
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_ACTIVE_TEMPLATES_DROPDOWN = gql`
	query GetActivityTemplates($first: Int!, $page: Int) {
		getActivityTemplates(first: $first, page: $page) {
			data {
				id
				name
				description
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_TEMPLATE_BY_ID = gql`
	query GetActivityTemplateById($template_id: ID) {
		getActivityTemplateById(id: $template_id) {
			id
			layout_id
			layout {
				id
				current_version {
					id
					schema
				}
				description
				name
				reference_name
				settings
				status_id
				type_id
			}
			initiator_id
			initiator {
				id
				current_version {
					id
					schema
				}
				description
				name
				reference_name
				settings
				status_id
				type_id
			}
			workflow_id
			publisher {
				last_name
				middle_name
				first_name
				id
			}
			archiver {
				last_name
				middle_name
				first_name
				id
			}
			creator {
				last_name
				middle_name
				first_name
				id
			}
			updater {
				last_name
				middle_name
				first_name
				id
			}
			description
			name
			status
			type
			settings
			variables
		}
	}
`;
