<template>
	<div class="col-span-12 w-full grid grid-cols-12 flex items-end">
		<h2 class="col-span-12 mb-3">Add new validation rule</h2>
		<div class="col-span-12 grid grid-cols-12">
			<div class="col-span-11 flex items-center justify-center">
				<div class="w-full grid grid-cols-12 flex items-end">
					<FormKit
						id="ruleToAdd"
						type="dropdown"
						:value="selectedRule"
						:classes="{
							outer: 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-12',
						}"
						:ignore="true"
						label="Rule to add"
						name="ruleToAdd"
						placeholder="Select a rule to add"
						:options="availableRulesForDropdown"
						@input="(value) => (selectedRule = value)"
					/>
				</div>
			</div>

			<div v-if="selectedRule && selectedRule?.arguments?.length === 0" class="col-span-1 flex items-center justify-center min-w-[40px] mt-5">
				<button
					type="button"
					class="flex justify-center items-center px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
					:disabled="!selectedRule"
					@click="addRuleToElement"
				>
					<FontAwesomeIcon :icon="faPlus" aria-hidden="true" class="w-4 h-4" />
				</button>
			</div>
		</div>
		<div v-if="selectedRule && selectedRule?.arguments?.length > 0" class="col-start-1 col-span-12 flex flex-row justify-between grid grid-cols-12">
			<div class="col-span-11 flex flex-col justify-left w-full">
				<h2 class="font-semibold mt-3">Arguments</h2>
				<div class="w-full rounded border b-2 flex flex-col justify-left items-center">
					<FormKit
						v-for="args in selectedRule?.arguments"
						:id="args.name"
						:key="args.id"
						:type="args.type ?? 'text'"
						:value="newValidateRuleArgs[args.id]"
						:classes="{ outer: 'w-full px-2 py-1' }"
						:ignore="true"
						:label="args.label"
						:name="args.name"
						:required="args.required ?? false"
						:placeholder="args.placeholder"
						@input="(value) => (newValidateRuleArgs[args.id] = value)"
					>
						<template #label="validationContext">
							{{ validationContext.label }}
							<span v-if="!!validationContext.attrs.required" class="text-danger-500">*</span>
						</template>
					</FormKit>
					<p class="flex justify-left font-light text-sm">(<span class="text-danger-500">*</span>) is a required field</p>
				</div>
			</div>
			<div class="col-span-1 flex items-center justify-center min-w-[40px] mt-4">
				<button
					type="button"
					class="flex px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 justify-center items-center"
					:disabled="!selectedRule"
					@click="addRuleToElement"
				>
					<FontAwesomeIcon :icon="faPlus" aria-hidden="true" class="w-4 h-4" />
				</button>
			</div>
		</div>
		<div class="col-span-12 my-3 -mx-2">
			<hr />
		</div>
		<h2 class="col-span-12 mb-3">Current validation rules</h2>
		<div v-if="validationRules?.length > 0" class="col-span-12 flex flex-col overflow-x-auto">
			<div class="min-w-full py-2 align-middle px-px">
				<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
					<table class="min-w-full divide-y divide-gray-300">
						<thead class="bg-gray-50">
							<tr>
								<th scope="col" class="py-2.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-2.5">Rule</th>
								<th scope="col" class="py-2.5 pl-4 pr-3 text-left text-sm font-bold text-gray-900 sm:pl-2.5"></th>
								<th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
									<span class="sr-only">Remove</span>
								</th>
							</tr>
						</thead>
						<tbody class="divide-y divide-gray-200 bg-white">
							<tr v-for="(rule, index) in validationRules" :key="index">
								<td class="flex flex-col pl-2 py-1 text-sm font-medium text-gray-900 w-22">
									<div class="flex flex-row items-center">
										<span class="font-semibold">Rule : </span>
									</div>
									<div v-if="getRuleArguments(rule[0]).length > 0" class="flex flex-row items-center">
										<span class="font-semibold">Parameters : </span>
									</div>
								</td>
								<td class="pr-2 py-1 text-sm font-medium text-gray-900 w-full">
									<div class="flex flex-row items-center">
										<span class="ml-2 font-normal whitespace-pre-wrap">{{ getRuleLabel(rule[0]) }}</span>
									</div>
									<div v-if="getRuleArguments(rule[0]).length > 0" class="flex flex-row items-center">
										<span class="ml-2 font-normal whitespace-pre-wrap">
											<span class="flex flex-col">
												<span v-for="(args, argIndex) in getRuleArguments(rule[0])" :key="argIndex">
													<span v-if="rule[argIndex + 1]">{{ args.label }}: {{ rule[argIndex + 1] }}</span>
												</span>
											</span>
										</span>
									</div>
								</td>
								<td class="w-px px-1 pr-3">
									<div class="flex flex-shrink justify-center items-center">
										<button
											type="button"
											class="flex justify-center items-center px-1 py-1 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none"
											@click="removeValidationRule(parseInt(String(index)))"
										>
											<FontAwesomeIcon :icon="faTrash" aria-hidden="true" class="w-4 h-4" />
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div v-else class="col-span-12 flex justify-center text-sm">This element current has no validation rules.</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, reactive, ref, watch } from 'vue';
	import { FormKitFrameworkContext, getNode } from '@formkit/core';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPlus } from '@fortawesome/pro-solid-svg-icons';
	import { faTrash } from '@fortawesome/pro-regular-svg-icons';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const newValidateRuleArgs = ref({});
	const props = defineProps<Props>();
	const validationRules = reactive(props.context._value ?? []);

	watch(validationRules, (newValue) => {
		props.context.node.input(newValue);
	});
	const selectedRule = ref();

	const addRuleToElement = () => {
		const orderedNewValidateRuleArgs = Object.keys(newValidateRuleArgs)
			.sort()
			.reduce((obj, key) => {
				obj[key] = newValidateRuleArgs[key];
				return obj;
			}, {});
		const newRule = [selectedRule.value.name];
		for (const ruleArg of Object.values(orderedNewValidateRuleArgs._rawValue)) {
			newRule.push(ruleArg);
		}
		validationRules.push(newRule);
		getNode('ruleToAdd')?.input(undefined, true);
		newValidateRuleArgs.value = {};
	};

	const getRuleLabel = (rule: string) => {
		for (const availableRule of availableValidationRules) {
			if (availableRule.name === rule) {
				return availableRule.label;
			}
		}
	};

	const getRuleArguments = (rule: string) => {
		for (const availableRule of availableValidationRules) {
			if (availableRule.name === rule) {
				return availableRule.arguments;
			}
		}
	};

	const removeValidationRule = (ruleToRemoveIndex: number) => {
		validationRules.splice(ruleToRemoveIndex, 1);
	};

	const availableRulesForDropdown = computed(() => {
		const rulesToShow = [];
		for (const rule of availableValidationRules) {
			rulesToShow.push({
				label: rule.label,
				value: rule,
			});
		}
		return rulesToShow;
	});

	type ValidationOption = {
		name: string;
		label: string;
		arguments: ValidationOptionArgument[];
	};

	type ValidationOptionArgument = {
		id: number;
		name: string;
		type: string;
		required: boolean;
		label: string;
		placeholder?: string;
	};

	const availableValidationRules: ValidationOption[] = [
		{
			name: 'accepted',
			label: 'Must be an "accepted" value (yes, on, 1 or true)',
			arguments: [],
		},
		{
			name: 'safeAlphaNumeric',
			label: 'Must only contain letters, numbers, or hyphens',
			arguments: [],
		},
		{
			name: 'alphanumeric',
			label: 'Must be letters and/or numbers',
			arguments: [],
		},
		{
			name: 'alpha_spaces',
			label: 'Must be letters and/or spaces',
			arguments: [],
		},
		{
			name: 'between',
			label: 'Must be a number between',
			arguments: [
				{
					id: 1,
					name: 'min',
					type: 'number',
					required: true,
					label: 'Lowest number',
					placeholder: 'Enter the lowest acceptable number',
				},
				{
					id: 2,
					name: 'max',
					type: 'number',
					required: true,
					label: 'Highest number',
					placeholder: 'Enter the highest acceptable number',
				},
			],
		},
		{
			name: 'confirm',
			label: 'Must match another input',
			arguments: [],
		},
		{
			name: 'date_after',
			label: 'Must be a date after',
			arguments: [],
		},
		{
			name: 'date_before',
			label: 'Must be a date before',
			arguments: [],
		},
		{
			name: 'date_between',
			label: 'Must be a date between',
			arguments: [],
		},
		{
			name: 'date_format',
			label: 'Must be a date in the format',
			arguments: [],
		},
		{
			name: 'email',
			label: 'Must be an email',
			arguments: [],
		},
		{
			name: 'ends_with',
			label: 'Must end with',
			arguments: [
				{
					id: 1,
					name: 'text',
					type: 'text',
					required: true,
					label: 'Text to search for',
				},
			],
		},
		{
			name: 'is',
			label: 'Must be matching',
			arguments: [
				{
					id: 1,
					name: 'values',
					type: 'text',
					required: true,
					label: 'Comma seperated values',
				},
			],
		},
		{
			name: 'length',
			label: 'Must be text of a certain length',
			arguments: [
				{
					id: 1,
					name: 'min',
					type: 'number',
					required: true,
					label: 'Min length of text',
				},
				{
					id: 2,
					name: 'max',
					type: 'number',
					required: true,
					label: 'Max length of text',
				},
			],
		},
		{
			name: 'match',
			label: 'Must match text or pattern',
			arguments: [],
		},
		{
			name: 'max',
			label: 'Must be a number below',
			arguments: [
				{
					id: 1,
					name: 'max',
					type: 'number',
					required: true,
					label: 'Max length of text',
				},
			],
		},
		{
			name: 'min',
			label: 'Must be a number above',
			arguments: [
				{
					id: 1,
					name: 'min',
					type: 'number',
					required: true,
					label: 'Min length of text',
				},
			],
		},
		{
			name: 'not',
			label: 'Must not be',
			arguments: [],
		},
		{
			name: 'number',
			label: 'Must be a number',
			arguments: [],
		},
		{
			name: 'required',
			label: 'Must not be empty (Field is required)',
			arguments: [],
		},
		{
			name: 'starts_with',
			label: 'Must start with',
			arguments: [
				{
					id: 1,
					name: 'text',
					type: 'text',
					required: true,
					label: 'Text to search for',
				},
			],
		},
		{
			name: 'url',
			label: 'Must be a URL',
			arguments: [],
		},
		{
			name: 'safeAlphaNumeric',
			label: 'Must be alphanumeric, hyphens, and underscores',
			arguments: [],
		},
	];
</script>
