import { acceptHMRUpdate, defineStore } from 'pinia';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { apolloClient } from '@/services/useApollo';
import { FetchResult } from '@apollo/client/core';
import { useBaseStore } from '@store';
import { ActivityTemplateSettings, ActivityTemplateVariables, CreateTemplateType } from '@/types/activityTemplate';
import { CREATE_NEW_TEMPLATE, UPDATE_TEMPLATE } from '@modules/builder/graphql/TemplateMutations';
import { ActivityTemplate } from '@/types/graphql';
import { GET_ACTIVE_TEMPLATES_DROPDOWN, GET_TEMPLATE_BY_ID, GET_TEMPLATES } from '@modules/builder/graphql/TemplateQueries';
import useNotify from '@utils/useNotify';
import { AlertIcons } from '@/types/dialog';
import { nanoid } from 'nanoid';
import { get } from 'lodash';
import { FormKitSchemaObject } from '@/types/form';
import useGraphQL from '@utils/useGraphQL';
import { safeUnpack } from '@utils/helpers';

export const useBuilderStore = defineStore({
	id: 'Builder',
	state: () => ({
		templates: <ActivityTemplate[]>[],
		template: <ActivityTemplate>{},
		settings: <ActivityTemplateSettings>{},
		variables: <ActivityTemplateVariables[]>[],
		loading: false,
		templateLoaded: false,
		templatesLoaded: false,
	}),
	getters: {
		activeTemplate: (state): ActivityTemplate => {
			return state.template;
		},
		activeTemplateLayoutSchema: (state): FormKitSchemaObject[] => {
			return (state.template?.layout?.current_version?.schema ? safeUnpack(state.template?.layout?.current_version?.schema, {}) : []) as FormKitSchemaObject[];
		},
		activeTemplateInitiatorSchema: (state): FormKitSchemaObject[] => {
			return (state.template?.initiator?.current_version?.schema ? safeUnpack(state.template?.initiator?.current_version?.schema, {}) : []) as FormKitSchemaObject[];
		},
	},
	actions: {
		async createNewTemplate(templateData: CreateTemplateType) {
			this.setLoadingStart();
			const { mutate: createTemplate, onError } = provideApolloClient(apolloClient)(() =>
				useMutation(CREATE_NEW_TEMPLATE, () => ({
					variables: {
						input: templateData,
					},
				})),
			);
			onError((error) => {
				throw new Error(error.message);
			});

			const createTemplateResponse = (await createTemplate()) as FetchResult;
			this.setLoadingFinished();

			return createTemplateResponse?.data?.createActivityTemplate.id;
		},
		async updateTemplate() {
			this.setLoadingStart();
			const { mutate: updateTemplate, onError } = provideApolloClient(apolloClient)(() =>
				useMutation(UPDATE_TEMPLATE, () => ({
					variables: {
						input: {
							id: this.template.id,
							type: this.template.type,
							status: this.template.status,
							name: this.template.name,
							description: this.template.description,
							settings: this.settings ? JSON.stringify(this.settings) : null,
							variables: this.variables ? JSON.stringify(this.variables) : null,
							layout_id: this.template.layout_id,
							initiator_id: this.template.initiator_id,
							workflow_id: this.template.workflow_id,
						},
					},
				})),
			);
			onError((error) => {
				useNotify.error('An error occurred', error.message);

				throw new Error(error.message);
			});

			const updateTemplateResponse = (await updateTemplate()) as FetchResult;
			if (updateTemplateResponse?.data?.updateActivityTemplate) {
				this.$patch({
					template: updateTemplateResponse?.data?.updateActivityTemplate,
					settings: safeUnpack(updateTemplateResponse?.data?.updateActivityTemplate.settings, {}),
					variables: safeUnpack(updateTemplateResponse?.data?.updateActivityTemplate.variables, []),
				});
				useNotify.icon({ icon: AlertIcons.Success }).title('Template successfully updated').fire();
			}
			this.setLoadingFinished();
		},
		async getTemplates(count = 30, page = 1) {
			this.setLoadingStart(true);
			const graphQlResult = await useGraphQL.query(
				GET_TEMPLATES,
				{
					first: count,
					page: page,
				},
				{
					fetchPolicy: 'cache-and-network',
				},
			);

			if (graphQlResult.getActivityTemplates) {
				this.templates = graphQlResult.getActivityTemplates.data;

				this.templatesLoaded = true;
			}

			this.setLoadingFinished();
		},
		async getTemplateById(templateId: string) {
			this.setLoadingStart(true);
			const graphQlResult = await useGraphQL.query(
				GET_TEMPLATE_BY_ID,
				{
					template_id: templateId,
				},
				{
					fetchPolicy: 'no-cache',
				},
			);

			if (graphQlResult.getActivityTemplateById) {
				console.dir(graphQlResult.getActivityTemplateById);
				console.dir(safeUnpack(graphQlResult.getActivityTemplateById.settings, {}));
				console.dir(safeUnpack(graphQlResult.getActivityTemplateById.variables, []));

				this.$patch({
					template: graphQlResult.getActivityTemplateById,
					settings: safeUnpack(graphQlResult.getActivityTemplateById.settings, {}),
					variables: safeUnpack(graphQlResult.getActivityTemplateById.variables, []),
				});
				this.templateLoaded = true;
			}
			this.setLoadingFinished();
		},
		setLoadingStart(reset = false) {
			if (reset) {
				this.templateLoaded = false;
				this.templatesLoaded = false;
			}
			const baseStore = useBaseStore();
			baseStore.setLoadingStart();
			this.loading = true;
		},
		setLoadingFinished() {
			const baseStore = useBaseStore();
			baseStore.setLoadingFinished();
			if (this.templateLoaded || this.templatesLoaded) {
				this.loading = false;
			}
		},
		addNewTemplateVariable(variableAddressToCreateVariable: string | boolean) {
			if (!variableAddressToCreateVariable) {
				//create it in the base array
				this.variables.push({
					id: nanoid(),
					name: '',
					type: undefined,
					tracked: true,
					children: [],
				} satisfies ActivityTemplateVariables);

				return '[' + (this.variables.length - 1) + ']';
			}
			const locationToAdd = get(this.variables, variableAddressToCreateVariable + '.children');
			locationToAdd.push({
				id: nanoid(),
				name: '',
				tracked: true,
				type: undefined,
				children: [],
			} satisfies ActivityTemplateVariables);

			return variableAddressToCreateVariable + '.children[' + (locationToAdd.length - 1) + ']';
		},
		async getActiveTemplatesDropdown(count = 30, page = 1) {
			const queryResult = await apolloClient.query({
				query: GET_ACTIVE_TEMPLATES_DROPDOWN,
				variables: {
					first: count,
					page: page,
				},
			});

			if (queryResult?.data?.getActivityTemplates) {
				const templateList = [];
				for (const template of queryResult.data.getActivityTemplates.data) {
					templateList.push({
						label: template.name + (template.description ? ' [' + template.description + ']' : ''),
						value: template.id,
					});
				}
				return templateList;
			}

			return [];
		},
		getDropdownList() {
			return this.getActiveTemplatesDropdown();
		},
		async getSelectedDropdownOption(templateId: string) {
			for (const templatesDropdownOption of await this.getActiveTemplatesDropdown()) {
				if (templatesDropdownOption.value === templateId) {
					return templatesDropdownOption;
				}
			}
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useBuilderStore, import.meta.hot));
}
