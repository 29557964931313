import gql from 'graphql-tag';

export const CREATE_NEW_FORM = gql`
	mutation Mutation($input: CreateFormInput!) {
		createForm(input: $input) {
			id
		}
	}
`;

export const UPDATE_FORM = gql`
	mutation Mutation($input: UpdateFormInput!) {
		updateForm(input: $input) {
			id
			type_id
			status_id
			settings
			name
			reference_name
			description
			current_version_id
			current_version_replaces
			current_version {
				id
				form_id
				version
				schema
				created_at
				created_by
				updated_at
				updated_by
			}
			version_history
			published_at
			published_by
			archived_at
			archived_by
			created_at
			created_by
			updated_at
			updated_by
			current_version_at
			current_version_by
		}
	}
`;

export const UPDATE_FORM_REQUEST = gql`
	mutation Mutation($input: UpdateFormRequestInput!) {
		updateFormRequest(input: $input) {
			id
			status
			visibility
			form_key
			settings
			data
			form {
				id
				name
				reference_name
				description
			}
			form_version {
				id
				version
				schema
			}
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
			created_at
			creator {
				first_name
				middle_name
				last_name
				id
			}
		}
	}
`;

export const SUBMIT_FORM_REQUEST = gql`
	mutation Mutation($input: SubmitFormRequestInput!) {
		submitFormRequest(input: $input) {
			id
			status
			visibility
			form_key
			settings
			data
			form {
				id
				name
				reference_name
				description
			}
			form_version {
				id
				version
				schema
			}
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
			created_at
			creator {
				first_name
				middle_name
				last_name
				id
			}
		}
	}
`;
