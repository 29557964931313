export type CreateActivity = {
	template_id: string;
};

export enum ActivityInteractionVisibility {
	PUBLIC = 1,
	PRIVATE = 2,
	AUDIT = 3,
	DELETED = 99,
}

export interface ActivityInteractionPayload {
	activity_id: number;
	content: string;
	visibility?: ActivityInteractionVisibility;
	meta?: ActivityInteractionMetaPayload;
}

export interface ActivityInteractionMetaPayload {
	assign?: number;
	tag?: string;
	followUp?: string;
}

export const ActivityStatus = {
	CREATING: 1,
	OPEN: 2,
	RESOLVED: 3,
	CLOSED: 4,
	ARCHIVED: 5,
	DELETED: 99,
};

export const ActivityStatusLabel = {
	[ActivityStatus.CREATING]: 'Creating',
	[ActivityStatus.OPEN]: 'Open',
	[ActivityStatus.RESOLVED]: 'Resolved',
	[ActivityStatus.CLOSED]: 'Closed',
	[ActivityStatus.ARCHIVED]: 'Archived',
	[ActivityStatus.DELETED]: 'Deleted',
};

export const ActivityVisibility = {
	PUBLIC: 1,
	PRIVATE: 2,
	AUDIT: 3,
	DELETED: 99,
};

export const ActivityVisibilityLabel = {
	[ActivityVisibility.PUBLIC]: 'Public',
	[ActivityVisibility.PRIVATE]: 'Private',
};
