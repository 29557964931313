import { acceptHMRUpdate, defineStore } from 'pinia';
import { ActivityInteractionPayload, ActivityStatusLabel, CreateActivity } from '@/types/activity';
import { ActivityTemplateSettings, ActivityVariables } from '@/types/activityTemplate';
import { provideApolloClient, useMutation, useQuery, useSubscription } from '@vue/apollo-composable';
import { apolloClient } from '@/services/useApollo';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { Activity, ActivityEvent, ActivityInteraction, ActivityLogs, ActivityTemplate, Query } from '@/types/graphql';
import { useBaseStore } from '@store';
import { CREATE_NEW_ACTIVITY, CREATE_NEW_ACTIVITY_INTERACTION, DELETE_ACTIVITY_INTERACTION, UPDATE_ACTIVITY } from '@modules/activities/graphql/ActivityMutations';
import { GET_ACTIVE_ACTIVITIES_DROPDOWN, GET_ACTIVITIES, GET_ACTIVITY_BY_ID } from '@modules/activities/graphql/ActivityQueries';
import plur from '@utils/usePlural';
import { safeUnpack, ucword } from '@utils/helpers';
import { ON_ACTIVITY_CREATED, ON_ACTIVITY_EVENT_CREATED, ON_ACTIVITY_UPDATED, ON_INTERACTION_CREATED } from '@modules/activities/graphql/ActivitySubscriptions';
import { FormKitSchemaNode } from '@formkit/core';
import useNotify from '@utils/useNotify';
import { AlertIcons } from '@/types/dialog';
import { getLocalizedDate, getRelativeFormatFrom } from '@utils/useDate';
import clone from '@utils/useClone';
import { useWorkflowProcessStore } from '@modules/workflow_process/store';

export const useActivityStore = defineStore({
	id: 'Activity',
	state: () => ({
		creatingNewInteraction: false,
		loading: false,
		activityLoaded: false,
		listenForNewInteractions: true,
		listenForNewEvents: true,
		listenForActivityUpdates: true,
		listenForNewActivities: true,
		listenForNewInteractionsStarted: false,
		listenForNewEventsStarted: false,
		listenForActivityUpdatesStarted: false,
		listenForNewActivitiesStarted: false,
		activities: <Activity[]>[],
		activity: <Activity>{},
		activityForms: {},
		activityTemplate: <ActivityTemplate>{},
		activityLayout: <FormKitSchemaNode[]>[],
		activityTimeline: <FormKitSchemaNode[]>[],
		activityInteractions: <ActivityInteraction[]>[],
		activityVariables: <ActivityVariables>{},
		activityEvents: <ActivityEvent[]>[],
		activityLogs: <ActivityLogs[]>[],
		activityTemplateSettings: <ActivityTemplateSettings>{
			interaction_label: 'interaction',
		},
	}),
	getters: {
		interactionLabelSingular: (state): string => {
			return ucword(plur(state.activityTemplateSettings?.interaction_label, 1)).toString();
		},
		interactionLabelPlural: (state): string => {
			return ucword(plur(plur(state.activityTemplateSettings?.interaction_label, 1), 2)).toString();
		},
		activeActivityId: (state): string | undefined => {
			return state.activity?.id;
		},
		activeLayoutSchema: (state): FormKitSchemaNode[] => {
			return state.activityLayout ?? [];
		},
		activityCreated: (state): string => {
			return getRelativeFormatFrom(getLocalizedDate(state.activity?.created_at)) ?? '';
		},
		activityLastUpdated: (state): string => {
			return getRelativeFormatFrom(getLocalizedDate(state.activity?.updated_at)) ?? '';
		},
		activityCreator: (state): string => {
			return state.activity?.creator?.first_name && state.activity?.creator?.last_name ? state.activity?.creator?.first_name + ' ' + state.activity?.creator?.last_name : '';
		},
		activityType: (state): string => {
			return state.activityTemplate?.name ?? '';
		},
		activityStatus: (state): number => {
			return state.activity?.status;
		},
		activityStatusLabel: (state): string => {
			return state.activity?.status ? ActivityStatusLabel[state.activity?.status] : '';
		},
		interactionLabel: (state): string => {
			return state.activityTemplateSettings && state.activityTemplateSettings.interaction_label ? state.activityTemplateSettings.interaction_label : '';
		},
	},
	actions: {
		async addNewActivityInteraction(interaction: ActivityInteractionPayload) {
			// send response to backend
			this.setLoadingStart(false);
			this.$patch({
				creatingNewInteraction: true,
			});
			const { mutate: createActivityInteraction, onError } = provideApolloClient(apolloClient)(() =>
				useMutation(CREATE_NEW_ACTIVITY_INTERACTION, () => ({
					variables: {
						input: interaction,
					},
				})),
			);
			onError((error) => {
				useNotify.error('An error occurred', error.message);

				throw new Error(error.message);
			});

			const createActivityInteractionResponse = (await createActivityInteraction()) as FetchResult;
			if (createActivityInteractionResponse?.data?.createActivityInteraction) {
				this.$patch({
					creatingNewInteraction: false,
					activityInteractions: [...this.activityInteractions, createActivityInteractionResponse.data.createActivityInteraction] as ActivityInteraction[],
				});
				useNotify
					.icon({ icon: AlertIcons.Success })
					.title(this.interactionLabelSingular + ' successfully created')
					.fire();
			}
			this.setLoadingFinished(false);
			return createActivityInteractionResponse?.data?.createActivityInteraction.id;
		},

		async deleteActivityInteraction(interactionId: string) {
			// send response to backend
			this.setLoadingStart(false);
			const { mutate: deleteActivityInteraction, onError } = provideApolloClient(apolloClient)(() =>
				useMutation(DELETE_ACTIVITY_INTERACTION, () => ({
					variables: {
						input: {
							id: interactionId,
						},
					},
				})),
			);
			onError((error) => {
				useNotify.error('An error occurred', error.message);
				throw new Error(error.message);
			});

			const deleteActivityInteractionResponse = (await deleteActivityInteraction()) as FetchResult;
			if (deleteActivityInteractionResponse?.data?.deleteActivityInteraction) {
				await this.getActivityById(this.activity.id, false);
				useNotify
					.icon({ icon: AlertIcons.Success })
					.title(this.interactionLabelSingular + ' successfully deleted')
					.fire();
			}
			this.setLoadingFinished(false);
		},
		async createNewActivity(templateData: CreateActivity) {
			this.setLoadingStart();
			const { mutate: createActivity, onError } = provideApolloClient(apolloClient)(() =>
				useMutation(CREATE_NEW_ACTIVITY, () => ({
					variables: {
						input: templateData,
					},
				})),
			);
			onError((error) => {
				throw new Error(error.message);
			});

			const createActivityResponse = (await createActivity()) as FetchResult;
			this.setLoadingFinished();

			return createActivityResponse?.data?.createActivity.id;
		},
		async updateActivity() {
			this.setLoadingStart();
			const { mutate: updateActivity, onError } = provideApolloClient(apolloClient)(() =>
				useMutation(UPDATE_ACTIVITY, () => ({
					variables: {
						input: {
							data: 'this',
						},
					},
				})),
			);
			onError((error) => {
				throw new Error(error.message);
			});

			const updateActivityResponse = (await updateActivity()) as FetchResult;

			if (updateActivityResponse?.data?.updateActivity) {
				this.$patch({});
			}
			this.setLoadingFinished();
		},
		async getActivities(count = 30, page = 1) {
			this.setLoadingStart();
			const { onResult } = provideApolloClient(apolloClient)(() =>
				useQuery(
					GET_ACTIVITIES,
					{
						first: count,
						page: page,
					},
					{
						fetchPolicy: 'cache-and-network',
					},
				),
			);
			onResult(async (queryResult: ApolloQueryResult<Query>) => {
				if (queryResult?.data?.getActivities) {
					this.activities = queryResult?.data?.getActivities.data;
				}
				this.setLoadingFinished();

				this.startNewActivitySubscription();
			});
		},
		clearActiveActivity() {
			this.$patch({
				activityLoaded: false,
				listenForNewInteractions: false,
				listenForNewEvents: false,
				listenForActivityUpdates: false,
				listenForNewActivities: false,
				activity: <Activity>{},
				activityForms: {},
				activityTemplate: <ActivityTemplate>{},
				activityLayout: <FormKitSchemaNode[]>[],
				activityTimeline: <FormKitSchemaNode[]>[],
				activityInteractions: <ActivityInteraction[]>[],
				activityEvents: <ActivityEvent[]>[],
				activityLogs: <ActivityLogs[]>[],
				activityTemplateSettings: <ActivityTemplateSettings>{
					interaction_label: 'interaction',
				},
			});

			this.activityVariables = {};

			const workflowProcessStore = useWorkflowProcessStore();

			workflowProcessStore.workflow_process_id = null;
			workflowProcessStore.history = [];
			workflowProcessStore.variables = {};
		},
		async getActivityById(activityId: string, loading = true) {
			if (loading) {
				this.setLoadingStart();
			}
			this.clearActiveActivity();
			const { onResult } = provideApolloClient(apolloClient)(() =>
				useQuery(
					GET_ACTIVITY_BY_ID,
					{
						activity_id: activityId,
					},
					{
						fetchPolicy: 'no-cache',
					},
				),
			);

			onResult(async (queryResult: ApolloQueryResult<Query>) => {
				if (queryResult?.data?.getActivityById) {
					this.$patch({
						activity: queryResult.data.getActivityById,
						activityForms: safeUnpack(queryResult.data.getActivityById.forms, {}),
						activityTemplate: queryResult.data.getActivityById.template as ActivityTemplate,
						activityInteractions: queryResult.data.getActivityById.interactions as ActivityInteraction[],
						activityEvents: queryResult.data.getActivityById.events as ActivityEvent[],
						activityLogs: queryResult.data.getActivityById.logs as ActivityLogs[],
						activityTemplateSettings: safeUnpack(queryResult.data.getActivityById.template?.settings, {}),
						activityLayout: safeUnpack(queryResult.data.getActivityById.layout?.current_version?.schema, []),
						activityVariables: safeUnpack(queryResult.data.getActivityById.variables, {}),
						activityLoaded: true,
					});

					const workflowProcessStore = useWorkflowProcessStore();
					workflowProcessStore.$patch({
						workflow_process_id: parseInt(queryResult.data.getActivityById.workflow_process?.id ?? ''),
						variables: safeUnpack(queryResult.data.getActivityById.workflow_process?.variables, []),
						history: safeUnpack(queryResult.data.getActivityById.workflow_process?.history, []),
					});

					this.startActivityUpdatedSubscription(true);
					this.startNewActivityInteractionSubscription(true);
					this.startNewActivityEventSubscription(true);
				}
				if (loading) {
					this.setLoadingFinished();
				}
			});
		},
		startNewActivitySubscription() {
			if (!this.listenForNewActivitiesStarted) {
				const { onResult } = provideApolloClient(apolloClient)(() =>
					useSubscription(ON_ACTIVITY_CREATED, {}, () => ({
						enabled: this.listenForNewActivities,
						fetchPolicy: 'no-cache',
					})),
				);

				onResult((subscriptionData) => {
					if (subscriptionData.data.activityCreated) {
						this.$patch({
							activities: [subscriptionData.data.activityCreated, ...this.activities] as Activity[],
						});
					}
				});
				this.listenForNewActivitiesStarted = true;
			}
		},
		startNewActivityInteractionSubscription(restartSubscription: boolean) {
			if (!this.listenForNewInteractionsStarted) {
				const { onResult } = provideApolloClient(apolloClient)(() =>
					useSubscription(
						ON_INTERACTION_CREATED,
						() => ({
							activity_id: this.activeActivityId,
						}),
						() => ({
							enabled: this.listenForNewInteractions && !!this.activeActivityId,
							fetchPolicy: 'no-cache',
						}),
					),
				);

				onResult((subscriptionData) => {
					if (subscriptionData.data.activityInteractionCreated) {
						let existing = false;
						for (const activityInteraction of this.activityInteractions) {
							if (activityInteraction.id == subscriptionData.data.activityInteractionCreated.id) {
								existing = true;
							}
						}
						if (!existing) {
							this.$patch({
								activityInteractions: [...this.activityInteractions, subscriptionData.data.activityInteractionCreated] as ActivityInteraction[],
							});
						}
					}
				});
				this.listenForNewInteractionsStarted = true;
			}
			if (restartSubscription) {
				this.listenForNewInteractions = true;
			}
		},
		startNewActivityEventSubscription(restartSubscription: boolean) {
			if (!this.listenForNewEventsStarted) {
				const { onResult } = provideApolloClient(apolloClient)(() =>
					useSubscription(
						ON_ACTIVITY_EVENT_CREATED,
						() => ({
							activity_id: this.activeActivityId,
						}),
						() => ({
							enabled: this.listenForNewEvents && !!this.activeActivityId,
							fetchPolicy: 'no-cache',
						}),
					),
				);

				onResult((subscriptionData) => {
					if (subscriptionData.data.activityEventCreated) {
						let existing = false;
						for (const activityEvent of this.activityEvents) {
							if (activityEvent.id == subscriptionData.data.activityEventCreated.id) {
								existing = true;
							}
						}
						if (!existing) {
							this.$patch({
								activityEvents: clone([...this.activityEvents, subscriptionData.data.activityEventCreated]) as ActivityEvent[],
							});
						}
					}
				});
				this.listenForNewEventsStarted = true;
			}
			if (restartSubscription) {
				this.listenForNewEvents = true;
			}
		},
		startActivityUpdatedSubscription(restartSubscription: boolean) {
			if (!this.listenForActivityUpdatesStarted) {
				const { onResult } = provideApolloClient(apolloClient)(() =>
					useSubscription(
						ON_ACTIVITY_UPDATED,
						() => ({
							activity_id: this.activeActivityId,
						}),
						() => ({
							enabled: this.listenForActivityUpdates && !!this.activeActivityId,
							fetchPolicy: 'no-cache',
						}),
					),
				);

				onResult((subscriptionData) => {
					if (subscriptionData.data.activityUpdated) {
						this.$patch({
							activity: { ...this.activity, ...subscriptionData.data.activityUpdated },
							activityVariables: safeUnpack(subscriptionData.data.activityUpdated.variables, []),
						});

						const workflowProcessStore = useWorkflowProcessStore();
						workflowProcessStore.$patch({
							variables: safeUnpack(subscriptionData.data.activityUpdated.workflow_process?.variables, []),
							history: safeUnpack(subscriptionData.data.activityUpdated.workflow_process?.history, []),
						});
					}
				});
				this.listenForActivityUpdatesStarted = true;
			}
			if (restartSubscription) {
				this.listenForActivityUpdates = true;
			}
		},
		setLoadingStart(local = true) {
			const baseStore = useBaseStore();
			baseStore.setLoadingStart();
			if (local) {
				this.loading = true;
			}
		},
		setLoadingFinished(local = true) {
			const baseStore = useBaseStore();
			baseStore.setLoadingFinished();
			if (local) {
				this.loading = false;
			}
		},
		async getActiveActivitiesDropdown(count = 30, page = 1) {
			const queryResult = await apolloClient.query({
				query: GET_ACTIVE_ACTIVITIES_DROPDOWN,
				variables: {
					first: count,
					page: page,
				},
			});

			if (queryResult?.data?.getActivities) {
				const activityList = [];
				for (const activity of queryResult.data.getActivities.data) {
					activityList.push({
						label: ucword(activity.template.type.toString()) + ' #' + activity.id + '(' + activity.template.name + ')' + (activity.description ? ' [' + activity.description + ']' : ''),
						value: activity.id,
					});
				}
				return activityList;
			}

			return [];
		},
		getDropdownList() {
			return this.getActiveActivitiesDropdown();
		},
		async getSelectedDropdownOption(activityId: string) {
			for (const activitiesDropdownOption of await this.getActiveActivitiesDropdown()) {
				if (activitiesDropdownOption.value === activityId) {
					return activitiesDropdownOption;
				}
			}
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useActivityStore, import.meta.hot));
}
