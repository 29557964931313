import { acceptHMRUpdate, defineStore } from 'pinia';
import { SweetAlertOptions } from 'sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import clone from '@utils/useClone';
import { AlertIcons, AlertPosition, DialogIcon } from '@/types/dialog';

type NotificationState = {
	active: boolean;
	title: string | null;
	icon: DialogIcon | null;
	html: boolean;
};

const useNotificationStore = defineStore({
	id: 'Notification',
	state: () =>
		({
			active: false,
			title: null,
			icon: null,
			html: false,
		}) as NotificationState,
	getters: {
		hasIcon: (state): boolean => state.icon != null,
		isHTML: (state): boolean => state.html,
	},
	actions: {
		reset() {
			this.$patch({
				active: false,
				title: null,
				icon: null,
				html: false,
			} as NotificationState);
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot));
}

// noinspection JSUnusedGlobalSymbols
const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	showCloseButton: true,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

// -----------------------------------
// Public interface
// -----------------------------------

export const useNotify = {
	get state() {
		return useNotificationStore();
	},

	title(title: string) {
		this.state.title = title;
		return this;
	},

	icon({ icon, colour = undefined }: { icon: AlertIcons; colour?: string }) {
		this.state.icon = {
			icon: icon,
			colour: colour,
		};
		return this;
	},

	error(title: string, text: string) {
		Swal.fire({
			position: AlertPosition.TopEnd,
			icon: AlertIcons.Error,
			title: title,
			text: text,
			showConfirmButton: false,
			timer: 10000,
			timerProgressBar: true,
		});
	},

	fire() {
		const notifyOptions: SweetAlertOptions = {
			title: '',
			icon: undefined,
			iconColor: undefined,
		};

		if (this.state.title != null) {
			notifyOptions.title = this.state.title;
		}

		if (this.state.icon != null) {
			notifyOptions.icon = this.state.icon.icon;
			if (this.state.icon && this.state.icon.colour != null) {
				notifyOptions.iconColor = this.state.icon.colour;
			}
		}

		Toast.fire(clone(notifyOptions)); // clone it to prevent any wierd super clingy reactive things

		this.state.reset();
	},

	close() {
		Swal.close();
		this.state.reset();
	},
};

export default useNotify;
