<template>
	<div :class="props.context.classes.outer + ' w-full grid grid-cols-12 items-end'">
		<div class="col-span-12">
			<FormKit :value="selectedClass" :ignore="true" type="dropdown" :label="props.context.label" :options="props.context?.classOptions" @input="(value) => updateClasses(value)" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, reactive, ref } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';
	import { useFormStore } from '@modules/form/store';

	interface Props {
		context: FormKitFrameworkContext;
	}

	type ClassObject = { [key: string]: boolean };
	type StringKeyedObject = { [key: string]: string };

	const formStore = useFormStore();
	const selectedClass = ref();
	const props = defineProps<Props>();
	const availableClasses = reactive<StringKeyedObject>(props.context.classOptions ?? {});
	const currentClasses = ref(props.context.value);

	const staticElement = () => {
		return props.context.staticElement ?? false;
	};

	const directReturn = () => {
		return props.context.directReturn ?? false;
	};

	const identifyCurrentClass = (currentClasses: ClassObject, availableClasses: StringKeyedObject) => {
		if (currentClasses) {
			for (const classOption of Object.keys(currentClasses)) {
				for (const availableClass of Object.keys(availableClasses)) {
					if (classOption == availableClass) {
						if (selectedClass.value !== classOption) {
							selectedClass.value = classOption;
						}
						return true;
					}
				}
			}
		}
	};

	identifyCurrentClass(props.context.value, availableClasses);

	// Have to update the central schema because reactivity is being a complete B!^*%(^
	const updateClasses = (selectedClassValue: string) => {
		const classes: ClassObject = {};
		const editingElementValue = formStore.getEditingElement();
		if (staticElement()) {
			currentClasses.value = editingElementValue.attrs[props.context.node.name];
		} else {
			currentClasses.value = editingElementValue[props.context.node.name];
		}
		if (staticElement()) {
			let found = false;
			if (editingElementValue?.attrs[props.context.node.name]) {
				for (const classOption of Object.keys(editingElementValue?.attrs[props.context.node.name])) {
					if (classOption == selectedClass.value) {
						classes[selectedClassValue] = true;
						found = true;
					} else {
						classes[classOption] = editingElementValue?.attrs[props.context.node.name][classOption];
					}
				}
			}
			if (!found) {
				classes[selectedClassValue] = true;
			}
		} else if (directReturn()) {
			const found = false;
			if (currentClasses.value) {
				for (const classOption of Object.keys(currentClasses.value)) {
					if (classOption == selectedClass.value) {
						classes[selectedClassValue] = true;
					} else {
						classes[classOption] = currentClasses.value[classOption];
					}
				}
			}
			if (!found) {
				classes[selectedClassValue] = true;
			}
		} else {
			const found = false;
			if (editingElementValue[props.context.node.name]) {
				for (const classOption of Object.keys(editingElementValue[props.context.node.name])) {
					if (classOption == selectedClass.value) {
						classes[selectedClassValue] = true;
					} else {
						classes[classOption] = editingElementValue[props.context.node.name][classOption];
					}
				}
			}
			if (!found) {
				classes[selectedClassValue] = true;
			}
		}
		selectedClass.value = selectedClassValue;
		if (directReturn()) {
			props.context.node.input(classes);
			currentClasses.value = classes;
		} else {
			if (staticElement()) {
				editingElementValue.attrs[props.context.node.name] = classes;
			} else {
				editingElementValue[props.context.node.name] = classes;
			}
		}
	};
</script>
