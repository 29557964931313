import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';
import { useAuthStore } from '@modules/auth/store';
import 'vue-router';

declare module 'vue-router' {
	// noinspection JSUnusedGlobalSymbols
	interface RouteMeta {
		requiresAdmin?: boolean;
		requiresAuth?: boolean;
		embeddedRoute?: boolean;
	}
}

interface RouteFile {
	guestRoutes?: RouteRecordRaw[];
	authRoutes?: RouteRecordRaw[];
	adminRoutes?: RouteRecordRaw[];
}

const baseRoutes: RouteRecordRaw[] = [];

// Import all the resource routes files.
function loadRoutes(): RouteRecordRaw[] {
	const routes: RouteRecordRaw[] = baseRoutes;
	const modules = import.meta.glob('../modules/**/routes.ts', { eager: true }); //can't use alias mapping
	Object.values(modules).forEach((value: unknown) => {
		const module = value as RouteFile;
		if (module.guestRoutes) {
			Object.values(module.guestRoutes).forEach((route: RouteRecordRaw) => {
				routes.push(<RouteRecordRaw>route);
			});
		}
		if (module.authRoutes) {
			Object.values(module.authRoutes).forEach((route: RouteRecordRaw) => {
				if (!route.meta) {
					route['meta'] = { requiresAuth: true };
				} else {
					route.meta['requiresAuth'] = true;
				}
				routes.push(<RouteRecordRaw>route);
			});
		}
		if (module.adminRoutes) {
			Object.values(module.adminRoutes).forEach((route: RouteRecordRaw) => {
				if (!route.meta) {
					route['meta'] = { requiresAdmin: true };
				} else {
					route.meta['requiresAdmin'] = true;
				}
				routes.push(<RouteRecordRaw>route);
			});
		}
	});
	const fallbackRoute: RouteRecordRaw = { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('../components/PageNotFound.vue') };
	routes.push(<RouteRecordRaw>fallbackRoute);
	return routes;
}

const router: Router = createRouter({
	history: createWebHistory(),
	routes: loadRoutes(),
});

router.beforeEach((to, from) => {
	const authStore = useAuthStore();

	if (from.meta.embeddedRoute) {
		return false;
	}

	if (to.meta.requiresAuth && !authStore.isAuthenticated) {
		return {
			name: 'login',
		};
	} else if (to.meta.requiresAdmin && !authStore.isAuthenticated) {
		return {
			name: 'login',
		};
	}
});

router.afterEach((to) => {
	if (to.name == 'logout') {
		router.push({
			name: 'login',
		});
	}
});

export default router as Router;
