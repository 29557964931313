import { RouteRecordRaw } from 'vue-router';

export const guestRoutes: RouteRecordRaw[] = [];

export const authRoutes: RouteRecordRaw[] = [
	{
		path: '/form',
		name: 'form.index',
		component: () => import('./layouts/index.vue'),
	},
	{
		path: '/form/create',
		name: 'form.create',
		component: () => import('./layouts/index.vue'),
	},
	{
		path: '/form/:form_id',
		name: 'form.show',
		component: () => import('./layouts/show.vue'),
	},
	{
		path: '/form/embedded/:form_schema',
		name: 'form.embedded',
		meta: { embeddedRoute: true },
		component: () => import('./layouts/embedded.vue'),
	},
	{
		path: '/form/kitchen_sink',
		name: 'form.test',
		component: () => import('./layouts/KitchenSink.vue'),
	},
];

export const adminRoutes: RouteRecordRaw[] = [];

export default [...guestRoutes, ...authRoutes, ...adminRoutes];
