import { acceptHMRUpdate, defineStore } from 'pinia';
import { Organisation, User, UserPreferences, UserProfile } from '@/types/graphql';
import { UserObjectQueryData } from '@/types/user';
import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { apolloClient } from '@/services/useApollo';
import { initializeSentryUserContext } from '@/services/useSentry';
import { ApolloQueryResult } from '@apollo/client/core';
import { GET_LOGGED_IN_USER } from '@modules/user/graphql/UserQueries';
import { getInitials } from '@utils/helpers';
import { useAuthStore } from '@modules/auth/store';
import { Channel, PresenceChannel } from 'pusher-js';
import PusherService from '@/services/usePusher';

export const useUserStore = defineStore({
	id: 'User',
	state: () => ({
		loaded: false,
		user: <User>{},
		profile: <UserProfile>{},
		preferences: <UserPreferences>{},
		organisation: <Organisation>{},
		organisationBroadcastChannel: <PresenceChannel>{},
		userBroadcastChannel: <Channel>{},
	}),
	getters: {
		isUserLoaded: (state): boolean => state.loaded,
		getUser: (state): User | null => (state.loaded ? state.user : null),
		getUserID: (state): number => (state.loaded ? parseInt(state.user?.id) : 0),
		getUserEmail: (state): string | undefined => (state.loaded ? state.user.email : 'demo@acme.com.au'),
		getUserFirstName: (state): string => (state.loaded ? state.user?.first_name ?? '' : 'Demo'),
		getUserMiddleName: (state): string | undefined => (state.loaded ? state.user?.middle_name ?? '' : ''),
		getUserLastName: (state): string | undefined => (state.loaded ? state.user?.last_name ?? '' : 'Person'),
		getUserPreferredName: (state): string | undefined => (state.loaded ? state.profile?.preferred_name ?? '' : undefined),
		getUserPosition: (state): string | undefined => (state.loaded ? state.profile?.position ?? '' : 'Demo Manager'),
		/**
		 * Returns the full name of the user.
		 *
		 * @returns {string}
		 */
		getUserFullName(): string {
			if (this.getUserMiddleName) {
				return `${this.getUserFirstName} ${this.getUserMiddleName} ${this.getUserLastName}`;
			}
			return `${this.getUserFirstName} ${this.getUserLastName}`;
		},
		/**
		 * Returns the name of the user (use preferred name where set).
		 *
		 * @returns {string}
		 */
		getUserName(): string {
			if (this.profile && this.profile.preferred_name) {
				return String(this.getUserPreferredName);
			}
			return this.getUserFullName;
		},
		/**
		 * Returns the initials of the user.
		 *
		 * @returns {string}
		 */
		getUserInitials(): string {
			return getInitials(this.getUserFirstName.toString(), String(this.getUserLastName));
		},
		getUserTimeZone: (state): string | undefined => (state.loaded && state.preferences && state.preferences?.time_zone ? state.preferences?.time_zone : 'Australia/Sydney'),
		getUserAvatar: (state): string =>
			state.loaded && state.profile && state.profile.avatar
				? state.profile?.avatar?.url
				: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
	},
	actions: {
		async getLoggedInUser() {
			const authStore = useAuthStore();
			// check if the users profile is stored in local state
			if (localStorage.getItem('user') && localStorage.getItem('user_profile') && localStorage.getItem('user_preferences') && localStorage.getItem('user_organisation')) {
				this.user = JSON.parse(localStorage.getItem('user') ?? '') as User;
				this.profile = JSON.parse(localStorage.getItem('user_profile') ?? '') as UserProfile;
				this.preferences = JSON.parse(localStorage.getItem('user_preferences') ?? '') as UserPreferences;
				this.organisation = JSON.parse(localStorage.getItem('user_organisation') ?? '') as Organisation;
				if (!this.loaded) {
					await this.doPostUserFetchActions();
					if (!(await authStore.status())) {
						await authStore.clearAuthentication();
					}
					return;
				}
			}
			// otherwise fetch a fresh one!
			const { onResult, onError } = provideApolloClient(apolloClient)(() => useQuery(GET_LOGGED_IN_USER));

			onResult(async (queryResult: ApolloQueryResult<UserObjectQueryData>) => {
				if (queryResult?.data?.me) {
					this.breakApartUserObject(queryResult.data.me);
					localStorage.setItem('user', JSON.stringify(this.user));
					localStorage.setItem('user_profile', JSON.stringify(this.profile));
					localStorage.setItem('user_preferences', JSON.stringify(this.preferences));
					localStorage.setItem('user_organisation', JSON.stringify(this.organisation));
					await this.doPostUserFetchActions();
				}
			});
			onError((error) => {
				if (error.message === 'Not Authenticated') {
					authStore.clearAuthentication();
				}
			});
		},
		breakApartUserObject(userJsonObject: User | string) {
			const userObject = JSON.parse(JSON.stringify(userJsonObject)) as User;
			if (userObject?.profile) {
				this.profile = userObject.profile;
				delete userObject.profile;
			}
			if (userObject?.preferences) {
				this.preferences = userObject.preferences;
				delete userObject.preferences;
			}
			if (userObject?.organisation) {
				this.organisation = userObject.organisation;
				delete userObject.organisation;
			}
			this.user = userObject;
		},
		async doPostUserFetchActions() {
			if (this.user && Object.keys(this.user).length > 0 && Object.getPrototypeOf(this.user) === Object.prototype) {
				this.loaded = true;
				await initializeSentryUserContext({
					user: {
						id: this.getUserID.toString(),
						email: this.getUserEmail?.toString() ?? '',
						name: this.getUserFullName,
					},
				});
			}
			// handle no user but logged in?
		},
		async joinOrganisationBroadcastChannel() {
			this.organisationBroadcastChannel = PusherService.subscribe('presence-organisation.' + this.organisation.id) as PresenceChannel;
		},
		async joinUserBroadcastChannel() {
			this.userBroadcastChannel = PusherService.subscribe('private-user.' + this.user.global_id);
		},
		async leaveOrganisationBroadcastChannel() {
			PusherService.unsubscribe('presence-organisation.' + this.organisation.id);
			this.organisationBroadcastChannel = <PresenceChannel>{};
		},
		async leaveUserBroadcastChannel() {
			PusherService.unsubscribe('private-user.' + this.user.global_id);
			this.userBroadcastChannel = <Channel>{};
		},
		async clearUser() {
			await this.leaveOrganisationBroadcastChannel();
			await this.leaveUserBroadcastChannel();
			localStorage.removeItem('user');
			localStorage.removeItem('user_profile');
			localStorage.removeItem('user_preferences');
			localStorage.removeItem('user_organisation');
			this.$patch({
				user: <User>{},
				profile: <UserProfile>{},
				preferences: <UserPreferences>{},
				organisation: <Organisation>{},
				loaded: false,
			});
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
