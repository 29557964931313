import { AuthConfig as AuthConfigType } from '@/types/auth';

const authBaseUrl = import.meta.env.VITE_AUTH_BASE_URL?.toString().endsWith('/') ? import.meta.env.VITE_AUTH_BASE_URL?.toString() : import.meta.env.VITE_AUTH_BASE_URL?.toString() + '/';

export const AuthConfig: AuthConfigType = {
	register_url: authBaseUrl + 'register',
	login_url: authBaseUrl + 'login',
	challenge_url: authBaseUrl + 'challenge',
	refresh_url: authBaseUrl + 'refresh',
	logout_url: authBaseUrl + 'logout',
	forgot_url: authBaseUrl + 'forgot',
	reset_url: authBaseUrl + 'reset',
	status_url: authBaseUrl + 'status',
};
