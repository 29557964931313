// noinspection JSUnusedGlobalSymbols

import { acceptHMRUpdate, defineStore } from 'pinia';
import { SweetAlertInput, SweetAlertOptions } from 'sweetalert2';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import clone from '@utils/useClone';
import { AlertIcons, AlertPosition, DialogIcon, DialogResult } from '@/types/dialog';

export const enum AlertTypes {
	Alert = 'alert',
	Confirm = 'confirm',
	Prompt = 'prompt',
}

export const enum AlertInputTypes {
	Text = 'text',
	Email = 'email',
	Url = 'url',
	Password = 'password',
	Textarea = 'textarea',
	Select = 'select',
	Radio = 'radio',
	Checkbox = 'checkbox',
	File = 'file',
	Range = 'range',
}

type DialogInput = {
	type: Exclude<SweetAlertInput, 'file'>;
	label?: string;
	placeholder?: string;
	attributes?: Record<string, string>;
	options?: object;
	value?: SyncOrAsync<string | number | File | FileList>;
	validator?: (inputValue: string) => SyncOrAsync<string | null>;
};

type DialogButton = {
	text: string;
	label: string;
	colour: string | undefined;
};
type DialogState = {
	type: AlertTypes | null;
	active: boolean;
	message: string | null;
	title: string | null;
	icon: DialogIcon | null;
	confirm: DialogButton | null;
	deny: DialogButton | null;
	cancel: DialogButton | null;
	footer: string | null;
	input: DialogInput | null;
	html: boolean;
	width: string | null;
	padding: string | null;
	position: AlertPosition | null;
	colour: string | null;
};

type SyncOrAsync<T> = T | Promise<T> | { toPromise: () => T };

const useDialogStore = defineStore({
	id: 'Dialog',
	state: () =>
		({
			type: null,
			active: false,
			message: null,
			title: null,
			icon: null,
			confirm: null,
			deny: null,
			cancel: null,
			input: null,
			html: false,
			footer: null,
			position: AlertPosition.Center,
			width: '60em',
			padding: '0 0 1.25em',
		}) as DialogState,
	getters: {
		hasIcon: (state): boolean => state.icon != null,
		hasConfirmButton: (state): boolean => !!state.confirm,
		hasDenyButton: (state): boolean => !!state.deny,
		hasCancelButton: (state): boolean => !!state.cancel,
		hasInput: (state): boolean => !!state.input,
		hasFooter: (state): boolean => !!state.footer,
		isHTML: (state): boolean => state.html,
		hasCustomPosition: (state): boolean => state.position != null,
		hasCustomWidth: (state): boolean => state.width != null,
		hasCustomPadding: (state): boolean => state.padding != null,
	},
	actions: {
		reset() {
			this.$patch({
				type: null,
				active: false,
				message: null,
				title: null,
				icon: null,
				confirm: null,
				deny: null,
				cancel: null,
				input: null,
				html: false,
				footer: null,
				position: AlertPosition.Center,
				width: '60em',
				padding: '0 0 1.25em',
			});
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useDialogStore, import.meta.hot));
}

// -----------------------------------
// Private Methods
// -----------------------------------
const open = () => {
	const state = useDialogStore();
	const dialogOptions: SweetAlertOptions = {};
	if (state.isHTML) {
		dialogOptions.title = state.title ?? '';
		dialogOptions.html = state.message ?? '';
	} else {
		dialogOptions.titleText = state.title ?? '';
		dialogOptions.text = state.message ?? '';
	}
	if (state.icon != null) {
		dialogOptions.icon = state.icon.icon;
		if (state.icon && state.icon.colour != null) {
			dialogOptions.iconColor = state.icon.colour;
		}
	}
	if (state.footer != null) {
		dialogOptions.footer = state.footer;
	}
	if (state.padding != null) {
		dialogOptions.padding = state.padding;
	}
	if (state.width != null) {
		dialogOptions.width = state.width;
	}
	if (state.position != null) {
		dialogOptions.position = state.position;
	}
	if (state.input != null) {
		dialogOptions.input = state.input.type;
		if (state.input && state.input.attributes != null) {
			dialogOptions.inputAttributes = state.input.attributes;
		}
		if (state.input && state.input.label != null) {
			dialogOptions.inputLabel = state.input.label;
		}
		if (state.input && state.input.placeholder != null) {
			dialogOptions.inputPlaceholder = state.input.placeholder;
		}
		if (state.input && state.input.options != null) {
			dialogOptions.inputOptions = state.input.options;
		}
		if (state.input && state.input.validator != null) {
			dialogOptions.inputValidator = state.input.validator;
		}
		if (state.input && state.input.value != null) {
			dialogOptions.inputValue = state.input.value;
		}
	}
	if (state.hasConfirmButton) {
		dialogOptions.showConfirmButton = true;
		if (state.confirm && state.confirm.text != null) {
			dialogOptions.confirmButtonText = state.confirm.text;
		}
		if (state.confirm && state.confirm.colour != null) {
			dialogOptions.confirmButtonColor = state.confirm.colour;
		}
		if (state.confirm && state.confirm.label != null) {
			dialogOptions.confirmButtonAriaLabel = state.confirm.label;
		}
	}
	if (state.hasDenyButton) {
		dialogOptions.showDenyButton = true;
		if (state.deny && state.deny.text != null) {
			dialogOptions.denyButtonText = state.deny.text;
		}
		if (state.deny && state.deny.colour != null) {
			dialogOptions.denyButtonColor = state.deny.colour;
		}
		if (state.deny && state.deny.label != null) {
			dialogOptions.denyButtonAriaLabel = state.deny.label;
		}
	}
	if (state.hasCancelButton) {
		dialogOptions.showCancelButton = true;
		if (state.cancel && state.cancel.text != null) {
			dialogOptions.cancelButtonText = state.cancel.text;
		}
		if (state.cancel && state.cancel.colour != null) {
			dialogOptions.cancelButtonColor = state.cancel.colour;
		}
		if (state.cancel && state.cancel.label != null) {
			dialogOptions.cancelButtonAriaLabel = state.cancel.label;
		}
	}
	dialogOptions.reverseButtons = true;

	state.active = true;
	return new Promise<DialogResult>((resolve, reject) => {
		Swal.fire(clone(dialogOptions))
			.then((result: DialogResult) => {
				/* Read more about isConfirmed, isDenied below */
				return resolve(result);
			})
			.catch(() => {
				reject();
			});
		state.reset();
	});
};

// -----------------------------------
// Public interface
// -----------------------------------

export const useDialog = {
	get state() {
		return useDialogStore();
	},

	title(title: string) {
		this.state.title = title;
		return this;
	},

	icon({ icon, colour = undefined }: { icon: AlertIcons; colour?: string }) {
		this.state.icon = {
			icon: icon,
			colour: colour,
		};
		return this;
	},

	position(position: AlertPosition) {
		this.state.position = position;
		return this;
	},

	width(width: string) {
		this.state.width = width;
		return this;
	},

	padding(padding: string) {
		this.state.padding = padding;
		return this;
	},

	confirmButton({ text, ariaLabel = '', colour = undefined }: { text: string; ariaLabel: string; colour?: string }) {
		this.state.confirm = {
			text: text,
			label: ariaLabel,
			colour: colour,
		};
		return this;
	},

	denyButton({ text, ariaLabel = '', colour = undefined }: { text: string; ariaLabel: string; colour?: string }) {
		this.state.deny = {
			text: text,
			label: ariaLabel,
			colour: colour,
		};
		return this;
	},

	cancelButton({ text, ariaLabel = '', colour = undefined }: { text: string; ariaLabel: string; colour?: string }) {
		this.state.cancel = {
			text: text,
			label: ariaLabel,
			colour: colour,
		};
		return this;
	},

	input(inputOptions: DialogInput) {
		this.state.input = inputOptions;
		return this;
	},

	footer(footer: string) {
		this.state.footer = footer;
		return this;
	},

	html(enabled = true) {
		this.state.html = enabled;
		return this;
	},

	alert(message: string) {
		this.state.type = AlertTypes.Alert;
		this.state.message = message;
		return open();
	},

	confirm(message: string) {
		this.state.type = AlertTypes.Confirm;
		this.state.message = message;
		if (!this.state.hasConfirmButton) {
			this.confirmButton({ ariaLabel: 'Yes', colour: '#22c55e', text: 'Yes' });
		}
		if (!this.state.hasDenyButton) {
			this.denyButton({ ariaLabel: 'No', colour: '#ef4444', text: 'No' });
		}
		return open();
	},

	prompt(message: string) {
		this.state.type = AlertTypes.Prompt;
		this.state.message = message;
		return open();
	},

	close() {
		Swal.close();
		this.state.reset();
	},
};

export default useDialog;
