/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ConditionalField, FormKitSchemaObject, FormSubmissionObject } from '@/types/form';
import { useFormStore } from '@modules/form/store';
import { useWorkflowStore } from '@modules/workflow/store';
import { DropdownObject } from '@/types/app';
import { useActivityStore } from '@modules/activities/store';
import { useBuilderStore } from '@modules/builder/store';

export function getValueWithKeyFromStyleObject(styles: { [key: string]: string }, style: string): string | undefined {
	if (styles) {
		for (const styleKey of Object.keys(styles)) {
			if (styleKey === style) {
				return styles[styleKey].toString();
			}
		}
	}

	return undefined;
}

export function recursiveElementDescriptionSearch(element: FormKitSchemaObject): ConditionalField[] {
	let fieldDescription = [];
	// @ts-ignore
	if (Object.hasOwn(element, '$formkit')) {
		// @ts-ignore
		fieldDescription.push({ id: element.id, label: element.label ?? element.name, name: element.name, type: element['$formkit'] } as ConditionalField);
	}
	// @ts-ignore
	if (Object.hasOwn(element, 'children')) {
		// @ts-ignore
		fieldDescription = [...fieldDescription, ...recursiveElementDescriptionSearch(element.children)];
	}
	return fieldDescription;
}

export function cleanFormData(formData: FormSubmissionObject): FormSubmissionObject {
	return JSON.parse(JSON.stringify(formData, (k, v) => (k === 'slots' ? undefined : v)));
}

export async function getOptionsForActiveFormsDropdown() {
	const formStore = useFormStore();

	return formStore.getDropdownList();
}

export async function getSelectedOptionForActiveFormsDropdown(formId: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const formStore = useFormStore();

	return formStore.getSelectedDropdownOption(formId);
}

export async function getOptionsForActiveWorkflowsDropdown() {
	const workflowStore = useWorkflowStore();

	return workflowStore.getDropdownList();
}

export async function getSelectedOptionForActiveWorkflowsDropdown(workflowId: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const workflowStore = useWorkflowStore();

	return workflowStore.getSelectedDropdownOption(workflowId);
}

export async function getOptionsForActiveActivitiesDropdown() {
	const activityStore = useActivityStore();

	return activityStore.getDropdownList();
}

export async function getSelectedOptionForActiveActivitiesDropdown(workflowId: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const activityStore = useActivityStore();

	return activityStore.getSelectedDropdownOption(workflowId);
}

export async function getOptionsForActiveTemplatesDropdown() {
	const builderStore = useBuilderStore();

	return builderStore.getDropdownList();
}

export async function getSelectedOptionForActiveTemplatesDropdown(workflowId: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const builderStore = useBuilderStore();

	return builderStore.getSelectedDropdownOption(workflowId);
}

export async function getWorkblockOptionsForActiveTemplatesDropdown() {
	const workflowStore = useWorkflowStore();

	return workflowStore.getWorkblockActionDropdownList();
}

export async function getSelectedWorkblockOptionForActiveTemplatesDropdown(workblockActionId: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const workflowStore = useWorkflowStore();

	return workflowStore.getSelectedWorkblockActionDropdownOption(workblockActionId);
}

export async function getWorkflowFormOptionsForCurrentWorkflowDropdown() {
	const workflowStore = useWorkflowStore();

	return workflowStore.getActiveWorkflowFormsDropdownList();
}

export async function getSelectedWorkflowFormOptionsForCurrentWorkflowDropdown(workflowFormKey: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const workflowStore = useWorkflowStore();

	return await workflowStore.getSelectedActiveWorkflowFormsDropdownList(workflowFormKey);
}

export async function getWorkflowTriggersOptionsForCurrentWorkflowDropdown() {
	const workflowStore = useWorkflowStore();

	return workflowStore.getActiveWorkflowTriggersDropdownList();
}

export async function getSelectedWorkflowTriggersOptionsForCurrentWorkflowDropdown(workflowTriggerKey: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const workflowStore = useWorkflowStore();

	return workflowStore.getSelectedActiveWorkflowTriggersDropdownList(workflowTriggerKey);
}

export async function getWorkflowFormTriggersOptionsForCurrentWorkflowDropdown() {
	const workflowStore = useWorkflowStore();

	return workflowStore.getActiveWorkflowFormTriggersDropdownList();
}

export async function getSelectedWorkflowFormTriggersOptionsForCurrentWorkflowDropdown(workflowTriggerKey: string, cachedOption: DropdownObject) {
	if (cachedOption) return cachedOption;
	const workflowStore = useWorkflowStore();

	return workflowStore.getSelectedActiveWorkflowFormTriggersDropdownList(workflowTriggerKey);
}
