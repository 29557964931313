import gql from 'graphql-tag';

export const GET_ACTIVITIES = gql`
	query GetActivities($first: Int!, $page: Int) {
		getActivities(first: $first, page: $page) {
			data {
				id
				template {
					name
				}
				description
				status
				visibility
				sub_status
				data
				variables
				workflow_data
				forms
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
				updated_at
				updater {
					id
					first_name
					middle_name
					last_name
				}
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_ACTIVE_ACTIVITIES_DROPDOWN = gql`
	query GetActivities($first: Int!, $page: Int) {
		getActivities(first: $first, page: $page) {
			data {
				id
				template {
					name
					type
				}
				description
			}
			paginatorInfo {
				count
				currentPage
				firstItem
				hasMorePages
				lastItem
				lastPage
				perPage
				total
			}
		}
	}
`;

export const GET_ACTIVITY_BY_ID = gql`
	query GetActivityById($activity_id: ID) {
		getActivityById(id: $activity_id) {
			id
			description
			data
			forms
			status
			sub_status
			variables
			visibility
			workflow_data
			workflow_id
			workflow_revision_id
			layout_id
			initiator_id
			initiator_form_request_id
			layout {
				current_version {
					schema
				}
			}
			template {
				id
				name
				description
				type
				settings
			}
			logs {
				id
				action
				log
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
			}
			workflow_process {
				id
				variables
				history
				status_id
			}
			form_requests {
				id
				form_key
				status
				visibility
				form {
					name
					reference_name
					description
				}
				form_version {
					schema
				}
				submissions {
					data
				}
				data
				activated_at
				submitted_at
				closed_at
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
				updated_at
				updater {
					id
					first_name
					middle_name
					last_name
				}
			}
			interactions {
				id
				visibility
				content
				meta
				content_history
				attachments {
					id
					path
					meta
					created_at
					creator {
						id
						first_name
						middle_name
						last_name
					}
				}
				created_at
				creator {
					id
					first_name
					middle_name
					last_name
				}
				updated_at
				updater {
					id
					first_name
					middle_name
					last_name
				}
			}
			events {
				causer {
					first_name
					middle_name
					last_name
					id
				}
				caused_at
				event
				message
				meta
				properties
				id
				subject {
					... on Activity {
						description
						id
						template {
							name
							type
						}
						creator {
							first_name
							middle_name
							last_name
							id
						}
					}
					... on ActivityInteraction {
						content
						creator {
							first_name
							middle_name
							last_name
							id
						}
					}
				}
				visibility
			}
			created_at
			creator {
				id
				first_name
				middle_name
				last_name
			}
			updated_at
			updater {
				first_name
				middle_name
				last_name
				id
			}
		}
	}
`;
