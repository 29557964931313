import { WorkflowObjectPosition, WorkflowObjectType, WorkflowSchema } from '@/types/workflow';
import clone from '@utils/useClone';
import { ActionTypes } from '@modules/workflow/utils/constants';
import { useWorkflowStore } from '@modules/workflow/store';

function calcWidth(element: WorkflowSchema) {
	if (!element.children || element.children?.length === 0) {
		return 1;
	}
	let i;
	let result = 0;
	if (element.children) {
		if (element.type === WorkflowObjectType.BRANCH) {
			for (i = 0; i < element.children.length; i++) {
				const count = calcWidth(element.children[i]);
				if (count) result += count;
			}
			return result;
		} else {
			for (i = 0; i < element.children.length; i++) {
				const count = calcWidth(element.children[i]);
				if (count > result) result = count;
			}
		}
	}
	return result ? result : 0;
}

export function convertJSAddressToPHPAddress(jsAddress: string) {
	jsAddress = jsAddress.replaceAll('[', '.');
	jsAddress = jsAddress.replaceAll('].', '.');
	jsAddress = jsAddress.replaceAll(']', '');

	return jsAddress;
}

function getLeftPos(element: WorkflowSchema): number {
	if (!element.children || element.children?.length === 0) {
		return element.position?.x ?? 0;
	}
	if (element.type === WorkflowObjectType.BRANCH && element.children && element.children.length > 0) {
		return getLeftPos(element.children[0]);
	} else {
		let result = 0;
		if (element.children) {
			for (let i = 0; i < element.children.length; i++) {
				const pos = getLeftPos(element.children[i]);
				if (pos && pos < result) result = pos;
			}
		}
		return result;
	}
}

function calcBranchLength(element: WorkflowSchema) {
	let length = 0;
	let i;
	if (element.children) {
		for (i = 0; i < element.children.length; i++) {
			const children = element.children[i]?.children;
			let subLength = 0;
			if (!children) continue;
			if (!children.find(({ type }) => type === WorkflowObjectType.BRANCH)) {
				subLength = children.length;
			} else {
				for (let j = 0; j < children.length; j++) {
					if (children[j].type === WorkflowObjectType.BRANCH) {
						subLength += calcBranchLength(children[j]);
					} else {
						subLength++;
					}
				}
			}
			if (subLength > length) length = subLength;
		}
	}
	return length ? length + 1 : 1;
}

function calcStepLength(element: WorkflowSchema) {
	const children = element.children;
	let length = 0;
	if (!children || children.length === 0) return 0;
	if (!children.find(({ type }) => type === WorkflowObjectType.BRANCH)) {
		length = children.length;
	} else {
		for (let j = 0; j < children.length; j++) {
			if (children[j].type === WorkflowObjectType.BRANCH) {
				length += calcBranchLength(children[j]);
			} else {
				length++;
			}
		}
	}
	return length;
}

function removePlus(element: WorkflowSchema): WorkflowSchema {
	const children = [];
	if (!element.children || element.children.length === 0) {
		return { ...element } as WorkflowSchema;
	} else {
		let isSetEmptyRoot = false;
		for (let i = 0; i < element.children.length; i++) {
			const child = element.children[i];
			if (child.type === WorkflowObjectType.PLUS) continue;
			const newChild = removePlus(child);
			if (newChild.type === WorkflowObjectType.ROOT && !newChild?.children?.length) {
				if (isSetEmptyRoot) {
					continue;
				} else {
					const newChild = removePlus(child);
					children.push(newChild);
					isSetEmptyRoot = true;
					continue;
				}
			}
			children.push(newChild);
		}
	}

	return { ...element, children } as WorkflowSchema;
}

function makePositionToElements(rootElement: WorkflowSchema, rootPosition: WorkflowObjectPosition, offsetX: number): WorkflowSchema {
	const newElement = clone(rootElement);
	const { x, y } = rootPosition;
	newElement.position = { x: x + offsetX, y: y };
	newElement.length = rootElement.type === WorkflowObjectType.BRANCH ? calcBranchLength(rootElement) : calcStepLength(rootElement);
	if (!newElement.children || newElement.children.length === 0) {
		return newElement as WorkflowSchema;
	}
	if (newElement.type === WorkflowObjectType.BRANCH) {
		const width = calcWidth(newElement);
		const children = newElement.children;
		const childWidthArr = [];
		for (let i = 0; i < children.length; i++) {
			const child = children[i];
			const childWidth = calcWidth(child);
			childWidthArr.push(childWidth);
		}
		const newChildren = [];
		for (let i = 0; i < children.length; i++) {
			const child = children[i];
			let tmp = 0;
			for (let j = 0; j < i; j++) {
				tmp += childWidthArr[j];
			}
			const childOffset = tmp + childWidthArr[i] / 2 - width / 2;
			const newChild = makePositionToElements(child, newElement.position, childOffset);
			newChildren.push(newChild);
		}
		newElement.children = newChildren;
		return newElement;
	} else {
		const children = newElement.children;
		const childHeightArr = [];
		for (let i = 0; i < children.length; i++) {
			const child = children[i];
			const childHeight = child.type === WorkflowObjectType.BRANCH ? calcBranchLength(child) : 1;
			childHeightArr.push(childHeight);
		}
		const newChildren = [];
		for (let i = 0; i < children.length; i++) {
			const child = children[i];
			let tmp = 1;
			for (let j = 0; j < i; j++) {
				tmp += childHeightArr[j];
			}
			const childOffset = tmp;
			if (child.type === WorkflowObjectType.BRANCH) {
				const newChild = makePositionToElements(child, { x: x + offsetX, y: y + childOffset }, 0);
				newChildren.push(newChild);
			} else {
				const newChild = { ...child, position: { x: x + offsetX, y: y + childOffset } };
				newChildren.push(newChild);
			}
		}
		newElement.children = newChildren;
		return newElement;
	}
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
function convertEmptyArraysToObjects(obj, excludeKeys: string[] = []) {
	if (Array.isArray(obj) && obj.length === 0) {
		return {};
	}
	for (const key in obj) {
		if (Array.isArray(obj[key]) && obj[key].length === 0 && excludeKeys.includes(key)) {
			continue; // Skip conversion for excluded keys with empty arrays
		}

		if (Array.isArray(obj[key]) && obj[key].length === 0) {
			obj[key] = {}; // Convert empty arrays to empty objects
		} else if (typeof obj[key] === 'object') {
			if (Array.isArray(obj[key])) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				obj[key].forEach((item, index) => {
					if (typeof item === 'object') {
						convertEmptyArraysToObjects(obj[key][index], excludeKeys);
					}
				});
			} else {
				convertEmptyArraysToObjects(obj[key], excludeKeys);
			}
		}
	}
	return obj;
}

function postAddActionToSchema(workblockId: string, actionId: number, elementAddress: string) {
	const workflowStore = useWorkflowStore();

	if (actionId == ActionTypes.INITIATE_FORM) {
		// add a new item to the workflow forms list;
		workflowStore.workflowForms[elementAddress] = {
			id: workblockId,
			actionId: actionId.toString(),
			parameters: {},
		};
	} else if (actionId == ActionTypes.CREATE_TRIGGER) {
		// add a new item to the workflow forms list;
		workflowStore.workflowTriggers[elementAddress] = {
			id: workblockId,
			actionId: actionId.toString(),
			parameters: {},
		};
	}
}

export { calcBranchLength, calcStepLength, calcWidth, makePositionToElements, removePlus, getLeftPos, convertEmptyArraysToObjects, postAddActionToSchema };
