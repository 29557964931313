export interface AppConfig {
	name: string;
	version: string;
	environment: string;
}

export interface UseRefHistoryRecord<T> {
	snapshot: T;
	timestamp: number;
}

export enum WindowBreakpoint {
	Desktop = 'desktop',
	Laptop = 'laptop',
	Tablet = 'tablet',
	Mobile = 'mobile',
}

export type NameValueObject = {
	name: string;
	value: string | number | null | undefined;
};

export type DropdownObject = {
	label: string;
	value: string | number | null | undefined;
};

export type KeyValuePair<K extends keyof any = string, V = unknown> = Record<K, V>;
interface RecursiveKeyValuePair<K extends keyof any = string, V = string> {
	[key: string]: V | RecursiveKeyValuePair<K, V>;
}
