/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormKitNode } from '@formkit/core';

import {
  getOptionsForActiveActivitiesDropdown,
  getOptionsForActiveFormsDropdown,
  getOptionsForActiveTemplatesDropdown,
  getOptionsForActiveWorkflowsDropdown,
  getSelectedOptionForActiveActivitiesDropdown,
  getSelectedOptionForActiveFormsDropdown,
  getSelectedOptionForActiveTemplatesDropdown,
  getSelectedOptionForActiveWorkflowsDropdown,
  getSelectedWorkblockOptionForActiveTemplatesDropdown,
  getSelectedWorkflowFormOptionsForCurrentWorkflowDropdown,
  getSelectedWorkflowFormTriggersOptionsForCurrentWorkflowDropdown,
  getSelectedWorkflowTriggersOptionsForCurrentWorkflowDropdown,
  getWorkblockOptionsForActiveTemplatesDropdown,
  getWorkflowFormOptionsForCurrentWorkflowDropdown,
  getWorkflowFormTriggersOptionsForCurrentWorkflowDropdown,
  getWorkflowTriggersOptionsForCurrentWorkflowDropdown
} from '@modules/form/utils/helpers';

export function getDropdownOptionLoaders(node: FormKitNode) {
	if (node.type === 'input' && node.props?.type === 'dropdown') {
		if (node.props?.attrs?.meta?.listType === 'dynamic') {
			if (node.props?.attrs?.meta?.dynamicList === 'active_forms') {
				node.props.options = getOptionsForActiveFormsDropdown;
				node.props.optionLoader = getSelectedOptionForActiveFormsDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_workflows') {
				node.props.options = getOptionsForActiveWorkflowsDropdown;
				node.props.optionLoader = getSelectedOptionForActiveWorkflowsDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_templates') {
				node.props.options = getOptionsForActiveTemplatesDropdown;
				node.props.optionLoader = getSelectedOptionForActiveTemplatesDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_activities') {
				node.props.options = getOptionsForActiveActivitiesDropdown;
				node.props.optionLoader = getSelectedOptionForActiveActivitiesDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_workblocks') {
				node.props.options = getWorkblockOptionsForActiveTemplatesDropdown;
				node.props.optionLoader = getSelectedWorkblockOptionForActiveTemplatesDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_workflow_forms') {
				node.props.options = getWorkflowFormOptionsForCurrentWorkflowDropdown;
				node.props.optionLoader = getSelectedWorkflowFormOptionsForCurrentWorkflowDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_workflow_triggers') {
				node.props.options = getWorkflowTriggersOptionsForCurrentWorkflowDropdown;
				node.props.optionLoader = getSelectedWorkflowTriggersOptionsForCurrentWorkflowDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'active_workflow_form_triggers') {
				node.props.options = getWorkflowFormTriggersOptionsForCurrentWorkflowDropdown;
				node.props.optionLoader = getSelectedWorkflowFormTriggersOptionsForCurrentWorkflowDropdown;
			} else if (node.props?.attrs?.meta?.dynamicList === 'empty_list') {
				node.props.options = emptyList;
				node.props.optionLoader = emptyList;
			}
			if (!node.props.emptyMessage) {
				node.props.emptyMessage = 'No options available!';
			}
		}
		if (!node.props.options) {
			node.props.options = [];
		}
	}
}

export function setGlobalElementDefaults(node: FormKitNode) {
	if (node.type === 'input' && (node.props?.type === 'dropdown' || node.props?.type === 'select')) {
		if (!node.props.placeholder) {
			node.props.placeholder = '---Please select---';
		}

		if (!node.props.options) {
			node.props.options = [];
		}
	}
	if (node.type === 'input' && (node.props?.type === 'radio' || (node.props?.type === 'checkbox' && node.props?.attrs?.meta?.type === 'group'))) {
		if (!node.props.options) {
			node.props.options = [];
		}
	}
	if (node.type === 'input' && node.props?.type !== 'toggle' && node.props?.type !== 'stepper' && 'no-empty-params' in (node.at('$root')?.props?.attrs ?? [])) {
		node.hook.input((value, next) => {
			if (value === undefined) return next('');
			return next(value);
		});
	}

	if (node.type === 'input' && node.props?.type === 'toggle') {
		node.hook.input((value, next) => {
			if (value === undefined) return next(node.props.offValue ?? false);
			return next(value);
		});
	}
	if (node.type === 'input' && node.props?.type === 'stepper') {
		console.dir(node);
	}
}

const emptyList = () => {
	return [];
};

export const castNumber = (node: FormKitNode) => {
	if (node.type === 'input' && ['number', 'range', 'stepper'].includes(node.props?.type)) {
		node.hook.input((value, next) => next(value ? parseFloat(value) : value));
	}
};

const isCheckboxAndRadioMultiple = (node: FormKitNode) => (node.props.type === 'checkbox' || node.props.type === 'radio') && node.props.options;

export function addAsteriskToRequiredFieldsPlugin(node: FormKitNode) {
	node.on('created', () => {
		// @ts-ignore
		const isRequired = node.props.parsedRules.some((rule) => rule.name === 'required');
		if (!isRequired) return;

		const isMultiOption = isCheckboxAndRadioMultiple(node);
		const isToggle = node.props.type === 'toggle';

		// if we're going to modify the schema then we need
		// to update the schemaMemoKey so we don't get an
		// invalid cached schema.
		if (node.props?.definition?.schemaMemoKey) {
			node.props.definition.schemaMemoKey = `required_${isMultiOption ? 'multi_' : ''}${node.props.definition.schemaMemoKey}`;
		}

		const schemaFn = node.props?.definition?.schema;
		// @ts-ignore
		node.props.definition.schema = (sectionsSchema = {}) => {
			if (isRequired) {
				if (isMultiOption) {
					sectionsSchema.legend = {
						children: [
							'$label',
							{
								$el: 'span',
								attrs: {
									class: {
										'ml-1': true,
										'text-danger-500': true,
									},
								},
								children: '*',
							},
						],
					};
				} else if (isToggle && node.props.attrs.labelPosition == 'secondary') {
					sectionsSchema.altLabel = {
						children: [
							'$label',
							{
								$el: 'span',
								attrs: {
									class: {
										'ml-1': true,
										'text-danger-500': true,
									},
								},
								children: '*',
							},
						],
					};
				} else {
					sectionsSchema.label = {
						children: [
							'$label',
							{
								$el: 'span',
								attrs: {
									class: {
										'ml-1': true,
										'text-danger-500': true,
									},
								},
								children: '*',
							},
						],
					};
				}
			}
			// @ts-ignore
			return schemaFn(sectionsSchema);
		};
	});
}
