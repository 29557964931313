<template>
	<div class="flex flex-grow w-full">
		<fieldset v-if="simple" class="w-full">
			<div class="w-full grid grid-cols-3 gap-3 sm:grid-cols-6">
				<label
					v-for="option in availableOptions"
					:key="option.value"
					:for="randomKey + '_' + context.node.name + '_' + option.value"
					:class="[
						!option.disabled ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
						'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500',
						currentValue == option.value ? 'bg-primary-600 border-transparent text-white hover:bg-primary-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
						'border rounded-md py-3 px-3 flex flex-grow items-center justify-center text-sm font-medium uppercase',
					]"
					@click="currentValue = option.value"
				>
					<input :id="randomKey + '_' + context.node.name + '_' + option.value" :name="randomKey + '_' + context.node.name" type="radio" :value="option.value" class="sr-only" :checked="currentValue == option.value" />
					<span class="flex flex-row justify-center items-center text-center select-none">{{ option.label }}</span>
				</label>
			</div>
		</fieldset>
		<fieldset v-else>
			<div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
				<label
					v-for="option in availableOptions"
					:key="option.value"
					:for="randomKey + '_' + context.node.name + '_' + option.value"
					class="relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
					:class="{
						'border-gray-300': currentValue !== option.value,
						'border-transparent': currentValue == option.value,
						'opacity-25': option.disabled,
						'cursor-not-allowed': option.disabled,
						'cursor-pointer': !option.disabled,
					}"
					@click="currentValue = option.value"
				>
					<input :id="randomKey + '_' + context.node.name + '_' + option.value" :name="randomKey + '_' + context.node.name" type="radio" :value="option.value" class="sr-only peer" :checked="currentValue == option.value" />
					<span class="flex flex-1 select-none">
						<span class="flex flex-col">
							<span class="block text-sm font-medium text-gray-900">{{ option.label }}</span>
							<span class="mt-1 flex items-center text-sm text-gray-500">{{ option.description }}</span>
							<span v-if="option.extra" class="mt-6 text-sm font-medium text-gray-900">{{ option.extra }}</span>
						</span>
					</span>
					<svg :class="[currentValue !== option.value ? 'invisible' : '', 'h-5 w-5 text-primary-600 select-none']" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
						<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
					</svg>
					<span class="pointer-events-none absolute -inset-px rounded-lg border peer-focus:border-2 border-transparent peer-checked:border-primary-600 select-none" aria-hidden="true"></span>
				</label>
			</div>
		</fieldset>
	</div>
</template>

<script setup lang="ts">
	import { ref, watch } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';
	import { CardOption } from '@/types/form';
	import { nanoid } from 'nanoid';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const props = defineProps<Props>();
	const currentValue = ref(props.context._value);
	const availableOptions = ref<CardOption[]>((props.context.cardOptions as CardOption[]) ?? []);
	const simple = ref<boolean>((props.context.simple as boolean) ?? false);

	const randomKey = nanoid(8);

	watch(
		() => props.context.cardOptions,
		(newValue) => {
			availableOptions.value = newValue as CardOption[];
		},
		{ deep: true },
	);

	watch(currentValue, (newValue) => {
		props.context.node.input(newValue);
	});
</script>
