<template>
	<div class="col-span-12 flex flex-row w-full">
		<TransitionRoot as="template" :show="formMapperOpen">
			<Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="formMapperOpen = false">
				<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4/5 sm:w-full sm:p-6 z-50">
							<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
								<button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="formMapperOpen = false">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</button>
							</div>
							<div class="sm:flex sm:items-start">
								<div class="flex flex-row flex-grow items-center">
									<div class="flex flex-row flex-grow justify-center">
										<div class="text-center sm:ml-4 sm:text-left justify-center">
											<DialogTitle as="h1" class="text-xl font-medium text-gray-900">"{{ formName }}"</DialogTitle>
										</div>
									</div>
								</div>
							</div>

							<div v-if="formSchema" class="flex flex-row w-full">
								<div class="flex flex-col h-full w-full">
									<div class="px-4 transition-all duration-500 transform">
										<div class="bg-white rounded-lg py-2 shadow-box-circle mx-auto transition-all duration-500 transform w-full border-t mt-2">
											<FormKit v-model="formData" type="form" :actions="false" :ignore="true">
												<div class="grid grid-cols-12 gap-x-2 gap-y-4 my-4">
													<FormKitSchema :schema="JSON.parse(changeInputsToVariableMappers(JSON.stringify(formSchema))) as FormKitSchemaNode[]" :data="formData" />
												</div>
											</FormKit>
										</div>
									</div>
								</div>
							</div>
							<DebugSection>
								{{ cleanFormData(formData ?? []) }}
							</DebugSection>
							<div class="sm:flex sm:flex-row-reverse">
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
									@click="saveFormMapping"
								>
									Save
								</button>
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm bg-white border-2 border-red-500 text-red-500"
									@click="formMapperOpen = false"
								>
									Close
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>

		<div v-if="formSchema" class="flex flex-row w-full mb-1 mt-2">
			<div>
				<button
					type="button"
					class="h-9.5 relative inline-flex items-center px-4 py-1 rounded-md border text-sm font-medium focus:z-10 focus:outline-none bg-primary-600 hover:bg-primary-700 text-white border-transparent whitespace-nowrap"
					@click="formMapperOpen = true"
				>
					Open {{ schemaObjectName.toLowerCase() }} mapper</button
				><br />
				<span class="formkit-help text-xs text-gray-500">Open the form mapper to map variables to the newly created activities {{ schemaObjectName.toLowerCase() }}</span>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { FormKitFrameworkContext, FormKitSchemaNode } from '@formkit/core';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { computed, defineProps, ref, watch } from 'vue';
	import { useBuilderStore } from '@modules/builder/store';
	import { FormKitSchemaObject } from '@/types/form';
	import DebugSection from '@components/DebugSection.vue';
	import { FormKitSchema } from '@formkit/vue';
	import { cleanFormData } from '@modules/form/utils/helpers';

	interface Props {
		context: FormKitFrameworkContext;
	}
	const builderStore = useBuilderStore();
	const props = defineProps<Props>();
	const formMapperOpen = ref<boolean>(false);
	const formData = ref(props.context.value);
	const schemaObjectType = ref<string>(String(props?.context?.schemaObjectType));
	const schemaObjectId = ref<string>(String(props?.context?.schemaObjectId));
	const schemaObjectName = ref<string>(String(props?.context?.schemaObjectName));

	// Need to handle if the `schemaObjectType` value dynamically changes
	watch(
		() => props.context.schemaObjectType,
		(newValue) => {
			schemaObjectType.value = String(newValue);
			reloadFormSchema();
		},
	);

	// Need to handle if the `schemaObjectId` value dynamically changes
	watch(
		() => props.context.schemaObjectId,
		(newValue) => {
			schemaObjectId.value = String(newValue);
			reloadFormSchema();
		},
	);

	// This method JUST reloads the schema if the values change
	const reloadFormSchema = async () => {
		if (schemaObjectType.value === 'activityTemplateInitiator' && schemaObjectId.value) {
			//get the template and store it
			await builderStore.getTemplateById(schemaObjectId.value);
		}

		updateInputContext();
	};

	// With the given props lets fetch the schema and modify it on the fly to something that can be used to map!
	const formSchema = computed(() => {
		if (schemaObjectType.value === 'activityTemplateInitiator') {
			return builderStore.activeTemplateInitiatorSchema;
		}

		return [] as FormKitSchemaObject;
	});

	// With the given props lets fetch the name of the form being displayed!
	const formName = computed(() => {
		if (schemaObjectType.value === 'activityTemplateInitiator') {
			return builderStore.activeTemplate?.initiator?.name;
		}

		return '';
	});

	function saveFormMapping() {
		updateInputContext();
		formMapperOpen.value = false;
	}

	function updateInputContext() {
		props.context.node.input(cleanFormData(formData.value ?? []));
	}
	const changeInputsToVariableMappers = (schema: string) => {
		const resultArr = schema.split(',');
		for (const line in resultArr) {
			if (resultArr[line].includes('"$formkit"')) {
				const match = resultArr[line].match(/"\$formkit":"(.*?)"/);
				resultArr[line] = resultArr[line].replace(/"\$formkit":".*"/g, '"$formkit":"variableMapping","originalType":"' + match[1] + '"');
			}
		}
		return resultArr.join(',');
	};
</script>
