// noinspection JSUnusedGlobalSymbols

import dayjs from 'dayjs';

import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { useUserStore } from '@modules/user/store';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getLocalizedDate = (date: string): dayjs.Dayjs => {
	const userStore = useUserStore();

	return dayjs.utc(date).tz(userStore.getUserTimeZone);
};

export const getRelativeFormatFrom = (date: dayjs.Dayjs, from: false | string = false): string => {
	if (!from) {
		return date.fromNow();
	}
	return date.from(getLocalizedDate(from));
};

export const getRelativeFormatTo = (date: dayjs.Dayjs, to: false | string = false): string => {
	if (!to) {
		return date.toNow();
	}
	return date.to(getLocalizedDate(to));
};
