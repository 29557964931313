import { FormKitListStatement, FormKitSchemaAttributes, FormKitSchemaMeta } from '@formkit/core';
import { FormKitBaseProps } from '@formkit/inputs';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export type Class = string | Record<string, boolean> | (string | Record<string, boolean>)[];
export type Style = string | Record<string, boolean> | Record<string, string> | (string | Record<string, boolean>)[];

export type FormKitSchema = FormKitSchemaDOMNode | FormKitSchemaComponent | FormKitSchemaFormKit;
export type FormKitSchemaKnownAttributes = {
	class?: Class;
	style?: Style;
} & FormKitSchemaAttributes;

export type FormKitSchemaKnownMetaAttributes = {
	type?: string;
	note?: string;
	conditionals?: Conditional[];
} & FormKitSchemaMeta &
	Partial<Expressions>;

export type FormKitSchemaKnownProps = {
	[index: string]: any;
};

export type FormKitSchemaObject = {
	attrs?: FormKitSchemaKnownAttributes;
	meta?: FormKitSchemaKnownMetaAttributes;
	children?: string | FormKitSchemaObject[] | FormKitSchemaCondition;
} & Partial<FormKitSchemaDOMNode> &
	Partial<FormKitSchemaComponent> &
	Partial<FormKitSchemaFormKit> &
	Partial<FormKitSchemaCondition> &
	Partial<FormKitSchemaInputProps | Record<string, string>> &
	Partial<FormKitSchemaBaseProps>;

//Record<string, any>;

export type FormKitSchemaBaseProps = {
	id: string;
	name: string;
	key: string;
	if?: string;
	for?: FormKitListStatement;
	bind?: string;
	props?: FormKitSchemaKnownProps;
	__raw__sectionsSchema: Record<string, FormKitSchemaObject>;
	sectionsSchema: Record<string, FormKitSchemaObject>;
} & Partial<FormKitBaseProps>;

/**
 * Type guard for schema objects.
 *
 * @param schema - returns `true` if the node is a schema node but not a string
 * or conditional.
 *
 * @returns `boolean`
 *
 * @public
 */
export function isSchemaObject(schema: Partial<FormKitSchemaObject>): schema is FormKitSchemaObject {
	return typeof schema === 'object' && ('$el' in schema || '$cmp' in schema || '$formkit' in schema);
}

export type FormSubmissionObject = Record<string, any>;

export type FormKitIcons =
	| 'mail'
	| 'phone'
	| 'lock'
	| 'dollar'
	| 'check'
	| 'arrowDown'
	| 'arrowUp'
	| 'close'
	| 'select'
	| 'spinner'
	| 'star'
	| 'trash'
	| 'date'
	| 'left'
	| 'right'
	| 'checkboxDecorator'
	| 'fileItem'
	| 'fileRemove'
	| 'radioDecorator';

export type FormKitSchemaInputProps = {
	innerClass: Class;
	outerClass: Class;
	inputClass: Class;
	inputType: string;
	validation: string | Array<[rule: string, ...args: any]>;
	options: null | FormKitSelectOptions[];
	prefixIcon: FormKitIcons;
	ratingIcon: FormKitIcons;
	selectionRemovable: boolean;
};

export type FormKitSchemaCondition = {
	if: string;
	then: FormKitSchemaObject | FormKitSchemaObject[];
	else?: FormKitSchemaObject | FormKitSchemaObject[];
};

export type FormKitSchemaComponent = {
	$cmp: string;
};
export type FormKitSchemaDOMNode = {
	$el: string | null;
};
export type FormKitSchemaFormKit = {
	$formkit: string;
};

export type FormKitSelectOptions = {
	label: string;
	value: string;
	attrs?: {
		disabled: boolean;
	};
};

export enum ElementType {
	Field = 'field',
	Static = 'static',
}

export type FormElement = {
	id: number;
	name: string;
	type: ElementType;
	icon?: string | IconDefinition;
	description?: string;
	schema: FormKitSchemaObject;
};

export enum FormType {
	Mixed = 1,
	Standalone = 2,
	Integrated = 3,
}

export type CreateFormType = {
	name?: string;
	reference_name?: string;
	description?: string;
	type_id?: number;
};

export enum ElementAlign {
	LEFT = 1,
	CENTER = 2,
	RIGHT = 3,
}

export type UnstructuredFormOutput = {
	[key: string]: unknown;
};

export type NestedLocation = {
	index: number | NestedLocation;
	insertAt: number | null;
	elementAddress: string;
};

export const enum ConditionalType {
	AND = 'AND',
	OR = 'OR',
}

export const enum FieldConditions {
	EMPTY = 'EMPTY',
	NOT_EMPTY = 'NOT_EMPTY',
	GREATER_THAN = 'GREATER_THAN',
	LESS_THAN = 'LESS_THAN',
	GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
	LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
	CONTAINS = 'CONTAINS',
	EQUAL_TO = 'EQUAL_TO',
	STRICT_EQUAL_TO = 'STRICT_EQUAL_TO',
	NOT_EQUAL_TO = 'NOT_EQUAL_TO',
	STRICT_NOT_EQUAL_TO = 'STRICT_NOT_EQUAL_TO',
	TRUE = 'TRUE',
	FALSE = 'FALSE',
	STARTS_WITH = 'STARTS_WITH',
	ENDS_WITH = 'ENDS_WITH',
	MATCHES_FIELD = 'MATCHES_FIELD',
	BETWEEN = 'BETWEEN',
	ADDITION = 'ADDITION',
	SUBTRACTION = 'SUBTRACTION',
	MULTIPLICATION = 'MULTIPLICATION',
	DIVISION = 'DIVISION',
	EQUALS = 'EQUALS',
	POWER_OF = 'POWER_OF',
	MODULUS = 'MODULUS',
	ABSOLUTE_VALUE = 'ABSOLUTE_VALUE',
}

export const FieldConditionsLabel = {
	[FieldConditions.EMPTY]: 'Is empty',
	[FieldConditions.NOT_EMPTY]: 'Is not empty',
	[FieldConditions.GREATER_THAN]: 'Is greater than [ > ]',
	[FieldConditions.LESS_THAN]: 'Is less than [ < ]',
	[FieldConditions.GREATER_THAN_OR_EQUAL]: 'Is greater than or equals [ >= ]',
	[FieldConditions.LESS_THAN_OR_EQUAL]: 'Is greater than or equals [ <= ]',
	[FieldConditions.CONTAINS]: 'Contains',
	[FieldConditions.EQUAL_TO]: 'Equal to [ == ]',
	[FieldConditions.STRICT_EQUAL_TO]: 'Strict equal to [ === ]',
	[FieldConditions.NOT_EQUAL_TO]: 'Not equal too [ != ]',
	[FieldConditions.STRICT_NOT_EQUAL_TO]: 'Strict not equal to [ !== ]',
	[FieldConditions.TRUE]: 'Is true',
	[FieldConditions.FALSE]: 'Is false',
	[FieldConditions.STARTS_WITH]: 'Starts with',
	[FieldConditions.ENDS_WITH]: 'Ends with',
	[FieldConditions.MATCHES_FIELD]: 'Matches other field',
	[FieldConditions.BETWEEN]: 'Is between',
	[FieldConditions.ADDITION]: 'Add',
	[FieldConditions.SUBTRACTION]: 'Subtract',
	[FieldConditions.MULTIPLICATION]: 'Multiply by',
	[FieldConditions.DIVISION]: 'Divide by',
	[FieldConditions.EQUALS]: 'Equals',
	[FieldConditions.POWER_OF]: 'To the power of',
	[FieldConditions.MODULUS]: 'Mod',
	[FieldConditions.ABSOLUTE_VALUE]: 'abs',
};
export const FieldConditionsOperator = {
	[FieldConditions.EMPTY]: '== ""',
	[FieldConditions.NOT_EMPTY]: '!= ""',
	[FieldConditions.GREATER_THAN]: '>',
	[FieldConditions.LESS_THAN]: '<',
	[FieldConditions.GREATER_THAN_OR_EQUAL]: '>=',
	[FieldConditions.LESS_THAN_OR_EQUAL]: '<=',
	[FieldConditions.CONTAINS]: '',
	[FieldConditions.EQUAL_TO]: '==',
	[FieldConditions.STRICT_EQUAL_TO]: '===',
	[FieldConditions.NOT_EQUAL_TO]: '!=',
	[FieldConditions.STRICT_NOT_EQUAL_TO]: '!==',
	[FieldConditions.TRUE]: '== true',
	[FieldConditions.FALSE]: '== false',
	[FieldConditions.STARTS_WITH]: '',
	[FieldConditions.ENDS_WITH]: '',
	[FieldConditions.MATCHES_FIELD]: '==',
	[FieldConditions.BETWEEN]: '',
	[FieldConditions.ADDITION]: '+',
	[FieldConditions.SUBTRACTION]: '−',
	[FieldConditions.MULTIPLICATION]: '×',
	[FieldConditions.DIVISION]: '÷',
	[FieldConditions.EQUALS]: '=',
	[FieldConditions.POWER_OF]: 'Xⁿ',
	[FieldConditions.MODULUS]: '%',
	[FieldConditions.ABSOLUTE_VALUE]: '|x|',
};

export type Conditional = {
	id: string;
	type: ConditionalType;
	conditions: ConditionalCondition[];
};

export type ConditionalCondition = {
	id: string;
	type: ConditionalType;
	field?: ConditionalField;
	condition?: string;
	value?: string | string[] | number | number[] | boolean;
	lower_value?: string | string[] | number | number[];
	upper_value?: string | string[] | number | number[];
};

export type ConditionalField = {
	id: string;
	label?: string;
	type: string;
};

export type CardOption = {
	label: string;
	value: unknown;
	disabled?: boolean;
	title?: string;
	description?: string;
	extra?: string;
};

export const enum ExpressionVariableType {
	GLOBAL = 'GLOBAL',
	LOCAL = 'LOCAL',
	STATIC = 'STATIC',
}

export const ExpressionVariableTypeLabel = {
	[ExpressionVariableType.GLOBAL]: 'Global variable',
	[ExpressionVariableType.LOCAL]: 'Local variable',
	[ExpressionVariableType.STATIC]: 'Static value',
};

export const enum ExpressionType {
	EXPRESSION = 'EXPRESSION',
	EQUATION = 'EQUATION',
	CONDITIONAL = 'CONDITIONAL',
	FUNCTION = 'FUNCTION',
}

export type Expressions = {
	expressions: Expression[];
	return_type: string;
	return_value: string;
};

export type ExpressionVariable = {
	label?: string;
	type?: ExpressionVariableType;
	variable?: string;
};

export type Expression = {
	id: string;
	type: ExpressionType;
	variable: ExpressionVariable;
	equation?: ExpressionEquation;
	conditional?: Conditional;
	function?: ExpressionFunction;
};

export type ExpressionEquation = {
	id: string;
	left_type: ExpressionType | undefined;
	left: string | ExpressionEquation | undefined;
	operator: string | undefined;
	right_type: ExpressionType | undefined;
	right: string | ExpressionEquation | undefined;
};

export type ExpressionFunction = {
	id: string;
	function: number | undefined;
	options: ExpressionFunctionOptions | undefined;
	arguments: ExpressionFunctionArgument | undefined;
};

export type ExpressionFunctionOptions = {
	[key: string]: string;
};

export type ExpressionFunctionArgument = {
	type: ExpressionType | undefined;
	value: string | undefined;
};
