import gql from 'graphql-tag';

export const GET_LOGGED_IN_USER = gql`
	query GetLoggedInUser {
		me {
			id
			global_id
			email
			first_name
			middle_name
			last_name
			is_active
			email_verified_at
			anti_phishing_code
			two_factor_type
			profile {
				avatar
				preferred_name
				work_contact_number
				work_ext_number
				mobile_contact_number
				home_contact_number
				unit_number
				updated_at
				created_at
				website
				bitbucket
				github
				facebook
				twitter
				linkedin
				position
				date_of_birth
				country
				post_code
				state
				city
				street_type
				street_name
				street_number
				id
			}
			preferences {
				id
				locale
				time_zone
				created_at
				updated_at
			}
			organisation {
				id
				data
				created_at
				updated_at
			}
			password_changed_at
			created_at
			updated_at
		}
	}
`;
