import { ApolloClient, ApolloLink, createHttpLink, fromPromise, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { ApiConfig } from '@/config/api';
import { AppConfig } from '@/config/app';
import { PusherLink, PusherService } from '@/services/usePusher';
import { getAuthHeaders } from '@utils/useAuth';
import { useAuthStore } from '@modules/auth/store';

// HTTP connection to the API
const httpLink = createHttpLink({
	// You should use an absolute URL here
	uri: ApiConfig.graphql_url,
});

const pusherLink = new PusherLink({
	pusher: PusherService,
});

const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers, this is done as middleware to make sure we are using the CURRENT token
	operation.setContext({
		headers: getAuthHeaders(),
	});

	return forward(operation);
});

// Error handler that will attempt to refresh when the network error is an unauthenticated response
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	if (graphQLErrors) {
		const authStore = useAuthStore();
		for (const err of graphQLErrors) {
			if (err?.message == 'Unauthenticated.') {
				fromPromise(
					authStore.refresh().then(() => {
						// Modify the operation context with a new token
						operation.setContext({
							headers: {
								...operation.getContext().headers,
								...getAuthHeaders(),
							},
						});
						// Retry the request
						return forward(operation);
					}),
				);
			} else {
				console.error(err);
			}
		}
	}

	// To retry on network errors, we recommend the RetryLink
	// instead of the onError link. This just logs the error.
	if (networkError) {
		console.log(`[Network error]: ${networkError}`);
	}
});

// Combine the two links to work together
const link = ApolloLink.from([pusherLink, authMiddleware, errorLink, httpLink]);

// Create the apollo client
export const apolloClient = new ApolloClient({
	link: link,
	cache: new InMemoryCache(),
	credentials: 'include',
	name: AppConfig.name,
	version: AppConfig.version,
});

export async function clearApolloStore() {
	await apolloClient.clearStore();
}
