<template>
	<RouterView />
</template>

<script setup lang="ts">
	import { onBeforeMount, onMounted, onUnmounted, provide } from 'vue';
	import { DefaultApolloClient } from '@vue/apollo-composable';
	import { apolloClient } from '@/services/useApollo';
	import { useAuthStore } from '@modules/auth/store';
	import { useUserStore } from '@modules/user/store';
	import { useBaseStore } from '@store';
	import { useRoute, useRouter } from 'vue-router';

	const route = useRoute();
	const router = useRouter();

	const attachWindowEvents = async () => {
		window.addEventListener('keydown', handleGlobalKeydown);
		window.addEventListener('resize', handleWindowWasResized);
	};
	const detachWindowEvents = async () => {
		window.removeEventListener('keydown', handleGlobalKeydown);
		window.removeEventListener('resize', handleWindowWasResized);
	};

	const baseStore = useBaseStore();

	const handleGlobalKeydown = async (event: KeyboardEvent) => {
		if (event.ctrlKey && event.altKey && event.code === 'Space') {
			event.stopPropagation();
			const authStore = useAuthStore();
			if (authStore.isAuthenticated) {
				await openGlobalSearch();
			}
		}
	};

	const openGlobalSearch = async () => {
		baseStore.globalSearchOpen = true;
	};

	const handleWindowWasResized = async () => {
		baseStore.windowHeight = window.innerHeight;
		baseStore.windowWidth = window.innerWidth;
	};

	onBeforeMount(async () => {
		if (!route.meta.embeddedRoute) {
			provide(DefaultApolloClient, apolloClient);
		}
	});

	onMounted(async () => {
		if (!route.meta.embeddedRoute) {
			const authStore = useAuthStore();
			const userStore = useUserStore();
			if (authStore.isAuthenticated) {
				await userStore.getLoggedInUser();
				userStore.joinOrganisationBroadcastChannel().then(() => {});
				userStore.joinUserBroadcastChannel().then(() => {});
			}
			await attachWindowEvents();
			await handleWindowWasResized();
			if (!authStore.isAuthenticated) {
				await router.push({
					name: 'login',
				});
			}
		}
	});

	onUnmounted(async () => {
		if (!route.meta.embeddedRoute) {
			await detachWindowEvents();
		}
	});
</script>
