/* eslint-disable */
// An Apollo Link for using graphql-pro's Pusher subscriptions
//
// @example Adding subscriptions to a HttpLink
//   // Load Pusher and create a client
//   import Pusher from "pusher-js"
//   var pusherClient = new Pusher("your-app-key", { cluster: "us2" })
//
//   // Build a combined link, initialize the client:
//   const pusherLink = new PusherLink({pusher: pusherClient})
//   const link = ApolloLink.from([authLink, pusherLink, httpLink])
//   const client = new ApolloClient(link: link, ...)
//
// @example Building a subscription, then subscribing to it
//  subscription = client.subscribe({
//    variables: { room: roomName},
//    query: gql`
//      subscription MessageAdded($room: String!) {
//        messageWasAdded(room: $room) {
//          room {
//            messages {
//              id
//              body
//            }
//          }
//        }
//      }
//       `
//   })
//
//   subscription.subscribe({ next: ({data, errors}) => {
//     // Do something with `data` and/or `errors`
//   }})
//
// noinspection TypeScriptRedundantGenericType,JSUnresolvedReference

import { ApolloLink, FetchResult, NextLink, Observable, Observer, Operation } from '@apollo/client/core';
import Pusher from 'pusher-js';
import { SocketsConfig } from '@/config/sockets';
import { ApiConfig } from '@/config/api';
import { ChannelAuthorizationCallback, ChannelAuthorizationRequestParams } from 'pusher-js/src/core/auth/options';
import { getAuthHeaders } from '@utils/useAuth';
import { useAuthStore } from '@modules/auth/store';

type RequestResult = FetchResult<{ [key: string]: any }, Record<string, any>, Record<string, any>>;

type Subscription = {
	closed: boolean;
	unsubscribe(): void;
};

export class PusherLink extends ApolloLink {
	pusher: Pusher;

	constructor(options: { pusher: Pusher }) {
		super();
		// Retain a handle to the Pusher client
		this.pusher = options.pusher;
	}

	request(operation: Operation, forward: NextLink): Observable<RequestResult> {
		const subscribeObservable = new Observable<RequestResult>((_observer: any) => {});
		// Capture the super method
		const prevSubscribe = subscribeObservable.subscribe.bind(subscribeObservable);
		// Override subscribe to return an `unsubscribe` object, see
		// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212
		subscribeObservable.subscribe = (observerOrNext: Observer<RequestResult> | ((value: RequestResult) => void), onError?: (error: any) => void, onComplete?: () => void): Subscription => {
			// Call super
			// @ts-ignore
			prevSubscribe(observerOrNext, onError, onComplete);

			const observer = getObserver(observerOrNext, onError, onComplete);

			let subscriptionChannel: string;

			forward(operation).subscribe({
				next: (data: any) => {
					// If the operation has the subscription channel, it's a subscription
					subscriptionChannel = data?.extensions?.lighthouse_subscriptions.channel ?? null;

					// No subscription found in the response, pipe data through
					if (!subscriptionChannel) {
						observer.next(data);
						observer.complete();

						return;
					}

					this.subscribeToChannel(subscriptionChannel, observer);
				},
			});

			// Return an object that will unsubscribe _if_ the query was a subscription.
			return {
				closed: false,
				unsubscribe: () => {
					subscriptionChannel && this.unsubscribeFromChannel(subscriptionChannel);
				},
			};
		};

		return subscribeObservable;
	}

	subscribeToChannel(subscriptionChannel: string, observer: { next: Function; complete: Function }) {
		return this.pusher.subscribe(subscriptionChannel).bind('lighthouse-subscription', (payload: any) => {
			if (!payload.more) {
				this.unsubscribeFromChannel(subscriptionChannel);
				observer.complete();
			}

			const result = payload.result;

			if (result) {
				observer.next(result);
			}
		});
	}

	unsubscribeFromChannel(subscriptionChannel: string) {
		this.pusher.unsubscribe(subscriptionChannel);
	}
}

// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L347-L361
function getObserver<T>(observerOrNext: Function | Observer<T>, onError?: (e: Error) => void, onComplete?: () => void) {
	if (typeof observerOrNext === 'function') {
		// Duck-type an observer
		return {
			next: (v: T) => observerOrNext(v),
			error: (e: Error) => onError && onError(e),
			complete: () => onComplete && onComplete(),
		};
	} else {
		// Make an object that calls to the given object, with safety checks
		return {
			next: (v: T) => observerOrNext.next && observerOrNext.next(v),
			error: (e: Error) => observerOrNext.error && observerOrNext.error(e),
			complete: () => observerOrNext.complete && observerOrNext.complete(),
		};
	}
}

export const PusherService = new Pusher(SocketsConfig.socketsKey, {
	cluster: SocketsConfig.socketsCluster,
	forceTLS: true,
	wsHost: SocketsConfig.socketsHost,
	wsPort: SocketsConfig.socketsPort ?? 6001,
	enabledTransports: ['ws', 'wss'],
	disableStats: true,
	channelAuthorization: {
		transport: 'jsonp',
		endpoint: ApiConfig.broadcast_auth_url,
		customHandler: async (params: ChannelAuthorizationRequestParams, callback: ChannelAuthorizationCallback) => {
			const { socketId, channelName } = params;

			const authStore = useAuthStore();
			if (authStore.isAuthenticated) {
				// @ts-ignore
				callback('User is not authenticated');
			}

			try {
				const response = await fetch(channelName.startsWith('private-lighthouse') ? ApiConfig.subscription_auth_url : ApiConfig.broadcast_auth_url, {
					method: 'post',
					body: JSON.stringify({
						socket_id: socketId,
						channel_name: channelName,
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						...getAuthHeaders(),
					} as HeadersInit,
				});

				callback(null, await response.json());
			} catch (e) {
				// @ts-ignore
				callback(e, null);
			}
		},
	},
});

export default PusherService;
