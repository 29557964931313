<template>
	<div class="flex flex-row items-center justify-center space-x-1.5">
		<input
			v-for="index in digits"
			:id="'digit_' + index"
			ref="digitRefs"
			:key="index"
			type="number"
			maxlength="1"
			:class="context.classes.digit"
			:value="otpInput[index - 1] || ''"
			@input.stop="handleInput(index - 1, $event)"
			@focus="handleFocus"
			@keyup.delete="handleBackspace"
			@paste.prevent.stop="handlePaste"
		/>
	</div>
</template>

<script setup lang="ts">
	import { defineProps, onMounted, ref } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const props = defineProps<Props>();

	const digits = Number(props.context.digits);
	const otpInput = ref(props.context.value || '');
	const digitRefs = ref<HTMLInputElement[] | null>([]);

	/**
	 * Handle input, advancing or retreating focus.
	 */
	function handleInput(index: number, e: InputEvent) {
		const prev = otpInput.value;

		if (otpInput.value.length <= index) {
			// If this is a new digit
			otpInput.value = '' + otpInput.value + (e.target as HTMLInputElement).value;
		} else {
			// If this digit is in the middle somewhere, cut the string into two
			// pieces at the index, and insert our new digit in.
			otpInput.value = '' + otpInput.value.substring(0, index) + (e.target as HTMLInputElement).value + otpInput.value.substring(index + 1);

			// if we already have the right amount of digits then
			if (otpInput.value.length > digits) {
				otpInput.value = '' + otpInput.value.substring(0, digits);
			}
		}

		// Get all the digit inputs
		const inputs = (e.target as HTMLInputElement)?.parentElement?.querySelectorAll('input');

		if (inputs) {
			if (index < digits - 1 && otpInput.value.length >= prev.length) {
				// If this is a new input and not at the end, focus the next input
				inputs.item(index + 1).focus();
			} else if (index > 0 && otpInput.value.length < prev.length) {
				// in this case we deleted a value, focus backwards
				inputs.item(index - 1).focus();
			}
		}

		if (otpInput.value.length === digits) {
			// If our input is complete, commit the value.
			props.context.node.input(otpInput.value);
		} else if (otpInput.value.length < digits && props.context.value !== '') {
			// If our input is incomplete, it should have no value.
			props.context.node.input('');
		}
	}

	/**
	 * On focus, select the text in our input.
	 */
	function handleFocus(e: InputEvent) {
		(e.target as HTMLInputElement).select();
	}

	/**
	 * On backspace ona an empty input move the next input or the first input.
	 */
	function handleBackspace(e: InputEvent) {
		if ((e.target as HTMLInputElement).value == '') {
			const inputs = (e.target as HTMLInputElement)?.parentElement?.querySelectorAll('input');
			if (inputs) {
				// Focus on the last character
				let found = false;
				for (let i = 5; i >= 0; i--) {
					if (inputs.item(i).value != '') {
						inputs.item(i).focus();
						found = true;
						break;
					}
				}
				if (!found) {
					inputs.item(0).focus();
				}
			}
		}
	}

	/**
	 * Handle the paste event.
	 */
	function handlePaste(e: ClipboardEvent) {
		const paste = e?.clipboardData?.getData('text').toString().match(/\d/g)?.join('');
		if (paste && paste !== '') {
			// If it is the right length, paste it.
			otpInput.value = paste.substring(0, digits);
			const inputs = (e.target as HTMLInputElement)?.parentElement?.querySelectorAll('input');
			if (inputs) {
				// Focus on the last character
				inputs.item(otpInput.value.length - 1).focus();
			}
			props.context.node.input(otpInput.value);
		}
	}

	/**
	 * Focus the first element on mount so that someone can easily paste/type in their code.
	 */
	onMounted(() => {
		if (digitRefs.value) {
			for (const element of digitRefs.value) {
				if (element.id === 'digit_1') {
					element.focus();
				}
			}
		}
	});
</script>

<style scoped>
	.formkit-inner {
		box-shadow: none;
	}

	.formkit-inner:focus-within {
		box-shadow: none;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
</style>
