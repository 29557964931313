<template>
	<div :class="props.context.classes.outer">
		<div :class="props.context.classes.wrapper">
			<div
				class="flex flex-row pb-1"
				:class="{
					'justify-between': props.context.label,
					'justify-end': !props.context.label,
					'items-center': props.context.label,
				}"
			>
				<component :is="context.slots.label" v-if="context.slots.label" />
				<label v-if="!context.slots.label && props.context.label" :class="props.context.classes.label + ' mt-1'" :for="props.context.id">{{ props.context.label }}</label>
				<button
					class="h-7 w-7 flex self-stretch grow-0 rounded-full shrink-0 border-gray-300 justify-center items-center [&>svg]:w-full [&>svg]:max-w-[0.95em] [&>svg]:max-h-[0.95em] [&>svg]:m-auto"
					:class="{
						'bg-gray-200': variable.type == 'STATIC',
						'hover:bg-gray-300': variable.type == 'STATIC',
						'bg-primary-600': variable.type !== 'STATIC',
						'hover:bg-primary-500': variable.type !== 'STATIC',
						'text-white': variable.type !== 'STATIC',
					}"
					:disabled="props.context.disabled ?? false"
					title="Select between static input and variable based input"
					@click.prevent.stop="variable.type = variable.type == 'STATIC' ? 'VARIABLE' : 'STATIC'"
				>
					<FontAwesomeIcon :icon="faValueAbsolute" class="h-7 w-7 ml-2" aria-hidden="true" />
				</button>
			</div>

			<!-- need to pivot here between static and variable selection -->
			<FormKit
				v-if="variable.type == 'STATIC'"
				:id="props.context.id"
				v-model="variable.value"
				v-bind="props.context.attrs"
				:name="props.context.name"
				:type="originalType"
				:ignore="true"
				:disabled="props.context.disabled ?? false"
				:readonly="props.context.readonly ?? false"
			/>
			<div v-else :class="props.context.classes.inner">
				<button :class="props.context.classes.input + ' text-left'" @click.prevent.stop="variableSelectorOpen = true">
					<span v-if="variable.value == ''" class="italic text-gray-300">click to select a variable</span>
					<span v-else class="text-gray-300">{{ variable.value }}</span>
				</button>
			</div>
		</div>
		<component :is="context.slots.help" v-if="context.slots.help" />
		<div v-if="!context.slots.help && props.context.help" :id="'help-' + props.context.id" :class="props.context.classes.help">{{ props.context.help }}</div>
		<component :is="context.slots.messages" v-if="context.slots.messages" />
		<ul v-if="!context.slots.messages && props.context.messages" :class="props.context.classes.messages">
			<component :is="context.slots.message" v-for="message in props.context.messages" v-if="context.slots.message" v-bind="message" :key="message.key" />
			<li v-for="message in props.context.messages" v-show="message.visible" :id="props.context.id + '-' + message.key" :key="message.key" :class="props.context.classes.message" :data-message-type="message.type">
				{{ message.value }}
			</li>
		</ul>
		<TransitionRoot as="template" :show="variableSelectorOpen">
			<Dialog as="div" class="fixed z-60 inset-0 overflow-y-auto" @close="variableSelectorOpen = false">
				<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
						<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<!-- This element is to trick the browser into centering the modal contents. -->
					<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-1/2 sm:w-full sm:p-6 z-60">
							<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
								<button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="variableSelectorOpen = false">
									<span class="sr-only">Close</span>
									<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
								</button>
							</div>
							<div class="sm:flex sm:items-start">
								<div class="flex flex-row flex-grow items-center">
									<div class="flex flex-row flex-grow justify-center">
										<div class="text-center sm:ml-4 sm:text-left justify-center">
											<DialogTitle as="h1" class="text-xl font-medium text-gray-900">Variable Selector</DialogTitle>
										</div>
									</div>
								</div>
							</div>

							<div class="flex flex-row w-full">
								<FormKit
									v-model="variable.type"
									:ignore="true"
									type="togglebuttons"
									name="type"
									label="Select variable type"
									:options="[
										{
											label: 'Pick from list',
											value: 'VARIABLE',
										},
										{
											label: 'Custom selector',
											value: 'CUSTOM',
										},
									]"
								/>
							</div>
							<div class="flex flex-row w-full">
								<FormKit v-if="variable.type == 'VARIABLE'" v-model="variable.value" :ignore="true" type="dropdown" label="Select variable type" :options="availableVariables" />
								<FormKit v-else v-model="variable.value" :ignore="true" type="text" label="Enter custom variable address" />
							</div>

							<div class="sm:flex sm:flex-row-reverse">
								<button
									type="button"
									class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
									@click="variableSelectorOpen = false"
								>
									Done
								</button>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</TransitionRoot>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, ref, watch } from 'vue';
	import { FormKitFrameworkContext } from '@formkit/core';
	import { faValueAbsolute, faXmark } from '@fortawesome/pro-light-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { useBuilderStore } from '@modules/builder/store';

	interface Props {
		context: FormKitFrameworkContext;
	}

	const props = defineProps<Props>();
	const originalType = ref<string>(String(props?.context?.originalType ?? 'text'));
	const variableSelectorOpen = ref<boolean>(false);
	const workflowStore = useWorkflowStore();
	const builderStore = useBuilderStore();

	const variable = ref(
		typeof props.context.value === 'object' && props.context.value !== null
			? props.context.value
			: {
					type: 'STATIC',
					value: props.context.value ?? '',
				},
	);

	const availableVariables = computed(() => {
		const variables = [];
		let activityVariables = [];
		for (const variable of builderStore.variables) {
			activityVariables = [...activityVariables, ...getVariableFromActivityVariables(variable)];
		}
		variables.push({
			group: 'Activity variables',
			options: activityVariables,
		});
		let workflowVariables = [];
		if (workflowStore.workflowVariables) {
			for (const variableIndex in workflowStore.workflowVariables) {
				workflowVariables = [...workflowVariables, ...getVariableFromWorkflowVariables(workflowStore.workflowVariables[variableIndex])];
			}
		}
		variables.push({
			group: 'Workflow variables',
			options: workflowVariables,
		});
		return variables;
	});

	const getVariableFromWorkflowVariables = (variable) => {
		let returnVariables = [];
		if ((variable.children && Array.isArray(variable.children) && variable.children.length > 0) || (variable.children && typeof variable.children === 'object' && Object.keys(variable.children).length > 0)) {
			for (const variableIndex in variable.children) {
				returnVariables = [...returnVariables, ...getVariableFromWorkflowVariables(variable.children[variableIndex])];
			}
		} else {
			returnVariables.push({
				id: variable.id,
				label: variable.description ? variable.name + ' (' + variable.description + ')' : variable.name,
				value: variable.name,
			});
		}

		return returnVariables;
	};

	const getVariableFromActivityVariables = (variable) => {
		let returnVariables = [];
		if (variable.children && Array.isArray(variable.children) && variable.children.length > 0) {
			for (const childVariable of variable.children) {
				returnVariables = [...returnVariables, ...getVariableFromActivityVariables(childVariable)];
			}
		} else {
			returnVariables.push({
				id: variable.id,
				label: variable.description ? variable.name + ' (' + variable.description + ')' : variable.name,
				value: variable.name,
			});
		}

		return returnVariables;
	};

	watch(
		() => variable.value,
		(newValue) => {
			props.context.node.input(newValue);
		},
		{ immediate: true, deep: true },
	);
</script>
