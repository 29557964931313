import { RouteRecordRaw } from 'vue-router';

export const guestRoutes: RouteRecordRaw[] = [];

export const authRoutes: RouteRecordRaw[] = [
	{
		path: '/builder',
		name: 'builder.index',
		component: () => import('./layouts/index.vue'),
	},
	{
		path: '/builder/create',
		name: 'builder.create',
		component: () => import('./layouts/index.vue'),
	},
	{
		path: '/builder/:template_id',
		name: 'builder.show',
		component: () => import('./layouts/show.vue'),
		children: [
			{
				path: 'settings',
				name: 'builder.settings',
				component: () => import('./pages/TemplateSettings.vue'),
				children: [
					{
						path: 'general',
						name: 'builder.settings.general',
						component: () => import('./partials/GeneralSettings.vue'),
						meta: {
							title: 'General',
						},
					},
					{
						path: 'security',
						name: 'builder.settings.security',
						component: () => import('./partials/SecuritySettings.vue'),
						meta: {
							title: 'Security',
						},
					},
					{
						path: 'interaction',
						name: 'builder.settings.interaction',
						component: () => import('./partials/InteractionSettings.vue'),
						meta: {
							title: 'Interactions',
						},
					},
					{
						path: 'notifications',
						name: 'builder.settings.notifications',
						component: () => import('./partials/NotificationSettings.vue'),
						meta: {
							title: 'Notifications',
						},
					},
				],
				meta: {
					title: 'Settings',
				},
			},
			{
				path: 'layout',
				name: 'builder.layout',
				component: () => import('./pages/TemplateLayout.vue'),
				meta: {
					title: 'Layout',
				},
			},
			{
				path: 'initiation',
				name: 'builder.initiation',
				component: () => import('./pages/TemplateInitiation.vue'),
				meta: {
					title: 'Initiation',
				},
			},

			{
				path: 'workflows',
				name: 'builder.workflow',
				component: () => import('./pages/TemplateWorkflows.vue'),
				meta: {
					title: 'Workflows',
				},
			},
			{
				path: 'forms',
				name: 'builder.form',
				component: () => import('./pages/TemplateForms.vue'),
				meta: {
					title: 'Forms',
				},
			},
			{
				path: 'log',
				name: 'builder.log',
				component: () => import('./pages/TemplateLog.vue'),
				meta: {
					title: 'Log',
				},
			},
			{
				path: 'variables',
				name: 'builder.variables',
				component: () => import('./pages/TemplateVariables.vue'),
				meta: {
					title: 'Variables',
				},
			},
		],
	},
];

export const adminRoutes: RouteRecordRaw[] = [];

export default [...guestRoutes, ...authRoutes, ...adminRoutes];
