//Initialize Vue to get the root component.
import { createApp } from 'vue';
//The initial mounted app
import App from '@/App.vue';

//Initialize CSS and Design Elements
import '@design/style/main.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-tel-input/vue-tel-input.css';

//Initialize VueRouter
import router from '@router';

//Initialize Pinia
import PiniaRouter from '@/plugins/PiniaRouter';
import { createPinia } from 'pinia';
//Initialize formkit plugin, pro, and config
import { defaultConfig, plugin } from '@formkit/vue';
import config from '../formkit.config';

//Initialize sentry
import { initializeSentry } from '@/services/useSentry';

const app = createApp(App);

const pinia = createPinia();
pinia.use(PiniaRouter(router));

initializeSentry(app, router);

//Finally add all plugins and global components and mount the app
app.use(pinia).use(router).use(plugin, defaultConfig(config)).mount('#app');
