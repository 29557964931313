import { acceptHMRUpdate, defineStore } from 'pinia';
import { ActivityVariables } from '@/types/activityTemplate';

export const useWorkflowProcessStore = defineStore({
	id: 'WorkflowProcess',
	state: () => ({
		loaded: false,
		loading: true,
		workflow_process_id: null as number | null,
		variables: <ActivityVariables>{},
		history: [],
	}),
	getters: {},
	actions: {},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useWorkflowProcessStore, import.meta.hot));
}
