import { useAuthStore } from '@modules/auth/store';

export function getAuthHeaders() {
	const authStore = useAuthStore();
	if (authStore.isAuthenticated) {
		return {
			Authorization: 'Bearer ' + authStore.getAuthToken,
			'X-Organisation': authStore.getAuthOrganisationId,
		};
	}
	return {};
}
