export type CreateTemplateType = {
	name: string;
	description?: string;
	type?: typeof TemplateType;
};

export type UpdateTemplateType = {
	name: string;
	description?: string;
	type?: typeof TemplateType;
};

export type ActivityTemplateSettings = {
	interaction_label: string;
	details_description?: string;
	details_heading?: string;
};

export type ActivityVariable = {
	id: string;
	name: string;
	type: typeof ActivityTemplateVariableType;
	children: ActivityVariables;
	value: unknown;
	attributes: {
		description: string | undefined;
		group: boolean;
		list: boolean;
		hidden: boolean;
		read_only: boolean;
		tracked: boolean;
	};
	history: boolean | undefined;
};

export type ActivityVariables = {
	[key: string]: ActivityVariable;
};

export type ActivityTemplateVariables = {
	id: string;
	name: string;
	description?: string | undefined;
	type: typeof ActivityTemplateVariableType | undefined;
	children: ActivityTemplateVariables[];
	tracked?: boolean;
	group?: boolean;
	hidden?: boolean;
	read_only?: boolean;
	list?: boolean;
	initial_value?: unknown | undefined;
};

export const ActivityTemplateVariableType = {
	STRING: 'string',
	NUMBER: 'number',
	DATE: 'date',
	DATETIME: 'datetime',
	TIME: 'time',
	JSON: 'json',
	BOOLEAN: 'boolean',
	LIST: 'list',
	CUSTOM: 'custom',
} as const;

export const ActivityTemplateVariableProperties = {
	READONLY: 'readonly',
	DISABLED: 'disabled',
	HIDDEN: 'hidden',
	TRACKED: 'tracked',
	GROUP: 'group',
	LIST: 'list',
} as const;

export const ActivityTemplateVariableTypeLabel = {
	[ActivityTemplateVariableType.STRING]: 'String',
	[ActivityTemplateVariableType.NUMBER]: 'Number',
	[ActivityTemplateVariableType.DATE]: 'Date',
	[ActivityTemplateVariableType.DATETIME]: 'Date/Time',
	[ActivityTemplateVariableType.TIME]: 'Time',
	[ActivityTemplateVariableType.JSON]: 'JSON',
	[ActivityTemplateVariableType.BOOLEAN]: 'Boolean',
	[ActivityTemplateVariableType.LIST]: 'List',
	[ActivityTemplateVariableType.CUSTOM]: 'Custom',
};

export const TemplateStatus = {
	DRAFT: 1,
	PUBLISHED: 2,
	ARCHIVED: 3,
	DELETED: 99,
};

export const TemplateStatusLabel = {
	[TemplateStatus.DRAFT]: 'Draft',
	[TemplateStatus.PUBLISHED]: 'Published',
	[TemplateStatus.ARCHIVED]: 'Archived',
	[TemplateStatus.DELETED]: 'Deleted',
};

export const TemplateType = {
	TICKET: 1,
	TASK: 2,
	PROJECT: 3,
	ACTIVITY: 4,
	JOB: 5,
	WORK_ORDER: 6,
};
export const TemplateTypeLabel = {
	[TemplateType.TICKET]: 'Ticket',
	[TemplateType.TASK]: 'Task',
	[TemplateType.PROJECT]: 'Project',
	[TemplateType.ACTIVITY]: 'Activity',
	[TemplateType.JOB]: 'Job',
	[TemplateType.WORK_ORDER]: 'Work Order',
};
