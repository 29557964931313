<template>
	<Disclosure v-if="baseStore.debugMode">
		<DisclosureButton class="py-1.5 text-white rounded-md flex justify-center items-center bg-pink-500 w-48">
			<div class="flex flex-col">
				<div class="flex flex-row justify-center items-center text-sm">Debug</div>
				<div v-if="title" class="flex flex-row justify-center items-center text-lg -mt-1.5 font-semibold">{{ title }}</div>
			</div></DisclosureButton
		>
		<DisclosurePanel class="text-black">
			<pre><slot /></pre>
		</DisclosurePanel>
	</Disclosure>
</template>

<script setup lang="ts">
	import { useBaseStore } from '@store';
	import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
	import { defineProps } from 'vue';

	const baseStore = useBaseStore();
	interface Props {
		title?: string;
	}
	defineProps<Props>();
</script>
